// autoviz-component-monorepo/packages/product-catalog/src/components/wheel-configurator/dropdown-menu-catalog.tsx

import DynamicComponentLoader from '@/components/dynamic-component-loader';
import { useStore } from '@/lib/store';
import React, { useEffect } from 'react';
import DropdownMenuWrapper from './dropdown-menu-wrapper';
import { cn, configuratorPath } from '@/lib/utils';

const DropdownMenuCatalog: React.FC<any> = ({
  classNameObject,
}: {
  classNameObject?: any;
}) => {
  const currentAutosyncApiWheelStyle = useStore(
    (state) => state.currentAutosyncApiWheelStyle,
  );

  const setNavigationContextRootPath = useStore(
    (state) => state.setNavigationContextRootPath,
  );
  useEffect(() => {
    setNavigationContextRootPath(configuratorPath);
  }, []);

  return (
    <DropdownMenuWrapper
      title={currentAutosyncApiWheelStyle?.Model}
      className={cn(classNameObject?.wrapper, `min-w-[400px] !p-0`)}
    >
      <div className={cn(`min-w-[400px] !p-0`)}>
        <DynamicComponentLoader
          componentNameAsProp="product-catalog"
          props={{
            showNavigation: false,
            showDarkModeToggle: false,
            classNameObject: { ProductList: 'grid !grid-cols-3' },
            context: 'DropdownMenuCatalog',
          }}
        />
      </div>
    </DropdownMenuWrapper>
  );
};

export default React.memo(DropdownMenuCatalog);
