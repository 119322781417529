import { WheelStyleDetail } from '@/lib/types';
import { cn } from '@/lib/utils';
import { useEffect, useState } from 'react';
import { resolveWheelOffsetDisplayText } from './text-displays-misc';

export default function ShowProductAllDetails({
  productDetail,
  hasDisplays,
}: {
  productDetail: WheelStyleDetail | null;
  hasDisplays: boolean;
}) {
  const [showAllDetails, setShowAllDetails] = useState<Boolean>(false);

  useEffect(() => {
    if (!hasDisplays) {
      setShowAllDetails(true);
    }
  }, [hasDisplays]);
  return (
    <div className="h-full">
      <div className="w-full h-full">
        <div className="grid grid-cols-2 w-full gap-3">
          <DetailsTitle
            title="Bolt Pattern"
            description={`${productDetail?.LugCount}x${productDetail?.BoltCircle1}`}
          />
          <DetailsTitle
            title="Offset"
            description={`${resolveWheelOffsetDisplayText(productDetail)}`}
          />
          {!showAllDetails ? (
            <>
              <DetailsTitle title="P/N" description={`${productDetail?.Pn}`} />
              <DetailsTitle
                title="Model"
                description={`${productDetail?.Bore || 'N/A'}`}
              />
              <DetailsTitle
                title="Bore"
                description={`${productDetail?.Bore || 'N/A'}`}
              />
              <DetailsTitle
                title="Size"
                description={`${productDetail?.Diameter}"x${productDetail?.Width}"`}
              />
              <DetailsTitle
                title="Load rating"
                description={`${productDetail?.LoadRating || 'N/A'}`}
              />
              <DetailsTitle
                title="Weight"
                description={`${productDetail?.Weight ? productDetail?.Weight + ' LBS' : 'N/A'}`}
              />
              <DetailsTitle
                title="Color"
                description={`${productDetail?.Color || 'N/A'}`}
              />
              <DetailsTitle
                title="Structure"
                description={`${productDetail?.Structure || 'N/A'}`}
              />
              <DetailsTitle
                title="Accent"
                description={`${productDetail?.Accent || 'N/A'}`}
              />{' '}
              <DetailsTitle
                title="Cap P/N"
                description={`${productDetail?.Cap?.Pn || 'N/A'}`}
              />{' '}
            </>
          ) : null}
          {/* <DetailsTitle description={productDetail.} /> */}
          {/* <DetailsTitle description={productDetail.} /> */}
        </div>
      </div>

      <button
        onClick={() => setShowAllDetails((prev) => !prev)}
        className="font-semibold text-base underline text-foreground px-0 mt-6"
      >
        {showAllDetails ? 'All details' : 'Show less'}
      </button>
    </div>
  );
}

export const DetailsTitle = ({
  title = 'Bold pattern',
  description = 'hello',
}: {
  description?: string;
  title?: string;
}) => {
  let descriptionClassName = 'text-sm pb-0 pt-1 font-semibold ';
  let titleClassName = 'text-sm pb-2 pt-1 font-';
  return (
    <div className=" w-full py-2 border-b-1 mx-0.5">
      <p className={cn(titleClassName)}>{title}</p>
      <p className={cn(descriptionClassName)}>{description}</p>
    </div>
  );
};
