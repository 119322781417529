import {
  useFetchVehicleTrimByYearModelAndMake,
  useFetchVehicleTypeByYearAndMake,
} from '@/hooks/useFetchVehicle';
import { useVehicleSearch } from '@/lib/store';
import React from 'react';

const vehicleModel = [
  {
    title: 'Astro',
  },
  {
    title: 'Express 1500',
  },
  {
    title: 'Express 2500',
  },
  {
    title: 'Express 3500',
  },
  {
    title: 'Venture',
  },
];

export default function SelectSearchVehicleModel() {
  const {
    SetVehicleModel,
    SetVehicleType,
    vehicleMake,
    vehicleYear,
    vehicleType: selectedType,
  } = useVehicleSearch((state) => state);

  const { vehicleType, isLoading, vehicleModel } =
    useFetchVehicleTypeByYearAndMake({
      make: vehicleMake!,
      year: vehicleYear!,
    });
  return (
    <div className="w-full">
      <div className="grid grid-cols-3 gap-2 max-h-[500px] mx-2">
        {vehicleModel?.Models.filter((fv) => fv.Type === selectedType).map(
          (item, index) => {
            return (
              <button
                onClick={() => {
                  // handle Exact vehicle state logic here.
                  SetVehicleModel(item.Model);
                }}
                className="border rounded-2xl flex items-center justify-center max-w-[196px] py-[20px]"
              >
                <h1 className="text-base font-semibold">{item.Model}</h1>
              </button>
            );
          },
        )}
      </div>
    </div>
  );
}

const vehicleTrim = [
  {
    title: 'LS Mini Passenger Van',
  },
  {
    title: 'Value Mini Passenger Van',
  },
  {
    title: 'Plus Mini Passenger Van',
  },
  {
    title: 'LT Mini Passenger Van',
  },
  {
    title: 'Warner Bros. Mini Passenger Van',
  },
];

export function SelectSearchVehicleTrim() {
  const {
    SetVehicleTrim,
    vehicleMake,
    vehicleModel,
    vehicleType,
    vehicleYear,
  } = useVehicleSearch((state) => state);
  const { vehicleDetails } = useFetchVehicleTrimByYearModelAndMake({
    make: vehicleMake!,
    model: vehicleModel!,
    year: vehicleYear!,
  });

  return (
    <div className="w-full">
      <div className="grid grid-cols-3 gap-2 max-h-[500px] mx-2">
        {vehicleDetails?.Vehicles.map((item, index) => {
          return (
            <button
              onClick={() => {
                // handle Exact vehicle state logic here.
                SetVehicleTrim({
                  id: item.Id,
                  colorId: item.Colors[0].Id,
                });
              }}
              className="border rounded-2xl flex items-center justify-center max-w-[196px] py-[20px] px-3 "
            >
              <h1 className="text-base font-semibold">{`${item.Submodel} ${item.Body}`}</h1>
            </button>
          );
        })}
      </div>
    </div>
  );
}
