import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useStore } from '../lib/store';
import { productCatalogPath } from '../lib/utils';
import WheelCarousel from './wheel-carousel';

import useFetchAndSetCurrentWheelData from '@/hooks/useFetchAndSetCurrentWheelData';
import { useModelViewer } from '@/hooks/useModelViewer';
import { ChevronLeft } from 'lucide-react';
import { Button } from './ui/button';
import WheelDetailsV1 from './wheel-details-v1';

const ProductDetail: React.FC = () => {
  const { styleId, wheelId } = useParams<{
    brandId: string;
    styleId: string;
    wheelId: string;
  }>();

  const {
    fetchAndSetCurrentWheelData,
    loading,
    setLoading,
    imgUrlBase,
    moreItems,
  } = useFetchAndSetCurrentWheelData();

  const [showContent, setShowContent] = useState<boolean>(false);

  const setCurrrentAutosyncApiWheelStyle = useStore(
    (state) => state.setCurrentAutosyncApiWheelStyle,
  );
  const currentAutosyncApiWheelStyle = useStore(
    (state) => state.currentAutosyncApiWheelStyle,
  );
  const currentAutosyncApiWheelStyles = useStore(
    (state) => state.currentAutosyncApiWheelStyles,
  );

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (currentAutosyncApiWheelStyles.length == 0) return;

    const foundProductDetail = currentAutosyncApiWheelStyles.find(
      (productDetail: any) => productDetail?.Id == wheelId,
    );
    setCurrrentAutosyncApiWheelStyle(
      foundProductDetail || currentAutosyncApiWheelStyles[0],
    );

    // console.log(`foundProductDetail`, foundProductDetail);
    if (foundProductDetail && foundProductDetail.Id.toString() !== wheelId) {
      navigate(
        `${productCatalogPath}/${styleId}/${foundProductDetail.Id}${location.search}`,
      );
    }

    if (currentAutosyncApiWheelStyles.length === 1) {
      navigate(
        `${productCatalogPath}/${styleId}/${currentAutosyncApiWheelStyles[0].Id}${location.search}`,
      );
    }
  }, [wheelId, currentAutosyncApiWheelStyles, styleId]);

  useEffect(() => {
    const productDetailContainer = document.getElementById(
      'product-detail-container',
    );
    const wheelCarouselContainer = document.getElementById(
      'wheel-carousel-container',
    );
    const wheelCarouselContainerClientHeight =
      wheelCarouselContainer?.clientHeight as any;
    const productDetailContainerClientHeight =
      productDetailContainer?.clientHeight as any;

    if (wheelCarouselContainer && productDetailContainer) {
      wheelCarouselContainer.style.minHeight = `${wheelCarouselContainerClientHeight}px`;
      productDetailContainer.style.minHeight = `${productDetailContainerClientHeight}px`;
    }

    fetchAndSetCurrentWheelData({ styleId });
  }, [styleId]);

  useEffect(() => {
    if (showContent) {
      const productDetailContainer = document.getElementById(
        'product-detail-container',
      );
      const wheelCarouselContainer = document.getElementById(
        'wheel-carousel-container',
      );
      if (wheelCarouselContainer && productDetailContainer) {
        wheelCarouselContainer.style.minHeight = `0px`;
        productDetailContainer.style.minHeight = `0px`;
      }
    }
  }, [showContent]);

  useEffect(() => {
    if (!loading) {
      setShowContent(true);
    } else {
      setShowContent(false);
    }
  }, [loading]);

  useEffect(() => {
    if (currentAutosyncApiWheelStyle) {
      const image = new Image();
      image.src = `${imgUrlBase}${currentAutosyncApiWheelStyle.Img0001}`;
      image.onload = () => {
        setLoading(false);
      };
    }
  }, [currentAutosyncApiWheelStyle, imgUrlBase]);

  const setOutletLoaderIsVisible = useStore(
    (state) => state.setOutletLoaderIsVisible,
  );
  const outletLoaderIsVisible = useStore(
    (state) => state.outletLoaderIsVisible,
  );
  const setStyleId = useStore((state) => state.setStyleId);

  const { modelSrc, setModelSrc } = useModelViewer();
  const curStyleId = useRef(currentAutosyncApiWheelStyle?.StyleId);
  useEffect(() => {
    if (currentAutosyncApiWheelStyle?.StyleId)
      setStyleId(currentAutosyncApiWheelStyle?.StyleId.toString());
    if (curStyleId.current !== currentAutosyncApiWheelStyle?.StyleId) {
      console.log(`change wheel`);
      setOutletLoaderIsVisible(true);
      setModelSrc('');
      curStyleId.current = currentAutosyncApiWheelStyle?.StyleId;
    }
    setOutletLoaderIsVisible(true);

    if (showContent) {
      setTimeout(() => {
        setOutletLoaderIsVisible(false);
      }, 100);
    }
  }, [currentAutosyncApiWheelStyle?.StyleId, showContent]); //@ia 27 patch, only show loader when styleId changes, wheelIds are for same wheel

  return (
    <>
      <div
        id="product-detail-container"
        className="md:rounded-lg mx-auto w-full relative  flex flex-col items-center justify-center pb-0 transition-all md:h-screen border-or"
      >
        <>
          <div
            className={`
        md:grid
        md:grid-cols-[65%,35%]
        z-1 transition-opacity
         
        md:rounded-lg
        mx-auto w-full
        min-h-screen
        md:h-screen
        flex flex-col items-start justify-center pb-0 relative top-0 left-0 ${showContent ? 'opacity-100 duration-500' : 'opacity-0 duration-0'}`}
          >
            <div className="bg-muted relative w-full h-full border-black border-0 overflow-hidden ">
              <Button
                variant={'ghost'}
                onClick={() => {
                  // setIsLeftDrawerOpen(false);
                  navigate('/catalog');
                }}
                className="left-3 top-3 bp1080:hidden  text-lg p-4 z-10 absolute font-normal"
              >
                <ChevronLeft size={24} />
                <p>Back to wheel catalog</p>
              </Button>

              <div className={`relative`}>
                {' '}
                <WheelCarousel
                  productDetail={currentAutosyncApiWheelStyle}
                  imgUrlBase={imgUrlBase}
                  loading={outletLoaderIsVisible}
                />
              </div>
            </div>

            <div
              className={`border-purple-500 border-0 overflow-y-scroll w-full h-full pb-[100px] md:pb-0`}
            >
              {' '}
              <WheelDetailsV1
                productDetail={currentAutosyncApiWheelStyle}
                wheelStyles={currentAutosyncApiWheelStyles || {}}
                loading={false}
              />
            </div>
          </div>
        </>
      </div>
    </>
  );
};

export default React.memo(ProductDetail);
