import React, { useEffect, useState } from 'react';
import { Button, buttonVariants } from './ui/button';
import { cn } from '@/lib/utils';
import { useVehicleSearch } from '@/lib/store';
import { Loader2 } from 'lucide-react';
import { useFetchVehicleFigment } from '@/hooks/useFetchVehicle';
import { Fitment, PlusSizes } from '@/types/figments';

let size_id = [
  {
    id: '215-70R15-1',
    valueA: 15,
    valueB: 6,
    PN: '215/70R15',
  },
  {
    id: '215-70R15-2',
    valueA: 15,
    valueB: 6,
    PN: '215/70R15',
  },
  {
    id: '215-70R15-3',
    valueA: 15,
    valueB: 6,
    PN: '215/70R15',
  },
  {
    id: '215-70R15-4',
    valueA: 15,
    valueB: 6,
    PN: '215/70R15',
  },
  {
    id: '215-70R15-5',
    valueA: 15,
    valueB: 6,
    PN: '215/70R15',
  },
];

export default function SelectSearchVehicleWheelSize() {
  const [selectMode, setSelectMode] = useState(true);
  const { vehicleTrim } = useVehicleSearch((state) => state);
  const { vehicleDetails } = useFetchVehicleFigment(vehicleTrim!);
  return (
    <div className="w-full ">
      <div className="border flex max-w-fit mx-auto  px-1 py-1 rounded-xl">
        <div>
          <button
            className={cn(
              buttonVariants({
                size: 'lg',
                variant: !selectMode ? 'secondary' : 'default',
              }),
              'py-2 rounded-xl',
            )}
            onClick={() => {
              setSelectMode(true);
            }}
          >
            Orginal
          </button>
        </div>
        <div>
          <button
            className={cn(
              buttonVariants({
                size: 'lg',
                variant: selectMode ? 'secondary' : 'default',
              }),
              'py-2 rounded-xl ',
            )}
            onClick={() => {
              setSelectMode(false);
            }}
          >
            Plus Size
          </button>
        </div>
      </div>
      <div className="w-full mt-4 max-h-[250px] overflow-y-scroll  scrollbar-w-2 scrollbar-thumb-rounded scrollbar-track-primary scrollbar-thumb-muted">
        <SearchedWheelSizeChip
          fitments={
            selectMode
              ? (vehicleDetails?.Vehicles[0].Fitments ?? null)
              : (vehicleDetails?.Vehicles[0].PlusSizes ?? null)
          }
        />
      </div>
      <div className="mt-2">
        <div className="flex w-full h-full  mt-12  sm:justify-between items-center mx-auto max-w-full flex-col sm:flex-row sm:max-w-[90%]">
          <Button
            variant={'link'}
            className={
              'text-secondary-foreground underline text-base font-semibold'
            }
            // onClick={() => handleResetClick()}
          >
            Show all Size (
            {(vehicleDetails?.Vehicles[0].Fitments.length ?? 0) +
              (vehicleDetails?.Vehicles[0].PlusSizes.length ?? 0)}{' '}
            Results)
          </Button>
          <Button
            variant={'revertedButton'}
            className="md:py-6 py-5  rounded-xl w-full max-w-[90%] sm:max-w-[250px] "
            size={'lg'}
            onClick={() => {}}
          >
            <div className="">
              <p className="font-normal text-base">
                Show{' '}
                {selectMode
                  ? vehicleDetails?.Vehicles[0].Fitments.length
                  : vehicleDetails?.Vehicles[0].PlusSizes.length}{' '}
                results
              </p>
            </div>
          </Button>
        </div>{' '}
      </div>
    </div>
  );
}

export function SearchedWheelSizeChip({
  fitments,
}: {
  fitments: Fitment[] | PlusSizes[] | null;
}) {
  const { SetVehicleSize, vehicleSize } = useVehicleSearch((state) => state);
  useEffect(() => {
    if (!fitments?.length) return;
    SetVehicleSize(fitments[0]?.ChassisId);
  }, []);
  return (
    <div className="">
      <div className="">
        <div className="flex flex-wrap gap-2  ">
          {fitments &&
            fitments.map((item) => {
              return (
                <div>
                  <Button
                    onClick={() => {
                      // alert(item.)
                      SetVehicleSize(`${item.ChassisId}-${item.RimDiameter}-${item.RimWidth}-${item.TireSize}`);
                    }}
                    size={'chip'}
                    className="py-2 font-normal gap-3"
                    variant={
                      vehicleSize === `${item.ChassisId}-${item.RimDiameter}-${item.RimWidth}-${item.TireSize}` ? 'default' : 'secondary'
                    }
                  >
                    <span className="font-semibold">
                      {item.RimDiameter}” x {item.RimWidth}”
                    </span>
                    <span>{item.TireSize}</span>
                  </Button>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}
