import { WheelGesturesPlugin } from 'embla-carousel-wheel-gestures';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { WheelStyleDetail } from '../lib/types';

import {
  Carousel,
  CarouselContent,
  CarouselItem,
} from '@/components/ui/carousel';

import { useStore } from '@/lib/store';
import { cn, productCatalogPath } from '@/lib/utils';
import { Button, buttonVariants } from './ui/button';

const ProductDetailSizeSelectorChip: React.FC<{
  items: WheelStyleDetail[];
  navigationPathRoot?: string;
  hasDisplays: boolean;
  setHasDisplays: (hasDisplays: boolean) => void;
}> = ({
  items,
  navigationPathRoot = productCatalogPath,
  hasDisplays = false,
  setHasDisplays,
}) => {
  const currentAutovizDbWheelModel = useStore(
    (state) => state.currentAutovizDbWheelModel,
  );
  const currentAutovizDbWheelInfo = useStore(
    (state) => state.currentAutovizDbWheelInfo,
  );
  const currentAutosyncApiWheelStyle = useStore(
    (state) => state.currentAutosyncApiWheelStyle,
  );

  useEffect(() => {
    // console.log(`currentAutosyncApiWheelStyle`, currentAutosyncApiWheelStyle);
    // console.log(
    //   `createBoltPatternDisplayKey(productDetail)`,
    //   createBoltPatternDisplayKey(currentAutosyncApiWheelStyle as any),
    // );
    // console.log(`currentAutovizDbWheelModel`, currentAutovizDbWheelModel);
    // console.log(`currentAutovizDbWheelInfo`, currentAutovizDbWheelInfo);
  }, [
    // currentAutovizDbWheelModel,
    // currentAutovizDbWheelInfo,
    currentAutosyncApiWheelStyle,
  ]);

  const navigate = useNavigate();
  const location = useLocation();
  const { styleId, wheelId } = useParams<{
    styleId: string;
    wheelId: string;
  }>();
  const sizeDisplaysRef = useRef<HTMLDivElement>(null);
  const [selectedBoltPattern, setSelectedBoltPattern] = useState<
    string | undefined
  >(undefined);
  const [selectedWheelSize, setSelectedWheelSize] = useState<
    string | undefined
  >(undefined);
  const [selectedOffsetAndLipSize, setSelectedOffsetAndLipSize] = useState<
    string | undefined
  >(undefined);

  //when styleId changes, clear the selected values
  useEffect(() => {
    setSelectedBoltPattern(undefined);
    setSelectedBoltPatternKey(`selectedBoltPatternKey_${new Date()}`);
  }, [styleId]);

  //try to set selected options by wheelId if it is set
  useEffect(() => {
    if (items) {
      setSelectedBoltPattern(
        getDisplayKey(createBoltPatternDisplayKey, wheelId, items),
      );
      setSelectedWheelSize(getDisplayKey(createSizeDisplayKey, wheelId, items));
      setSelectedOffsetAndLipSize(wheelId || undefined);
    }
  }, [items, wheelId]);

  //try to set selected options by wheelId if it is set
  useEffect(() => {
    // console.log(`useEffect offsetAndLipDisplays`, offsetAndLipDisplays);
    let keys = Object.keys(offsetAndLipDisplays);
    if (keys.length == 1) {
      let wheelId = offsetAndLipDisplays[keys[0]][0].Id.toString();

      navigate(`${navigationPathRoot}/${styleId}/${wheelId}${location.search}`);
    }
  }, [selectedWheelSize]);

  //create keys for reseting radix component Select
  const [selectedBoltPatternKey, setSelectedBoltPatternKey] = useState<string>(
    `selectedBoltPatternKey_${new Date()}`,
  );
  const [selectedWheelSizeKey, setSelectedWheelSizeKey] = useState<string>(
    `selectedWheelSizeKey_${new Date()}`,
  );
  const [selectedOffsetAndLipSizeKey, setSelectedOffsetAndLipSizeKey] =
    useState<string>(`selectedOffsetAndLipSizeKey_${+new Date()}`);

  //reset all other options
  const handleChangeBoltPattern = (value: string) => {
    setSelectedBoltPattern(value);
    setSelectedWheelSize(undefined);
    setSelectedWheelSizeKey(`selectedWheelSizeKey_${new Date()}`);
    setSelectedOffsetAndLipSize(undefined);
    setSelectedOffsetAndLipSizeKey(
      `selectedOffsetAndLipSizeKey_${+new Date()}`,
    );
  };

  //reset offset / lip size
  const handleChangeSize = (value: string) => {
    setSelectedWheelSize(value);
    setSelectedOffsetAndLipSize(undefined);
    setSelectedOffsetAndLipSizeKey(
      `selectedOffsetAndLipSizeKey_${+new Date()}`,
    );
    navigate(`${navigationPathRoot}/${styleId}/${wheelId}${location.search}`);
  };

  //set url
  const handleChangeOffsetAndLipSize = (wheelId: string) => {
    setSelectedOffsetAndLipSize(wheelId);
    navigate(`${navigationPathRoot}/${styleId}/${wheelId}${location.search}`);
  };

  const boltPatternDisplays: Record<string, WheelStyleDetail[]> = {};
  items.forEach((productDetail) => {
    let displayKey = createBoltPatternDisplayKey(productDetail);
    if (boltPatternDisplays[displayKey]) {
      boltPatternDisplays[displayKey].push(productDetail);
    } else {
      boltPatternDisplays[displayKey] = [productDetail];
    }
  });

  const sizeDisplays: Record<string, WheelStyleDetail[]> = {};

  if (selectedBoltPattern && boltPatternDisplays[selectedBoltPattern]) {
    boltPatternDisplays[selectedBoltPattern].forEach((productDetail) => {
      let displayKey = createSizeDisplayKey(productDetail);
      if (sizeDisplays[displayKey]) {
        sizeDisplays[displayKey].push(productDetail);
      } else {
        sizeDisplays[displayKey] = [productDetail];
      }
    });
  }

  const offsetAndLipDisplays: Record<string, WheelStyleDetail[]> = {};

  if (selectedWheelSize && sizeDisplays[selectedWheelSize]) {
    sizeDisplays[selectedWheelSize].forEach((productDetail) => {
      let displayKey = createOffsetAndLipDisplayKey(productDetail);
      if (offsetAndLipDisplays[displayKey]) {
        offsetAndLipDisplays[displayKey].push(productDetail);
      } else {
        offsetAndLipDisplays[displayKey] = [productDetail];
      }
    });
  }

  useEffect(() => {
    if (
      Object.keys(offsetAndLipDisplays).length > 0 ||
      Object.keys(sizeDisplays).length > 0 ||
      Object.keys(boltPatternDisplays).length > 0
    ) {
      setHasDisplays(true);
    }
  }, [offsetAndLipDisplays, sizeDisplays, boltPatternDisplays]);

  const [capOptionValue, setCapOptionValue] = useState<string>('');
  const capOptionsDisplays = [
    { name: 'Standard center cap', value: 'standard_centered' },
    { name: 'Floating Cap', value: 'floating' },
    { name: 'Chrome Cap', value: 'chrome' },
    { name: 'Machined Cap', value: 'machined' },
  ];

  useEffect(() => {
    handleChangeBoltPattern(
      createBoltPatternDisplayKey(currentAutosyncApiWheelStyle || undefined),
    );
    handleChangeSize(
      createSizeDisplayKey(currentAutosyncApiWheelStyle || undefined),
    );
    // handleChangeOffsetAndLipSize(
    //   createOffsetAndLipDisplayKey(currentAutosyncApiWheelStyle || undefined),
    // );
  }, [currentAutosyncApiWheelStyle]);

  return (
    <div className="flex flex-col w-full h-full">
      <div className={`text-sm pb-1 `}>Bolt Pattern</div>
      <div
        key={selectedBoltPatternKey}
        className="flex gap-2 pb-2  overflow-x-scroll scroll-smooth scrollbar-hidden scrollbar-thumb scrollbar-thumb-rounded pointer-events-auto  touch-pan-x"
      >
        <Carousel
          opts={{
            dragFree: true,
            // dragThreshold: 25,
            duration: 120,
            align: 'start',
          }}
          plugins={[
            WheelGesturesPlugin({
              forceWheelAxis: 'x',
            }),
          ]}
          className="w-full"
        >
          <CarouselContent className="">
            {Object.keys(boltPatternDisplays).map((displayKey, i) => (
              <CarouselItem
                onClick={() => handleChangeBoltPattern(displayKey)}
                className={cn(
                  buttonVariants({
                    variant:
                      displayKey === selectedBoltPattern
                        ? 'default'
                        : 'secondary',
                    size: 'chip',
                  }),
                  { 'font-semibold': displayKey === selectedBoltPattern },
                  ' max-w-fit ml-2   first-of-type:ml-4 md:border md:border-gray-400 cursor-pointer ',
                )}
                key={displayKey}
              >
                {displayKey}
              </CarouselItem>
            ))}
          </CarouselContent>
        </Carousel>
      </div>
      {selectedBoltPattern && (
        <>
          {Object.keys(sizeDisplays).length > 1 ? (
            <>
              <div className={`text-sm pb-1 pt-2  `}>Size</div>
              <div
                key={selectedWheelSizeKey}
                className="flex gap-2 pb-2 overflow-x-scroll scroll-smooth scrollbar-hidden scrollbar-thumb scrollbar-thumb-rounded pointer-events-au touch-pan-x"
              >
                <Carousel
                  opts={{
                    dragFree: true,
                    // dragThreshold: 25,
                    duration: 120,
                  }}
                  plugins={[
                    WheelGesturesPlugin({
                      forceWheelAxis: 'x',
                    }),
                  ]}
                  className="w-full"
                >
                  <CarouselContent className="">
                    {Object.keys(sizeDisplays).map((displayKey, i) => (
                      <CarouselItem
                        onClick={() => handleChangeSize(displayKey)}
                        className={cn(
                          buttonVariants({
                            variant:
                              displayKey === selectedWheelSize
                                ? 'default'
                                : 'secondary',
                            size: 'chip',
                          }),
                          { 'font-semibold': displayKey === selectedWheelSize },

                          ' max-w-fit ml-2 first-of-type:ml-4  md:border md:border-gray-400 cursor-pointer ',
                        )}
                        key={displayKey}
                      >
                        {displayKey}
                      </CarouselItem>
                    ))}
                  </CarouselContent>
                </Carousel>
              </div>
            </>
          ) : (
            <div>
              <div className={`text-sm pb-1 pt-2  `}>Size</div>
              <Button size={'chip'}>
                {Object.keys(sizeDisplays)[0] &&
                  'Size:' + Object.keys(sizeDisplays)[0]}{' '}
              </Button>
            </div>
          )}
        </>
      )}

      {selectedWheelSize ? (
        <>
          {Object.keys(offsetAndLipDisplays) &&
          Object.keys(offsetAndLipDisplays).length > 1 ? (
            <>
              <div className={`text-sm pb-1 pt-2 `}>Offset / Lip Size</div>
              <div
                key={selectedOffsetAndLipSizeKey}
                className="flex gap-2 pb-2  overflow-x-scroll scroll-smooth scrollbar-hidden scrollbar-thumb scrollbar-thumb-rounded pointer-events-au touch-pan-x"
              >
                <Carousel
                  opts={{
                    dragFree: true,
                    // dragThreshold: 25,
                    duration: 120,
                  }}
                  plugins={[
                    WheelGesturesPlugin({
                      forceWheelAxis: 'x',
                    }),
                  ]}
                  className="w-full"
                >
                  <CarouselContent className="">
                    {Object.keys(offsetAndLipDisplays).length > 0 &&
                      Object.keys(offsetAndLipDisplays).map((displayKey) => (
                        <CarouselItem
                          onClick={() =>
                            handleChangeOffsetAndLipSize(
                              offsetAndLipDisplays[displayKey][0].Id.toString(),
                            )
                          }
                          key={offsetAndLipDisplays[
                            displayKey
                          ][0].Id.toString()}
                          className={cn(
                            buttonVariants({
                              variant:
                                offsetAndLipDisplays[
                                  displayKey
                                ][0].Id.toString() === selectedOffsetAndLipSize
                                  ? 'default'
                                  : 'secondary',
                              size: 'chip',
                            }),
                            ' max-w-fit first-of-type:ml-4 ml-2 md:border md:border-gray-400 cursor-pointer ',
                          )}
                        >
                          {displayKey}
                        </CarouselItem>
                      ))}
                  </CarouselContent>
                </Carousel>
              </div>
            </>
          ) : (
            Object.keys(offsetAndLipDisplays)[0] && (
              <>
                <div className={`text-sm pb-2 pt-2 `}>Offset / Lip Size</div>
                <Button
                  size={'chip'}
                  variant={
                    //Review the color Schemes, because default/secondary doesn't have the border as per design file
                    'default'
                  }
                  className="md:border md:border-gray-400 max-w-fit"
                >
                  {Object.keys(offsetAndLipDisplays)[0]}
                </Button>
              </>
            )
          )}
        </>
      ) : null}

      {(items[0] && items[0]?.Cap !== null) || true ? (
        <>
          <div className={`text-sm pb-1 pt-4 `}>Cap Option</div>

          <div className="flex gap-2 pb-2 overflow-x-scroll scroll-smooth scrollbar-hidden scrollbar-thumb scrollbar-thumb-rounded pointer-events-au touch-pan-x">
            <Carousel
              opts={{
                dragFree: true,
                // dragThreshold: 25,
                duration: 120,
              }}
              plugins={[
                WheelGesturesPlugin({
                  forceWheelAxis: 'x',
                }),
              ]}
              className="w-full"
            >
              <CarouselContent>
                {Object.keys(capOptionsDisplays).map(
                  (displayKey: any, index: number) => (
                    <CarouselItem
                      key={capOptionsDisplays[displayKey].name}
                      className={cn(
                        buttonVariants({
                          variant:
                            capOptionValue === capOptionsDisplays[index].value
                              ? 'default'
                              : 'secondary',
                          size: 'chip',
                        }),
                        ' max-w-fit  first-of-type:ml-4 ml-2 md:border md:border-gray-400 cursor-pointer ',
                      )}
                      onClick={() => {
                        setCapOptionValue(capOptionsDisplays[index].value);
                      }}
                    >
                      {capOptionsDisplays[index].name}
                    </CarouselItem>
                  ),
                )}
              </CarouselContent>
            </Carousel>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default React.memo(ProductDetailSizeSelectorChip);

const createBoltPatternDisplayKey = (
  productDetail = {} as WheelStyleDetail,
) => {
  return `${productDetail?.LugCount} x ${productDetail?.BoltCircle1}`;
};

const createSizeDisplayKey = (productDetail = {} as WheelStyleDetail) => {
  return `${productDetail.Diameter}" x ${productDetail.Width}"`;
};

const createOffsetAndLipDisplayKey = (
  productDetail = {} as WheelStyleDetail,
) => {
  let lipSizeDisplay = productDetail.LipSize
    ? ` / ${productDetail.LipSize}"`
    : '';
  let offsetDisplay = productDetail.Offset ? `${productDetail.Offset}mm` : '';
  return `${offsetDisplay}${lipSizeDisplay}`;
};

const getDisplayKey = (
  createDisplayKey: (productDetail: WheelStyleDetail) => string,
  wheelId: string | undefined,
  items: WheelStyleDetail[] | undefined,
) => {
  if (!items) return undefined;
  const productDetail = items.find(
    (productDetail) => productDetail.Id.toString() === wheelId,
  );
  return productDetail ? createDisplayKey(productDetail) : undefined;
};
