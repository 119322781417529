import { ChevronLeft, Search, X } from 'lucide-react';
import React, {
  ChangeEvent,
  ChangeEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import useSearch from '@/hooks/useSearch';
import _ from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFilterMenuStore, useStore } from '../lib/store';
import FilterButton from './filter-button';
import ToggleButtonTheme from './toggle-button-theme';
import { Button } from './ui/button';
import SearchByVehicle from './search-by-vehicle';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from './ui/dialog';
import { Drawer, DrawerContent, DrawerHeader, DrawerTitle, DrawerTrigger } from './ui/drawer';

const FilterResultsBar: React.FC<any> = ({
  showNavigation = true,
  showDarkModeToggle = true,
}: {
  showNavigation?: boolean;
  showDarkModeToggle?: boolean;
}) => {
  const filterMenuOpen = useFilterMenuStore((state) => state.filterMenuOpen);
  const setFilterMenuOpen = useFilterMenuStore(
    (state) => state.setFilterMenuOpen,
  );
  const filtersCount = useFilterMenuStore((state) => state.filtersCount);
  const brandIds = useStore((state) => state.currentBrandIds);
  const setProducts = useStore((state) => state.setProducts);
  const isLeftDrawerOpen = useStore((state) => state.isLeftDrawerOpen);
  const isThreeColumnView = useStore((state) => state.isThreeColumnView);
  const { search } = useSearch();

  const location = useLocation();
  const navigate = useNavigate();
  const urlSearchParams = new URLSearchParams(location.search);
  const [queryValue, setQueryValue] = useState(
    urlSearchParams.get('search') || '',
  );
  const inputTouched = useRef(false);
  const searchActive = useRef(false);

  const debouncedSearch = useCallback(
    _.debounce(async (queryValue) => {
      if (
        queryValue.length >= 3 &&
        inputTouched.current &&
        !searchActive.current
      ) {
        searchActive.current = true;
        debouncedSearch.cancel();
        const newUrlSearchParams = new URLSearchParams(location.search);
        newUrlSearchParams.set('search', queryValue);
        navigate({ search: newUrlSearchParams.toString() }, { replace: true });
        const products_result = await search({
          query: queryValue,
          brandIds: brandIds || undefined,
        });
        setProducts(products_result);
        setTimeout(() => (searchActive.current = false), 1000);
      }
    }, 500),
    [queryValue],
  );

  useEffect(() => {
    if (queryValue.length >= 3) {
      debouncedSearch(queryValue);
    }
    if (queryValue === '') {
      clearSearch();
    }
    return () => {
      debouncedSearch.cancel();
    };
  }, [queryValue, debouncedSearch]);

  async function clearSearch() {
    setQueryValue('');
    debouncedSearch.cancel();
    const newUrlSearchParams = new URLSearchParams(location.search);
    newUrlSearchParams.delete('search');
    navigate({ search: newUrlSearchParams.toString() }, { replace: true });
    const products_result = await search({});
    setProducts(products_result);
  }
  function handleSearch(e: ChangeEvent<HTMLInputElement>) {
    if (e.target.value === '') clearSearch();
    setQueryValue(e.target.value);
    inputTouched.current = true;
  }
  return (
    <div className="flex flex-col items-center mx-auto ">
      <div
        className={`grid w-full  grid-cols-[1fr,auto] gap-3 flex flex-col items-start justify-start mb-5 ${!isLeftDrawerOpen && false && 'grid-cols-none bp1080:grid-cols-[1fr,auto]'}`}
      >
        {showNavigation && (
          <div
            onClick={() =>
              navigate(location.pathname === '/catalog' ? '/' : '/catalog')
            }
            className={`cursor-pointer flex flex-row items-center justify-start text-lg -ml-1`}
          >
            <ChevronLeft
              strokeWidth={2}
              size={25}
              className="text-foreground mr-2"
            />
            {location.pathname === '/catalog'
              ? 'Home'
              : 'Back to wheel catalog'}
          </div>
        )}

        {showDarkModeToggle && (
          <div
            className={`gap-2 gap-x-4 flex flex-col grid grid-cols-[1fr] w-full  justify-between`}
          >
            {/* <div className=" justify-self-end">
            <ToggleButtonListGrid />
          </div> */}
            <div className={``}>
              <ToggleButtonTheme />
            </div>
          </div>
        )}
      </div>

      <div className="grid grid-cols-[1fr,auto] w-full gap-3 place-items-stretch">
        <div className="whitespace-nowrap">
          <div className="relative max-w-[444px]">
            <label
              htmlFor="input-wheel-search-home"
              className="absolute inset-y-0 left-3 flex items-center"
            >
              <Search
                size={20}
                strokeWidth={1.5}
                className="text-gray-800 dark:text-gray-500"
              />
            </label>
            <input
              id="input-wheel-search-home"
              className="rounded-xl border  py-1 pl-[35px] w-full max-w-[444px] h-11 bg-input outline-primary focus:outline-primary active:outline-primary focus:ring-0 active:ring-0 focus:ring-offset-0 active:ring-offset-0 text-base placeholder:text-sm"
              placeholder="Search (at least 3 characters)"
              value={queryValue}
              onChange={handleSearch}
              onKeyUp={(e: any) =>
                e.key === 'Enter' && e.target.value.length < 3 && clearSearch()
              }
            />

            {queryValue.length > 0 && (
              <Button
                variant="secondary"
                size="xs"
                className="absolute right-2 top-1/2 -translate-y-1/2 bg-gray-200 rounded-lg !p-1 !h-6 !w-6"
                onClick={() => clearSearch()}
              >
                <X size={20} strokeWidth={3} className="text-gray-800" />
              </Button>
            )}
          </div>
        </div>
        <FilterButton />
        <div className="hidden md:block">
          <Dialog>
            <DialogTrigger>Search By Vehicle</DialogTrigger>
            <DialogContent className="z-[5000000] max-w-[676px] w-full flex flex-col max-h-[80%] py-8 my-8 border-2 px-[32px] transition-all duration-500 ease-in-out animate-in">
              <DialogHeader>
                <DialogTitle className="text-center">
                  <h3 className="text-2xl font-semibold">Search By Vehicle</h3>
                </DialogTitle>
              </DialogHeader>
              <div className=" w-full h-full overflow-hidden">
                <SearchByVehicle />
              </div>
            </DialogContent>
          </Dialog>
        </div>
        <div className='md:hidden'>
          <Drawer>
            <DrawerTrigger>Search By Vehicle</DrawerTrigger>
            <DrawerContent className="z-[5000000] max-w-[676px] w-full flex flex-col max-h-[80%] py-8 my-8 border-2 px-[32px] transition-all duration-500 ease-in-out animate-in">
              <DrawerHeader>
                <DrawerTitle className="text-center">
                  <h3 className="text-2xl font-semibold">Search By Vehicle</h3>
                </DrawerTitle>
              </DrawerHeader>
              <div className=" w-full h-full overflow-hidden">
                <SearchByVehicle />
              </div>
            </DrawerContent>
          </Drawer>
        </div>
      </div>
    </div>
  );
};

export default FilterResultsBar;
