// src/hooks/useSetSiteCustomizations.ts

import { DomainResult } from '@/lib/types';
import { useCallback, useEffect } from 'react';
import { useCompanyStore } from '../lib/store';

const useSetSiteCustomizations = () => {
  const setCompanyDisplayName = useCompanyStore((state) => state.setCompanyDisplayName);
  const setDomainName = useCompanyStore((state) => state.setDomainName);
  const setLogoUrl = useCompanyStore((state) => state.setLogoUrl);
  const setGaKey = useCompanyStore((state) => state.setGaKey);
  const setPrimaryColor = useCompanyStore((state) => state.setPrimaryColor);
  const primaryColor = useCompanyStore((state) => state.primaryColor);

  const setSiteCustomizations = useCallback((domainResult: DomainResult): void => {
    setCompanyDisplayName(domainResult.company_name);
    setDomainName(domainResult.name);
    setLogoUrl(domainResult.photo);
    setGaKey(domainResult.gakey);
    setPrimaryColor(domainResult.color);
  }, [setCompanyDisplayName, setDomainName, setLogoUrl, setGaKey, setPrimaryColor]);

  useEffect(() =>
  {
    if (primaryColor)
    {
      document.documentElement.style.setProperty('--primary', primaryColor);
      // document.documentElement.style.setProperty('--primary-dark', primaryColor); // Ensure this variable is used in dark mode
    }
  }, [primaryColor]);

  return { setSiteCustomizations };
};

export default useSetSiteCustomizations;
