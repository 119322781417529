// autoviz-component-monorepo/packages/product-catalog/src/components/product-catalog.tsx
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from './ui/alert-dialog';
import { Input } from './ui/input';

import { useStore } from '../lib/store';
import React, { useEffect, useState } from 'react';
import { buttonVariants } from './ui/button';
import { cn } from '@/lib/utils';

const AlertDialogComponent: React.FC = () => {
  const [inputValue, setInputValue] = useState('');
  const alertOpen = useStore((state) => state.alertOpen);
  const setAlertOpen = useStore((state) => state.setAlertOpen);
  const alertContext = useStore((state) => state.alertContext);
  const alertProps = useStore((state) => state.alertProps);
  const alertCallback = useStore((state) => state.alertCallback);
  const [title, setTitle] = useState('Please add a name for your design');
  const [description, setDescription] = useState<null | string | JSX.Element>(
    null,
  );
  const [actionText, setActionText] = useState('Save');
  const [variant, setVariant] = useState<any>('default');

  useEffect(() => {
    if (alertContext === 'saveDesign') {
      setTitle('Please add a name for your design');
      setActionText('Save');
      setVariant('default');
      setDescription('_input1');
    } else if (alertContext === 'deleteDesign') {
      setTitle('Are you sure you want to delete this design?');
      setActionText('Delete');
      setVariant('destructive');
      setDescription(
        <span className={`text-boxShadow-inner-lg`}>"{alertProps?.name}"</span>,
      );
    }
  }, [alertContext]);

  const handleCancel = () => {
    setAlertOpen(false);
  };

  const handleSave = ({ inputValue }: any) => {
    console.log(`handleSave`, { ...alertProps, inputValue });
    setAlertOpen(false);
    if (alertCallback) alertCallback({ ...alertProps, inputValue });
  };

  return (
    <AlertDialog open={alertOpen}>
      {/* <AlertDialogTrigger>Open</AlertDialogTrigger> */}
      <AlertDialogContent className={`z-[99999999999]`}>
        <AlertDialogHeader>
          <AlertDialogTitle>{title}</AlertDialogTitle>
          <AlertDialogDescription>
            {description == '_input1' ? (
              <Input
                placeholder="Enter design name"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
              />
            ) : (
              description
            )}
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel onClick={() => handleCancel()}>
            Cancel
          </AlertDialogCancel>
          <AlertDialogAction
            onClick={() => handleSave({ inputValue })}
            className={cn(buttonVariants({ variant: variant }))}
          >
            {actionText}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default AlertDialogComponent;
