import { ArrowLeft, ChevronLeft, Search, X } from 'lucide-react';
import React, { ReactNode } from 'react';
import { Button, buttonVariants } from './ui/button';
import { Carousel, CarouselContent, CarouselItem } from './ui/carousel';
import { WheelGesturesPlugin } from 'embla-carousel-wheel-gestures';
import { cn } from '@/lib/utils';
import SelectSearchedVehicle from './select-searched-vehicle';
import { useVehicleSearch } from '@/lib/store';
import SelectSearchVehicleYear from './select-search-vehicle-year';
import SelectSearchVehicleType from './select-search-vehicle-type';
import SelectSearchVehicleModel, {
  SelectSearchVehicleTrim,
} from './select-vehicle-model';
import SelectSearchVehicleWheelSize from './select-search-vehicle-wheel-size';
import { useFetchVehicle } from '@/hooks/useFetchVehicle';

type StepperType = {
  title: string;
  component: ReactNode;
  backButton: {
    title: string;
  } | null;
};

const step_mapper: Record<number, StepperType> = {
  0: {
    title: 'Select Make',
    backButton: null,
    component: <SelectSearchedVehicle />,
  },
  1: {
    title: 'Select Year',
    backButton: {
      title: 'Back to Make',
    },
    component: <SelectSearchVehicleYear />,
  },
  2: {
    title: 'Select vehicle type',
    backButton: {
      title: 'Back to year',
    },
    component: <SelectSearchVehicleType />,
  },
  3: {
    title: 'Select Model',
    backButton: {
      title: 'Back to vehicle type',
    },
    component: <SelectSearchVehicleModel />,
  },
  4: {
    title: 'Select Trim',
    backButton: {
      title: 'Back to model',
    },
    component: <SelectSearchVehicleTrim />,
  },
  5: {
    title: 'Select wheel size',
    backButton: {
      title: 'Back to model type',
    },
    component: <SelectSearchVehicleWheelSize />,
  },
};

export default function SearchByVehicle() {
  const { decrementStep, step } = useVehicleSearch((state) => state);
  const {
    SetVehicleMake,
    setStep,
    incrementStep,
    vehicleMake,

    vehicleModel,
    vehicleSize,
    vehicleTrim,
    vehicleType,
    vehicleYear,
  } = useVehicleSearch((state) => state);
  return (
    <div className="h-full">
      {/* Search Bar Starts */}
      <div className="whitespace-nowrap ">
        <div className="relative ">
          <label
            htmlFor="input-wheel-search-home"
            className="absolute inset-y-0 left-3 flex items-center"
          >
            <Search
              size={20}
              strokeWidth={1.5}
              className="text-gray-800 dark:text-gray-500"
            />
          </label>
          <input
            id="input-wheel-search-home"
            className="rounded-xl border  py-1 pl-[35px] w-full  h-11 bg-input outline-primary focus:outline-primary active:outline-primary focus:ring-0 active:ring-0 focus:ring-offset-0 active:ring-offset-0 text-base placeholder:text-sm"
            placeholder="Search (at least 3 characters)"
          />
          <Button
            variant="secondary"
            size="xs"
            className="absolute right-2 top-1/2 -translate-y-1/2 bg-gray-200 rounded-lg !p-1 !h-6 !w-6"
          >
            <X size={20} strokeWidth={3} className="text-gray-800" />
          </Button>
        </div>
      </div>
      {/* Search Bar ends */}
      <TitleWithBackButton
        backButton={{
          func() {
            decrementStep();
          },
          title: step_mapper[step].backButton?.title,
        }}
        title={step_mapper[step].title}
      />
      <ProgressBar progress={((step + 1) * 100) / 6} />
      <div className="flex item-center justify-center mt-2 h-full  ">
        {step_mapper[step].component}
      </div>
    </div>
  );
}

export function ProgressBar({ progress = 25 }: { progress: number }) {
  return (
    <div className="w-full h-[2px] bg-gray-400 mt-4 relative transition-transform duration-700 ease-in-out">
      <div
        style={{
          width: `${progress}%`,
        }}
        className="bg-primary h-[3px] absolute bottom-0 transition-transform duration-700 ease-in-out"
      />
    </div>
  );
}

export function TitleWithBackButton({
  backButton,
  title,
}: {
  backButton: {
    func: () => void;
    title?: string;
  } | null;
  title: string;
}) {
  return (
    <div className="mt-6">
      <div className="grid grid-cols-3">
        <div className={cn('flex')}>
          <button
            onClick={() => {
              if (backButton?.title) {
                backButton.func();
                // backButton?.func();
              }
            }}
            className={cn(
              'flex items-center justify-center hover:bg-muted py-2 pl-3 pr-4 rounded-md text-base',
              {
                hidden: !backButton?.title,
              },
            )}
          >
            <ChevronLeft size={18} />
            {backButton?.title}
          </button>
        </div>
        <div className="flex items-center justify-center text-center">
          <p className="text-base">{title}</p>
        </div>
        <div />
      </div>
    </div>
  );
}
