// autoviz-component-monorepo/packages/product-catalog/src/components/wheel-configurator/dropdown-size-selector.tsx

import ProductDetailSizeSelector from '@/components/product-detail-size-selector';
import { useFilterMenuStore, useStore } from '@/lib/store';
import { configuratorPath } from '@/lib/utils';
import React, { useEffect } from 'react';
import DropdownMenuWrapper from './dropdown-menu-wrapper';
import DropdownMenuCatalog from './dropdown-menu-catalog';

const DropdownMenuSizeSelector: React.FC = () => {
  const currentAutosyncApiWheelStyles = useStore(
    (state) => state.currentAutosyncApiWheelStyles,
  );

  return (
    <DropdownMenuWrapper title="Wheel specifications" className={`p-4`}>
      {/* <DropdownMenuWrapper title="Models" className={`p-4`}> */}

      <div className={`relative`}>
        <div className={`text-sm py-2`}>Wheel Model</div>
        <DropdownMenuCatalog
          classNameObject={{
            wrapper: `absolute  min-w-[400px] h-[calc(100vh-200px)] -left-0 top-[0px] `,
          }}
        />
      </div>
      {/* </DropdownMenuWrapper> */}

      <div className={`px-4 border-t mx-auto my-6`}></div>
      <ProductDetailSizeSelector
        items={currentAutosyncApiWheelStyles}
        navigationPathRoot={configuratorPath}
      />
    </DropdownMenuWrapper>
  );
};

export default React.memo(DropdownMenuSizeSelector);
