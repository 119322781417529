// autoviz-component-monorepo/packages/product-catalog/src/components/wheel-card.tsx

import { useStore } from '@/lib/store';
import { AutovizDbWheelModel, WheelStyle } from '@/lib/types';
import { disableRightClick, imgSrcFromWheelModel } from '@/lib/utils';
import React, { useState } from 'react';
import { Loader } from './loader';
import ModelViewer from './model-viewer';

const WheelMedia: React.FC<{
  currentAutovizDbWheelModel?: AutovizDbWheelModel | WheelStyle;
  allow3d?: boolean;
  index?: number;
  autoRotate?: boolean;
  src?: string;
}> = ({
  currentAutovizDbWheelModel,
  allow3d = false,
  index = 0,
  autoRotate = true,
  src,
}) => {
  const handleModelViewerClick = (e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent the click event from bubbling up
  };

  const [imageLoaded, setImageLoaded] = useState(false);
  const handleImageLoad = () => setImageLoaded(true);

  // const handleMouseOver = () =>
  // {
  //   if (currentAutovizDbWheelModel?.data?.path)
  //   {
  //     if (show3DModel !== null) setShow3DModel(true);
  //   }
  // };

  const handleMouseOut = () => {
    // setShow3DModel(false);
  };

  const setShow3DModel = useStore((state) => state.setShow3DModel);

  const show3DModel = useStore((state) => state.show3DModel);

  let resolvedModel =
    (currentAutovizDbWheelModel as WheelStyle)?.Model ||
    (currentAutovizDbWheelModel as AutovizDbWheelModel)?.model;
  let resolvedBrand =
    (currentAutovizDbWheelModel as WheelStyle)?.Brand ||
    (currentAutovizDbWheelModel as AutovizDbWheelModel)?.brand;
  let resolvedPartNumber =
    (currentAutovizDbWheelModel as WheelStyle)?.Model ||
    (currentAutovizDbWheelModel as AutovizDbWheelModel)?.model;

  src = src || imgSrcFromWheelModel(currentAutovizDbWheelModel);
  const alt = `${resolvedModel} ${resolvedPartNumber} by ${resolvedBrand}`;

  return (
    <div className={`relative w-full`}>
      {/* <div className={`relative pt-[100%] h-full w-full`}>
        <ImageWithFadeIn src={src} alt={alt} className={`absolute top-0 left-0 h-full w-full object-cover`} />
      </div> */}

      <div className={`pt-[100%] relative`}>
        {!imageLoaded && (
          <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
            <Loader className={`h-[20%] w-[20%] opacity-60`} />
          </div>
        )}

        {show3DModel === true && false ? (
          <ModelViewer
            src={''} //currentAutovizDbWheelModel?.data?.path || ''}
            alt={alt}
            autoRotate={autoRotate}
            shadowIntensity={1}
            onClick={handleModelViewerClick}
          />
        ) : (
          <img
            loading={index === 0 ? 'eager' : 'lazy'}
            src={src}
            alt={alt}
            onContextMenu={disableRightClick}
            className={`absolute top-0 left-0 w-full h-full object-cover transition-opacity duration-500 ${imageLoaded ? 'opacity-100' : 'opacity-0'}`}
            onLoad={handleImageLoad}
          />
        )}
      </div>
    </div>
  );
};

export default React.memo(WheelMedia);