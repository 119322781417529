import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import React, { useState } from 'react';

import { Button } from '@/components/ui/button';

import { useStore } from '@/lib/store';
import { Minus, Plus } from 'lucide-react';
import { twMerge } from 'tailwind-merge';
import BrandDisplayImage from './brand-display-image';
import RequestQuoteForm from './quote-request-form';
import { WheelSubModelDisplay } from './text-displays-misc';
import WheelMedia from './wheel-media';

const QuoteRequest: React.FC<any> = ({
  classNameButton = '',
  openCallback,
  closeCallback,
}: {
  classNameButton?: string;
  openCallback?: () => void;
  closeCallback?: () => void;
} = {}) => {
  const currentAutosyncApiWheelStyle = useStore(
    (state) => state.currentAutosyncApiWheelStyle,
  );
  const currentAutovizDbWheelModel = useStore(
    (state) => state.currentAutovizDbWheelModel,
  );
  const [open, setOpen] = React.useState(false);

  const handleOpenChange = (open: boolean) => {
    setOpen(open);
    if (open && openCallback) openCallback();
    if (!open && closeCallback) closeCallback();
  };

  const [quantityValue, setQuantityValue] = useState(4);

  return (
    <>
      <Dialog open={open} onOpenChange={handleOpenChange}>
        <DialogTrigger asChild>
          <Button
            size={'default'}
            className={twMerge(
              '!p-4 rounded-xl !h-14 text-base !text-primary-foreground bg-black dark:bg-white dark:!text-black w-full',
              classNameButton,
            )}
          >
            Request a quote
          </Button>
        </DialogTrigger>
        <DialogContent
          className={`z-[999999999999] bg-card p-0 w-full max-w-4xl max-h-screen overflow-scroll scrollbar-hidden safe-padding `}
        >
          <div className={`grid md:grid-cols-[60%,1fr] w-full`}>
            <div className={`p-8 rounded-l-lg pt-16 `}>
              <div
                className={`grid  grid-cols-[60%,1fr] justify-center md:gap-y-14  lg:gap-y-8 xl:gap-y-12   items-start`}
              >
                {/* Wheel Media starts */}

                <div
                  className={`flex flex-col  justify-center items-center w-full h-full relative pt-10`}
                >
                  {currentAutovizDbWheelModel && (
                    <WheelMedia
                      currentAutovizDbWheelModel={currentAutovizDbWheelModel}
                      allow3d={false}
                    />
                  )}
                </div>
                {/* Wheel Media Ends */}

                {/* aside Title and Quantity starts */}
                <div className={`pt-20 p-2  `}>
                  <div className={`max-w-[120px] pb-4`}>
                    <BrandDisplayImage />
                  </div>
                  <h2 className="text-xl  leading-tight">
                    {currentAutovizDbWheelModel?.model}
                  </h2>
                  <WheelSubModelDisplay
                    product={currentAutosyncApiWheelStyle}
                    className={`text-xs`}
                  />
                  <div className={`pt-10`}>
                    {' '}
                    <QuantityInput
                      label="Quanity"
                      value={quantityValue}
                      setQuantityValue={setQuantityValue}
                    />
                  </div>
                </div>
                {/* aside Title and Quantity Ends */}

                {/* Details starts */}
                <div className={`col-span-full    mt-4`}>
                  <div
                    className={`col-span-full py-1 text-left grid grid-cols-[repeat(auto-fit,minmax(80px,auto))] gap-4`}
                  >
                    <div className="">
                      <WheelDetailProperty
                        label="P/N"
                        value={currentAutosyncApiWheelStyle?.Pn}
                      />
                    </div>

                    <WheelDetailProperty
                      label="Bolt pattern"
                      value={`${currentAutosyncApiWheelStyle?.LugCount} x ${currentAutosyncApiWheelStyle?.BoltCircle1}`}
                    />
                    <WheelDetailProperty
                      label="Size"
                      value={`${currentAutosyncApiWheelStyle?.Diameter}" x ${currentAutosyncApiWheelStyle?.Width}" `}
                    />
                    <WheelDetailProperty
                      label="Offset"
                      value={`${currentAutosyncApiWheelStyle?.Offset} mm`}
                    />

                    <WheelDetailProperty
                      label="Color"
                      value={currentAutosyncApiWheelStyle?.Color}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={`p-8 bg-background`}>
              <DialogTitle
                className={`mb-3 text-foreground text-xl font-bold mx-auto`}
              >
                Request a Quote
              </DialogTitle>
              <RequestQuoteForm
                productDetail={currentAutosyncApiWheelStyle}
                setParentDialogOpen={setOpen}
                quantity={quantityValue}
              />
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default QuoteRequest;

export const WheelDetailProperty: React.FC<{ label: string; value: any }> = ({
  label,
  value,
}) => {
  return (
    <>
      <div className={`text-xs  text-left break-words`}>
        <div className={`pb-2 `}>{label}</div>
        <div className={`font-semibold text-xs`}>{value}</div>
      </div>
    </>
  );
};
export const QuantityInput: React.FC<{
  label: string;
  value: number;
  setQuantityValue: Function;
}> = ({ label, value, setQuantityValue }) => {
  const handleClick = (e: any, dir: number) => {
    let newValue = value + dir;
    if (newValue < 1) newValue = 1;
    if (newValue > 99) newValue = 99;
    setQuantityValue(newValue);
  };
  return (
    <>
      <div className={`pb-2`}>{label}</div>

      <div
        className={`grid grid-cols-[32px,40px,32px] items-center justify-center flex flex-col rounded-lg  overflow-hidden w-[100px] h-[32px]`}
      >
        <Button
          onClick={(e) => handleClick(e, -1)}
          variant={'muted'}
          size={'sm'}
          className={`bg-gray-400 text-primary-foreground !border-none rounded-none !p-0  !h-[32px] flex flex-col items-center justify-center`}
        >
          <Minus strokeWidth={2.5} className={`h-4 w-4`} />
        </Button>
        <div
          className={`bg-background text-foreground flex flex-col items-center justify-center w-[40px] !h-[32px] border-t-1 border-b-1`}
        >
          {value}
        </div>
        <Button
          onClick={(e) => handleClick(e, 1)}
          variant={'secondary'}
          size={'sm'}
          className={`bg-primary text-primary-foreground !border-none rounded-none !p-0 !h-[32px] flex flex-col items-center justify-center`}
        >
          <Plus strokeWidth={2.5} className={`h-4 w-4`} />
        </Button>
      </div>
    </>
  );
};
