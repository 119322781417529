// src/hooks/useSearch.ts
import { useSearchStore, useStore } from '../lib/store';
import { apiEndpoint } from '../lib/utils';
import useFetchAutovizDbWheelModels from './useFetchAutovizDbWheelModels';

const useSearch = () =>
{

  const setSearchLoading = useSearchStore((state) => state.setSearchLoading);
  const setImgUrlBase = useStore((state) => state.setImgUrlBase);
  const setProductsLoadingTrigger = useStore((state) => state.setProductsLoadingTrigger);
  const fetchFromAutovizDb = useStore((state) => state.fetchFromAutovizDb);
  const { fetchAutovizDbWheelModels } = useFetchAutovizDbWheelModels();

  const search = async ({ query, brandIds = "" }: { query?: string, brandIds?: string } = {}) =>
  {
    setSearchLoading(true);
    setProductsLoadingTrigger("search");
    const formData = new FormData();

    formData.append('data', JSON.stringify({ query, brandIds }));

    if (fetchFromAutovizDb)
    {
      const res = await fetchAutovizDbWheelModels({ search: query })

      setSearchLoading(false);
      return res.products
    }
    else
    {
      const response = await fetch(`${apiEndpoint}/_search.php`, {
        method: 'POST',
        body: formData,
      });

      const res = await response.json();

      const imgUrlBase = res?.data?.ImgUrlBase;
      setImgUrlBase(imgUrlBase);
      const products_result = Array.isArray(res?.data?.WheelStyles) ? res.data.WheelStyles : [];


      setSearchLoading(false);
      return products_result;
    }


  };


  return { search };
};

export default useSearch;
