import React, { Dispatch, SetStateAction, useState } from 'react';
import { Carousel, CarouselContent, CarouselItem } from './ui/carousel';
import { WheelGesturesPlugin } from 'embla-carousel-wheel-gestures';
import { useVehicleSearch } from '@/lib/store';
import { useFetchVehicle } from '@/hooks/useFetchVehicle';

export default function SelectSearchedVehicle() {
  let vehicleLogo = new Array(40).fill(null);
  const {
    SetVehicleMake,
    vehicleMake,
    vehicleModel,
    vehicleSize,
    vehicleTrim,
    vehicleType,
    vehicleYear,
  } = useVehicleSearch((state) => state);
  const { vehicleMake: vehicleMakeData } = useFetchVehicle();
  const [selectedLetter, setSelectedLetter] = useState('');
  return (
    <div className="overflow-x-hidden">
      <WordsMap
        selectedLetter={selectedLetter}
        setSelectedLetter={setSelectedLetter}
      />
      <div className="mt-4 overflow-y-scroll scrollbar-w-2 scrollbar-thumb-rounded scrollbar-track-primary scrollbar-thumb-muted">
        <div className="grid grid-cols-3 gap-2 max-h-[500px] mx-2">
          {vehicleMakeData &&
            vehicleMakeData.Makes &&
            vehicleMakeData.Makes.filter((fv) =>
              fv.Make.toLowerCase().startsWith(selectedLetter.toLowerCase()),
            ).map((item, index) => {
              return (
                <button
                  onClick={() => {
                    // handle Exact vehicle state logic here.
                    SetVehicleMake(item.Make);
                  }}
                  className="items-center justify-evenly flex  py-6  border rounded-2xl"
                >
                  <div className="">
                    <img
                      src={`${vehicleMakeData.LogoUrlBase}${item.Logo}`}
                      alt=""
                      className="max-w-8 max-h-8"
                    />
                  </div>
                  <h1 className="text-xl ">{item.Make}</h1>
                </button>
              );
            })}
        </div>
      </div>
    </div>
  );
}

export function WordsMap({
  setSelectedLetter,
  selectedLetter,
}: {
  selectedLetter: string;
  setSelectedLetter: Dispatch<SetStateAction<string>>;
}) {
  let WordArray = Array.from({ length: 26 }, (_, i) =>
    String.fromCharCode(65 + i),
  );

  return (
    <Carousel
      className="overflow-hidden"
      opts={{
        dragFree: true,
        duration: 120,
        align: 'start',
      }}
      plugins={[
        WheelGesturesPlugin({
          forceWheelAxis: 'x',
        }),
      ]}
    >
      <CarouselContent className="">
        {WordArray.map((item) => (
          <CarouselItem
            onClick={() => {
              if (item.toLowerCase() === selectedLetter.toLowerCase()) {
                setSelectedLetter('');
                return;
              }
              setSelectedLetter(item);
            }}
            // className=""
            className={`basis-[1/${WordArray.length}]  hover:text-primary flex items-center justify-center cursor-pointer`}
          >
            {item}
            {/* <button>{item}</button> */}
          </CarouselItem>
        ))}
      </CarouselContent>
    </Carousel>
  );
}
