import React from 'react';

import { WheelStyleDetail } from '../lib/types';
import { Button } from './ui/button';

import
  {
    Popover,
    PopoverContent,
    PopoverTrigger,
  } from "@/components/ui/popover";
import { resolveWheelOffsetDisplayText, WheelSubModelDisplay } from './text-displays-misc';

const ProductDetailPopover: React.FC<{ productDetail: WheelStyleDetail | null }> = ({ productDetail }) =>
{

  if (!productDetail) return null;
  
  const detailNameClassName = "text-sm p-1 pl-2 font-bold bg-white dark:bg-black/60 text-shadow-md text-shadow-white dark:drop-shadow-black"
  const detailValueClassName = "text-sm p-1 pl-2 font-normal bg-white dark:bg-black/60 h-full"
  const detailContainer = "flex flex-col  border-l-4 border-primary overflow-hidden shadow-xl"
  return (
    <div className={``}>
      <Popover>
        <PopoverTrigger asChild><Button variant={"link"} size={"sm"} className='font-bold text-base underline text-foreground px-0 mt-3'>All Details</Button></PopoverTrigger>
        <PopoverContent className={`w-full !bg-transparent h-full backdrop-blur-xl z-[9999] border-1 border-primary top-0 left-0 `}
          align='end' side='top' avoidCollisions={true} sideOffset={10}>

          <div className="grid grid-cols-2 lg:grid-cols-4 w-full gap-2">

            <div className={`col-span-full`}>

              <div className={`text-2xl grid grid-cols-[auto,1fr] gap-3  flex flex-col items-center justify-ceter w-full my-2 `}>
                <div className="text-2xl font-bold pr-3 border-r-1 border-gray-300 h-full flex flex-col items-start justify-center">{productDetail?.Model}</div>
                <WheelSubModelDisplay product={productDetail} className={`text-lg fong-bold text-foreground `} />
              </div>

            </div>

            <div className={detailContainer}>
              <div className={detailNameClassName}>P/N</div>
              <div className={detailValueClassName}>{productDetail?.Pn}</div>
            </div>
            <div className={detailContainer}>
              <div className={detailNameClassName}>MODEL</div>
              <div className={detailValueClassName}>{productDetail?.Model}</div>
            </div>
            <div className={detailContainer}>
              <div className={detailNameClassName}>SIZE</div>
              <div className={detailValueClassName}>{productDetail?.Diameter}"x{productDetail?.Width}"</div>
            </div>
            <div className={detailContainer}>
              <div className={detailNameClassName}>OFFSET</div>
              <div className={detailValueClassName}>{resolveWheelOffsetDisplayText(productDetail)}</div>
            </div>

            <div className={detailContainer}>
              <div className={detailNameClassName}>BOLT PATTERN</div>
              <div className={detailValueClassName}>{productDetail?.LugCount}x{productDetail?.BoltCircle1}</div>
            </div>
            <div className={detailContainer}>
              <div className={detailNameClassName}>BORE</div>
              <div className={detailValueClassName}>{productDetail?.Bore || 'N/A'}</div>
            </div>
            <div className={detailContainer}>
              <div className={detailNameClassName}>LOAD RATING</div>
              <div className={detailValueClassName}>{productDetail?.LoadRating||'N/A'}</div>

            </div>
            <div className={detailContainer}>
              <div className={detailNameClassName}>WEIGHT</div>
              <div className={detailValueClassName}>{productDetail?.Weight ? productDetail?.Weight + ' LBS' : 'N/A'}</div>

            </div>
            <div className={detailContainer}>
              <div className={detailNameClassName}>COLOR</div>
              <div className={detailValueClassName}>{productDetail?.Color||'N/A'}</div>
            </div>
            <div className={detailContainer}>
              <div className={detailNameClassName}>STRUCTURE</div>
              <div className={detailValueClassName}>{productDetail?.Structure||'N/A'}</div>
            </div>
            <div className={detailContainer}>
              <div className={detailNameClassName}>ACCENT</div>
              <div className={detailValueClassName}>{productDetail?.Accent||'N/A'}</div>

            </div>
            <div className={detailContainer}>
              <div className={detailNameClassName}>CAP P/N</div>
              <div className={detailValueClassName}>{productDetail?.Cap?.Pn||'N/A'}</div>
            </div>
          </div></PopoverContent>
      </Popover>
</div>
  )
}

export default ProductDetailPopover;