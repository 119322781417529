import { useStore } from '@/lib/store';
import { LoaderCircle } from 'lucide-react';
import { useEffect, useState } from 'react';

import { twMerge } from 'tailwind-merge';
export const Loader = ({
  className,
  visible,
}: {
  className?: string;
  visible?: boolean;
}) => {
  const appInitialized = useStore((state) => state.appInitialized);

  const [render, setRender] = useState(true);
  useEffect(() => {
    if (visible) setRender(true);
    else {
      setTimeout(() => {
        setRender(false);
      }, 4000);
    }
  }, [visible]);
  return (
    <>
      {render && (
        <LoaderCircle
          style={{
            opacity: visible ? 0.6 : 0,
            transition: 'opacity 319ms ease-in-out',
            animation: 'spin 681ms linear infinite',
          }}
          className={twMerge(
            'text-foreground',
            className,
            appInitialized ? 'text-primary' : '',
          )}
        />
      )}
    </>
  );
};