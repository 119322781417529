export const colors: SwatchType[] = [
  {
    displayName: 'Chrome',
    name: 'chrome',
    hex: '#e2e2e2',
    metallicFactor: 1,
    roughnessFactor: 0,
    texture: null,
    swatchSample: `chrome.webp`,
  },
  {
    displayName: 'Polished',
    name: 'polished',
    hex: '#FFFFFF',
    metallicFactor: 1,
    roughnessFactor: 0.12,
    texture: null,
    swatchSample: `chrome.webp`,
  },
  {
    displayName: 'Brushed Silver',
    name: 'brushed-silver',
    hex: null,
    metallicFactor: 1,
    roughnessFactor: 1,
    texture: 'brushed.jpg',
    swatchSample: `chrome.webp`,
  },
  { displayName: '12K Gold', name: '12k-gold', hex: '#D6B447' },
  { displayName: '24K Gold', name: '24k-gold', hex: '#E0B53F' },
  { displayName: 'Rose Gold', name: 'rose-gold', hex: '#E3B3A4' },
  { displayName: 'Copper', name: 'copper', hex: '#B87333' },
  { displayName: 'Bronze', name: 'bronze', hex: '#8C5A2B' },
  { displayName: 'White', name: 'white', hex: '#FFFFFF' },
  { displayName: 'Silver', name: 'silver', hex: '#C0C0C0' },
  { displayName: 'Gunmetal', name: 'gunmetal', hex: '#545454' },
  { displayName: 'Anthracite', name: 'anthracite', hex: '#383838' },
  { displayName: 'Black', name: 'black', hex: '#000000' },
  { displayName: 'Light Red', name: 'light-red', hex: '#FF0000' },
  { displayName: 'Dark Red', name: 'dark-red', hex: '#8B0000' },
  { displayName: 'Olive Green', name: 'olive-green', hex: '#556B2F' },
  { displayName: 'Light Blue', name: 'light-blue', hex: '#00BFFF' },
  { displayName: 'Dark Blue', name: 'dark-blue', hex: '#00008B' },
];

export const finishes: SwatchType[] = [
  {
    displayName: 'Matte',
    name: 'matte',
    metallicFactor: 0.75,
    roughnessFactor: 0.6,
    texture: null,
    swatchSample: `chrome.webp`,
  },
  {
    displayName: 'Satin',
    name: 'satin',
    metallicFactor: 0.75,
    roughnessFactor: 0.35,
    texture: null,
    swatchSample: `chrome.webp`,
  },
  {
    displayName: 'Gloss',
    name: 'gloss',
    metallicFactor: 0.75,
    roughnessFactor: 0.25,
    texture: null,
    swatchSample: `chrome.webp`,
  },
  {
    displayName: 'Brushed',
    name: 'brushed',
    metallicFactor: 1,
    roughnessFactor: 0,
    texture: 'brushed.jpg',
    swatchSample: `chrome.webp`,
  },
];

export const defaultFinish: SwatchType = finishes[2]; //gloss

export const nameToKey = (name: string) => {
  return name.toLowerCase().replace(/\s/g, '_');
};
export const nameToDisplayName = (name: string) => {
  return capitalizeWords(name.replace(/_/g, ' '));
};

export const capitalizeWords = (str: string): string => {
  return str
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};

export const dataObjectInit: PartModel = {
  barrel: {
    displayName: 'Barrel',
    modelKey: 'BASE',
    sort: 0,
    color: null,
    finish: defaultFinish,
    enabled: true,
  },
  cap: {
    displayName: 'Cap',
    modelKey: 'CAP',
    sort: 1,
    color: null,
    finish: defaultFinish,
    enabled: true,
  },
  cap_logo: {
    displayName: 'Cap Logo',
    modelKey: 'CAP TEXT',
    sort: 2,
    color: null,
    finish: defaultFinish,
    enabled: true,
  },
  face: {
    displayName: 'Face',
    modelKey: 'CAP HARDWARE',
    sort: 3,
    color: null,
    finish: defaultFinish,
    enabled: true,
  },
  accent: {
    displayName: 'Accent',
    modelKey: 'ACCENT',
    sort: 4,
    color: null,
    finish: defaultFinish,
    enabled: true,
  },
};

export const swatchesPerRow = 5;
export const maxSwatchesRows = 4;
export const maxRecentColors = 10;
export const wheelConfigModel: any = {
  wheelId: null,
  styleId: null,
  partsConfig: null,
  recentColors: [],
  createdAt: null,
  updatedAt: null,
};
// const resolveSwatchName = (swatches: Swatch[], name: string) => {
//   let swatch = swatches.find((swatch) => swatch.name === name);
//   console.log(`resolveSwatchName`, name);
//   return swatch ? swatch.displayName : "";
// };
export type SwatchType = {
  displayName: string;
  name: string;
  hex?: string | null;
  metallicFactor?: number;
  roughnessFactor?: number;
  texture?: string | null;
  swatchSample?: string;
};
export type PartModel = {
  [key: string]: Part;
};
export type Part = {
  displayName: string;
  modelKey: string;
  sort: number;
  color: SwatchType | null;
  finish: SwatchType | null;
  enabled: boolean;
};

export const finishAllowed = (colorName: any) =>
  !['chrome', 'polished', 'brushed-silver'].includes(colorName);

export const getSwatchByName = (
  swatches: SwatchType[],
  name?: string | null,
): SwatchType | null => {
  let swatch = swatches.find((swatch) => swatch.name === name);
  return swatch ? swatch : null;
};

export const getPartByName = ({
  scopedPartsModel,
  partName,
}: {
  scopedPartsModel?: PartModel;
  partName: string;
}): Part | null => {
  const resolvedPartsDataModel = scopedPartsModel; //|| partsDataModel;
  return resolvedPartsDataModel?.[partName] || null;
};

export const resolveSelectedTextureName = ({
  partsDataModel,
  partName,
}: {
  partsDataModel: PartModel;
  partName: string;
}) => {
  // console.log(`resolveSelectedTextureName`, partsDataModel, partName);
  let colorName = partsDataModel[partName].color?.displayName;
  let finishName = finishAllowed(partsDataModel[partName].color?.name)
    ? partsDataModel?.[partName]?.finish?.displayName
    : '';
  return [colorName, finishName].filter(Boolean).join(' / ').trim();
};

  export const updateAlphaValue = (material: any, alpha: number) => {
    const pbr = material.pbrMetallicRoughness;
    const baseColor = pbr.baseColorFactor;
    baseColor[3] = alpha;
    console.log(`baseColor`, material);
    pbr.setBaseColorFactor(baseColor);
  };

  export const applyTexture = async (
    modelViewer: any,
    material: any,
    textureKeyName: string,
  ) => {
    material.pbrMetallicRoughness.setMetallicFactor(1);
    material.pbrMetallicRoughness.setRoughnessFactor(1);
    material.pbrMetallicRoughness['metallicRoughnessTexture'].setTexture(null);
    const texture_url = `/images/textures/${textureKeyName}`;

    if (modelViewer.createTexture) {
      // console.log(`applyTexture`, texture_url);
      const texture = await modelViewer.createTexture(texture_url);
      material.pbrMetallicRoughness['metallicRoughnessTexture'].setTexture(
        texture,
      );
    }
  };

  export const getEstimatedWheelPosition = ({
    navPartsSwatchesPanelRef,
    navPartsSelectorRef,
    navOpen,
  }: any) => {
    // const modelViewerRefOffset =
    // modelViewerRef.current?.getBoundingClientRect();
    // if (!modelViewerRefOffset) return;
    const modelViewerRefOffsetHeight =
      document.getElementById('model-viewer')?.getBoundingClientRect().height ||
      0;

    const navPartsSelectorRefOffset =
      navPartsSelectorRef.current?.getBoundingClientRect();
    const adjustedLoacationAtRest =
      navPartsSelectorRefOffset?.width ||
      0 + navPartsSelectorRefOffset?.left ||
      0;

    const navPartsSwatchesPanelRefOffset =
      navPartsSwatchesPanelRef.current?.getBoundingClientRect();
    const navPartsSwatchesPanelRefOffsetWidth =
      navPartsSwatchesPanelRefOffset?.width || 0;
    const navPartsSwatchesPanelRefOffsetLeft =
      navPartsSwatchesPanelRefOffset?.left || 0;

    const remainingDisplayAreaWidth =
      window.innerWidth -
      navPartsSwatchesPanelRefOffsetWidth -
      navPartsSwatchesPanelRefOffsetLeft;

    const wheelDisplaySIze = modelViewerRefOffsetHeight * 0.65; //this is an estimate but it also depends on the window width/height ratio, tbd
    const remainingSpace = window.innerWidth - wheelDisplaySIze;

    // console.log({
    //   winddowWidth: window.innerWidth,
    //   wheelDisplaySIze
    //   navPartsSwatchesPanelRefOffsetWidth: navPartsSwatchesPanelRefOffsetWidth,
    //   navPartsSwatchesPanelRefOffsetLeft: navPartsSwatchesPanelRefOffsetLeft,
    //   remainingDisplayAreaWidth: remainingDisplayAreaWidth,
    // });

    let totalOffset = Math.abs(remainingDisplayAreaWidth / 2);

    totalOffset =
      totalOffset > wheelDisplaySIze / 2 ? wheelDisplaySIze / 2 : totalOffset;
    totalOffset = navOpen ? totalOffset : 0;

    return totalOffset;
  };

  export const loadScript = (src: string, type = 'module'): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      const existingScript = document.querySelector(`script[src="${src}"]`);
      if (existingScript) {
        resolve(); // Script already loaded
        return;
      }

      const script = document.createElement('script');
      script.src = src;
      script.type = type;
      script.async = true;
      script.onload = () => resolve();
      script.onerror = () => reject(new Error(`Failed to load script ${src}`));
      document.head.appendChild(script);
    });
  };

  export const loadModelViewerScripts = async () => {
    try {
      // Load import map for `three.js`
      // const importMapScript = document.createElement('script');
      // importMapScript.type = 'importmap';
      // importMapScript.textContent = JSON.stringify({
      //   imports: {
      //     three:
      //       'https://cdn.jsdelivr.net/npm/three@^0.164.1/build/three.module.min.js',
      //   },
      // });
      // document.head.appendChild(importMapScript);

      // Load the `model-viewer` scripts
      await loadScript(
        'https://ajax.googleapis.com/ajax/libs/model-viewer/3.5.0/model-viewer.min.js',
      );
      // await loadScript(
      //   'https://cdn.jsdelivr.net/npm/@google/model-viewer/dist/model-viewer.min.js',
      // );
      // await loadScript(
      //   'https://cdn.jsdelivr.net/npm/@google/model-viewer-effects/dist/model-viewer-effects.min.js',
      // );
    } catch (error) {
      console.error('Failed to load model-viewer scripts:', error);
    }
  };



  export const getMaterialByName = (materials: any[], partName?: string | null) => {
    let material = null;
    if (partName && materials) {
      material = materials.find((material: any) => {
        console.log(`material.name`, material.name, partName);
        return material.name === partName;
      });
    }
    return material;
  };