// src/hooks/useFetchAutovizDbWheelModels.ts
import { useCallback } from 'react';
import { useFilterMenuStore, useStore } from '../lib/store';
import { Filters, WheelStyle } from '../lib/types';
import { apiEndpoint } from '../lib/utils';

const useFetchAutovizDbWheelModels = () =>
{
  const setProductsLoading = useStore((state) => state.setProductsLoading);
  const setImgUrlBase = useStore((state) => state.setImgUrlBase);
  const setMoreItems = useStore((state) => state.setMoreItems);
  const setProducts = useStore((state) => state.setProducts);
  const currentBrandIds = useStore((state) => state.currentBrandIds);
  const sections = useFilterMenuStore((state) => state.sections);
  const setProductsLoadingTrigger = useStore((state) => state.setProductsLoadingTrigger);
  const setCurPage = useStore((state) => state.setCurPage);
  
  const fetchAutovizDbWheelModels = useCallback(async ({ curPage = 1, brandNames, filters, trigger = "", customizable = true, search = "" }: { curPage?: number, brandNames?: string[], filters?: any, trigger?: string, customizable?: boolean, search?: string } = {}): Promise<{products:WheelStyle[] | []}> =>
  {

    setProductsLoading(true);
    setProductsLoadingTrigger(trigger);
    if (trigger !== "scroll") setProducts([])

    setCurPage(curPage);

    filters = filters ? filters : sections
    
    const formData = new FormData();
    formData.append('data', JSON.stringify({ customizable, brandNames, filters, search }));
    // console.log(`fetchAutovizDbWheelModels`, { customizable, brandNames, filters })
    // return { products: [], filters: [] }
    const response = await fetch(`${apiEndpoint}/_get_autoviz_db_wheel_models.php`, {
      method: 'POST',
      body: formData,
    });

    const res = await response.json();
    const imgUrlBase = res?.data?.products?.[0]?.wheel_info?.ImgUrlBase || ""
    setImgUrlBase(imgUrlBase);

    // for now, no pagination
    setMoreItems(false);

    const productsResult = Array.isArray(res?.data?.products) ? res.data.products : [];

    const filtersForProductSet = res?.data?.filters ? res.data.filters : [];
    
    setProductsLoading(false);

    // setInMemoryProducts(productsResult) 
    
    return { products: productsResult }

  }, [setProductsLoading, setImgUrlBase, setMoreItems, setProducts, currentBrandIds, sections]);
  
  const fetchAutovizDbWheelFilters = useCallback(async ({customizable = true }: { customizable?: boolean } = {}): Promise<{filters:Filters}> =>
  {

    const formData = new FormData();
    formData.append('data', JSON.stringify({ customizable }));
    const response = await fetch(`${apiEndpoint}/_get_autoviz_db_wheel_filters.php`, {
      method: 'POST',
      body: formData,
    });

    const res = await response.json();
    const filtersForProductSet = res?.data ? res.data : [];
    
    return { filters: filtersForProductSet }

  }, [setProductsLoading, setImgUrlBase, setMoreItems, setProducts, currentBrandIds, sections]);

  return { fetchAutovizDbWheelModels, fetchAutovizDbWheelFilters };
};

export default useFetchAutovizDbWheelModels;
