import { LayoutGrid, List } from 'lucide-react';
import React, { useState } from 'react';
import { useStore } from '../lib/store';
import ToggleButton from './toggle-button';

const ToggleButtonListGrid: React.FC = () =>
{
  const listDisplayMode = useStore((state) => state.listDisplayMode);
  const setListDisplayMode = useStore((state) => state.setListDisplayMode);
  const iconSize = 14

  const [pivotBoolean, setPivotBoolean] = useState(listDisplayMode === "LIST");
  
  const handleClick = () =>
  {
    setListDisplayMode(listDisplayMode === "GRID" ? "LIST" : "GRID");
    setPivotBoolean(!pivotBoolean);
  };

  return (
    <>
      <ToggleButton pivotBoolean={pivotBoolean} handleToggleFunction={handleClick} leftContent={<><List size={iconSize} strokeWidth={2} /><span className={`text-xs`} >List</span></>} rightContent={<><LayoutGrid size={iconSize} strokeWidth={1} fill="" /><span className="text-xs">Grid</span></>} />
    </>
  );
};

export default ToggleButtonListGrid;
