// product-catalog/src/lib/store.ts

import { create } from 'zustand';
import
  {
    AutovizDbWheelModel,
    Brand,
    DomainResult,
    FetchWheelStylesResponse,
    ListDisplayMode,
    WheelStyle,
    WheelStyleDetail,
  } from './types';
import { productCatalogPath } from './utils';

export interface CatalogState {
  //DISPLAY
  curPage: number;
  setCurPage: (curPage: number) => void;
  moreItems: boolean;
  setMoreItems: (moreItems: boolean) => void;
  listDisplayMode: ListDisplayMode;
  setListDisplayMode: (listDisplayMode: ListDisplayMode) => void;
  carouselResizeCount: number;
  setCarouselResizeCount: (count: number) => void;
  carouselInitialized: boolean;
  setCarouselInitialized: (initialized: boolean) => void;
  appInitialized: boolean;
  setAppInitialized: (initialized: boolean) => void;
  searchParams: Record<string, any>;
  setSearchParams: (searchParams: Record<string, any>) => void;
  fetchFromAutovizDb: boolean;
  setFetchFromAutovizDb: (fetchFromAutovizDb: boolean) => void;
  isThreeColumnView: boolean;
  setIsThreeColumnView: (isThreeColumnView: boolean) => void;
  modelViewerScriptsLoaded: boolean;
  setModelViewerScriptsLoaded: (modelViewerScriptsLoaded: boolean) => void;
  navigationContextRootPath: string;
  setNavigationContextRootPath: (navigationContextRootPath: string) => void;
  qrCodeOpen: boolean;
  setQrCodeOpen: (qrCodeOpen: boolean) => void;
  qrCodeTitle: string;
  setQrCodeTitle: (qrCodeTitle: string) => void;
  qrCodeSubTitle: string;
  setQrCodeSubTitle: (qrCodeSubTitle: string) => void;
  outletLoaderIsVisible: boolean;
  setOutletLoaderIsVisible: (outletLoaderIsVisible: boolean) => void;

  //BRANDS
  brandsLoading: boolean;
  setBrandsLoading: (loading: boolean) => void;
  brands: Record<string, Brand>;
  setBrands: (brands: Record<string, Brand>) => void;
  currentBrand: Brand | null;
  setCurrentBrand: (currentBrand: Brand | null) => void;
  brandLogosUrlBase: string;
  setBrandLogosUrlBase: (brandLogosUrlBase: string) => void;
  currentBrandIds: string | null;
  setCurrentBrandIds: (currentBrandIds: string | null) => void;

  //PRODUCTS
  products: WheelStyle[];
  styleId: string;
  setStyleId: (styleId: string) => void;
  wheelId: string;
  setWheelId: (wheelId: string) => void;
  setProducts: (
    products: WheelStyle[] | ((prevProducts: WheelStyle[]) => WheelStyle[]),
  ) => void;
  imgUrlBase: string;
  setImgUrlBase: (imgUrlBase: string) => void;
  productsLoading: boolean;
  setProductsLoading: (loading: boolean) => void;
  productsLoadingTrigger: string | null;
  setProductsLoadingTrigger: (trigger: string) => void;
  currentDomain: DomainResult | null;
  setCurrentDomain: (currentDomain: DomainResult | null) => void;
  inMemoryProducts: WheelStyle[];
  setInMemoryProducts: (inMemoryProducts: WheelStyle[]) => void;
  isLeftDrawerOpen: boolean;
  setIsLeftDrawerOpen: (isLeftDrawerOpen: boolean) => void;

  currentAutosyncApiWheelStyle: WheelStyleDetail | null;
  setCurrentAutosyncApiWheelStyle: (
    currentAutosyncApiWheelStyle: WheelStyleDetail | null,
  ) => void;

  currentAutosyncApiWheelStyles: WheelStyleDetail[] | [];
  setCurrentAutosyncApiWheelStyles: (
    currentAutosyncApiWheelStyles: WheelStyleDetail[],
  ) => void;

  currentAutovizDbWheelModel: AutovizDbWheelModel | null;
  setCurrentAutovizDbWheelModel: (currentAutovizDbWheelModel: any) => void;

  currentAutovizDbWheelInfo: FetchWheelStylesResponse | null;
  setCurrentAutovizDbWheelInfo: (
    currentAutovizDbWheelInfo: FetchWheelStylesResponse | null,
  ) => void;

  dataObject: any;
  setDataObject: (dataObject: any) => void;
  currentPartName: string;
  setCurrentPartName: (currentPartName: string) => void;
  fullyInitialized: boolean;
  setFullyInitialized: (fullyInitialized: boolean) => void;
  savedDesigns: any[];
  setSavedDesigns: (savedDesigns: any[]) => void;
  currentDesignName: string;
  setCurrentDesignName: (currentDesignName: string) => void;
  alertOpen: boolean;
  setAlertOpen: (alertOpen: boolean) => void;
  alertContext: string | null;
  setAlertContext: (alertContext: any) => void;
  alertCallback: (props: any) => void;
  setAlertCallback: (alertCallback: () => void) => void;
  alertProps: any;

  // Model Viewer
  modelViewerRefState: any;
  setModelViewerRefState: (modelViewerRefState: any) => void;
  modelViewerRefSelf: any;
  setModelViewerRefSelf: (modelViewerRefSelf: any) => void;
  arActivated: boolean;
  setArActivated: (arActivated: boolean) => void;
  arNeedsActivation: boolean;
  setArNeedsActivation: (arNeedsActivation: boolean) => void;
  show3DModel: boolean;
  setShow3DModel: (show3DModel: boolean) => void;
  arIsPresenting: boolean;
  setArIsPresenting: (arIsPresenting: boolean) => void;
  modelSrc: string;
  setModelSrc: (modelSrc: string) => void;
  loaderStateBump: number;
  setLoaderStateBump: (loaderStateBump: number) => void;
}

export const useStore = create<CatalogState>((set) => {
  return {
    // DISPLAY
    outletLoaderIsVisible: false,
    setOutletLoaderIsVisible: (outletLoaderIsVisible) =>
      set({ outletLoaderIsVisible }),
    // Model Viewer
    modelViewerRefState: null,
    setModelViewerRefState: (modelViewerRefState) =>
      set({ modelViewerRefState }),
    modelViewerRefSelf: null,
    setModelViewerRefSelf: (modelViewerRefSelf) => set({ modelViewerRefSelf }),
    arActivated: false,
    setArActivated: (arActivated) => set({ arActivated }),
    arNeedsActivation: false,
    setArNeedsActivation: (arNeedsActivation) => set({ arNeedsActivation }),
    show3DModel: false,
    setShow3DModel: (show3DModel) => set({ show3DModel }),
    arIsPresenting: false,
    setArIsPresenting: (arIsPresenting) => set({ arIsPresenting }),
    modelSrc: '',
    setModelSrc: (modelSrc) => set({ modelSrc }),
    loaderStateBump: 0,
    setLoaderStateBump: (loaderStateBump) => set({ loaderStateBump }),

    //PRODUCTS
    styleId: '',
    setStyleId: (styleId) => set({ styleId }),
    wheelId: '',
    setWheelId: (wheelId) => set({ wheelId }),

    qrCodeOpen: false,
    setQrCodeOpen: (qrCodeOpen) => set({ qrCodeOpen }),
    qrCodeTitle: '',
    setQrCodeTitle: (qrCodeTitle) => set({ qrCodeTitle, qrCodeSubTitle: '' }),
    qrCodeSubTitle: '',
    setQrCodeSubTitle: (qrCodeSubTitle) => set({ qrCodeSubTitle }),
    showAlert: ({ context, props, callback }: any) => {
      set({
        alertOpen: true,
        alertContext: context,
        alertProps: props,
        alertCallback: callback,
      });
    },
    setAlertOpen: (alertOpen) => set({ alertOpen }),
    alertProps: null,
    alertOpen: false,
    alertContext: null,
    alertCallback: (props: any) => null,
    setAlertCallback: (alertCallback) => set({ alertCallback }),
    setAlertContext: (alertContext) => set({ alertContext }),
    currentDesignName: '',
    setCurrentDesignName: (currentDesignName) => set({ currentDesignName }),
    savedDesigns: [],
    setSavedDesigns: (savedDesigns) => set({ savedDesigns }),
    fullyInitialized: false,
    setFullyInitialized: (fullyInitialized) => set({ fullyInitialized }),
    currentPartName: '',
    setCurrentPartName: (currentPartName) => set({ currentPartName }),
    dataObject: null,
    setDataObject: (dataObject: any) => set({ dataObject }),
    navigationContextRootPath: productCatalogPath,
    setNavigationContextRootPath: (navigationContextRootPath) =>
      set({ navigationContextRootPath }),

    modelViewerScriptsLoaded: false,
    setModelViewerScriptsLoaded: (modelViewerScriptsLoaded) =>
      set({ modelViewerScriptsLoaded }),

    currentAutosyncApiWheelStyle: null,
    setCurrentAutosyncApiWheelStyle: (currentAutosyncApiWheelStyle) =>
      set({ currentAutosyncApiWheelStyle }),

    currentAutosyncApiWheelStyles: [],
    setCurrentAutosyncApiWheelStyles: (currentAutosyncApiWheelStyles) =>
      set({ currentAutosyncApiWheelStyles }),

    currentAutovizDbWheelModel: null,
    setCurrentAutovizDbWheelModel: (currentAutovizDbWheelModel) =>
      set({ currentAutovizDbWheelModel }),

    currentAutovizDbWheelInfo: null,
    setCurrentAutovizDbWheelInfo: (currentAutovizDbWheelInfo) =>
      set({ currentAutovizDbWheelInfo }),

    isLeftDrawerOpen: false,
    setIsLeftDrawerOpen: (isLeftDrawerOpen) => set({ isLeftDrawerOpen }),
    isThreeColumnView: false,
    setIsThreeColumnView: (isThreeColumnView) => set({ isThreeColumnView }),
    brands: {},
    setBrands: (brands) => set({ brands }),
    currentBrand: null,
    setCurrentBrand: (currentBrand) => set({ currentBrand }),
    brandLogosUrlBase: '',
    setBrandLogosUrlBase: (brandLogosUrlBase) => set({ brandLogosUrlBase }),
    imgUrlBase: '',
    setImgUrlBase: (imgUrlBase) => set({ imgUrlBase }),
    curPage: 1,
    setCurPage: (curPage) => set({ curPage }),
    moreItems: true,
    setMoreItems: (moreItems) => {
      set({ moreItems });
    },
    products: [],
    setProducts: (
      products: WheelStyle[] | ((prevProducts: WheelStyle[]) => WheelStyle[]),
    ) =>
      set((state) => ({
        products:
          typeof products === 'function' ? products(state.products) : products,
      })),
    listDisplayMode: 'GRID',
    setListDisplayMode: (listDisplayMode) => set({ listDisplayMode }),
    carouselResizeCount: 0,
    setCarouselResizeCount: (count) => set({ carouselResizeCount: count }),
    carouselInitialized: false,
    setCarouselInitialized: (initialized) =>
      set({ carouselInitialized: initialized }),
    appInitialized: false,
    setAppInitialized: (initialized) => set({ appInitialized: initialized }),
    brandsLoading: false,
    setBrandsLoading: (loading) => set({ brandsLoading: loading }),
    productsLoading: false,
    setProductsLoading: (loading) => set({ productsLoading: loading }),
    productsLoadingTrigger: null,
    setProductsLoadingTrigger: (trigger) =>
      set({ productsLoadingTrigger: trigger }),
    currentDomain: null,
    setCurrentDomain: (currentDomain) => set({ currentDomain }),
    currentBrandIds: null,
    setCurrentBrandIds: (currentBrandIds) => set({ currentBrandIds }),
    searchParams: {},
    setSearchParams: (searchParams) => set({ searchParams }),
    fetchFromAutovizDb: true,
    setFetchFromAutovizDb: (fetchFromAutovizDb) => set({ fetchFromAutovizDb }),
    inMemoryProducts: [],
    setInMemoryProducts: (inMemoryProducts) => set({ inMemoryProducts }),
  };
});

interface FilterMenuState {
  filteredProducts: WheelStyle[];
  setFilteredProducts: (filteredProducts: WheelStyle[]) => void;
  filterData: any;
  setFilterData: (filterData: any) => any;
  openSection: string | null;
  setOpenSection: (section: string | null) => void;
  filterMenuOpen: boolean;
  setFilterMenuOpen: (filterMenuOpen: boolean) => void;
  sections: {
    [key: string]: any;
  };
  setSections: (sections: any) => void;
  setFilterItemState: ({
    section,
    displayName,
    value,
  }: {
    section: string;
    displayName: string;
    value: any;
  }) => void;
  removeFilterItemState: ({ section }: { section: string }) => void;
  resetSections: () => void;
  filtersCount: number;
  resetFilterData: () => void;
  setFiltersCount: (filtersCount: number) => void;
}

export const useFilterMenuStore = create<FilterMenuState>((set) => ({
  filteredProducts: [],
  setFilteredProducts: (filteredProducts) => set({ filteredProducts }),

  filterData: {},
  setFilterData: (filterData) => set({ filterData }),
  resetFilterData: () => set({ filterData: {} }),
  filtersCount: 0,
  setFiltersCount: (filtersCount: number) => set({ filtersCount }),

  openSection: null,
  setOpenSection: (section) => set({ openSection: section }),

  filterMenuOpen: false,
  setFilterMenuOpen: (filterMenuOpen) => set({ filterMenuOpen }),

  sections: {},

  setSections: (sections) => set({ sections }),
  setFilterItemState: ({ section, displayName, value }) =>
    set((state) => ({
      sections: {
        ...state.sections,
        [section]: {
          ...state.sections[section],
          displayName,
          value,
        },
      },
    })),

  removeFilterItemState: ({ section }) =>
    set((state) => {
      const newSections = { ...state.sections };
      delete newSections[section];
      return { sections: newSections };
    }),

  resetSections: () =>
    set(() => ({
      sections: {},
      openSection: null,
    })),
}));

interface CompanyState {
  companyDisplayName: string;
  setCompanyDisplayName: (companyDisplayName: string) => void;
  domainName: string;
  setDomainName: (domainName: string) => void;
  displayName: string;
  setDisplayName: (displayName: string) => void;
  logoUrl: string;
  setLogoUrl: (logoUrl: string) => void;
  gaKey: string;
  setGaKey: (gaKey: string) => void;
  primaryColor: string;
  setPrimaryColor: (primaryColor: string) => void;
}
export const useCompanyStore = create<CompanyState>((set) => ({
  companyDisplayName: '',
  setCompanyDisplayName: (companyDisplayName) => set({ companyDisplayName }),
  domainName: '',
  setDomainName: (domainName) => set({ domainName }),
  displayName: '',
  setDisplayName: (displayName) => set({ displayName }),
  logoUrl: '',
  setLogoUrl: (logoUrl) => set({ logoUrl }),
  gaKey: '',
  setGaKey: (gaKey) => set({ gaKey }),
  primaryColor: '',
  setPrimaryColor: (primaryColor) => set({ primaryColor }),
}));

interface SearchState {
  searchLoading: boolean;
  setSearchLoading: (loading: boolean) => void;
  searchResults: WheelStyle[];
  setSearchResults: (results: WheelStyle[]) => void;
}
export const useSearchStore = create<SearchState>((set) => ({
  searchLoading: false,
  setSearchLoading: (loading) => set({ searchLoading: loading }),
  searchResults: [],
  setSearchResults: (results) => set({ searchResults: results }),
}));

interface ThemeState {
  isDark: boolean;
  toggleTheme: () => void;
}

const getStoredThemePreference = (): boolean | null => {
  const storedTheme = localStorage.getItem('theme');
  try {
    return storedTheme ? JSON.parse(storedTheme) : null;
  } catch (error) {
    return null;
  }
};

const getSystemDefaultTheme = (): boolean => {
  if (
    window.matchMedia &&
    window.matchMedia('(prefers-color-scheme: dark)').matches
  ) {
    return true;
  }
  return false;
};

const getInitialTheme = (): boolean => {
  const storedPreference = getStoredThemePreference();
  return storedPreference !== null ? storedPreference : getSystemDefaultTheme();
};

export const useThemeStore = create<ThemeState>((set) => ({
  isDark: getInitialTheme(),
  toggleTheme: () =>
    set((state) => {
      const newTheme = !state.isDark;
      localStorage.setItem('theme', JSON.stringify(newTheme));
      return { isDark: newTheme };
    }),
}));

interface IVehicleSearch {
  // Stepper map [1 - 5]
  step: number;
  // functions to handle steppers ends
  setStep: (value: number) => void;
  incrementStep: () => void;
  decrementStep: () => void;
  // functions to handle steppers ends

  // handle vehicle Make
  vehicleMake: string | null;
  SetVehicleMake: (value: string) => void;

  // handle Vehicle Year
  vehicleYear: string | number | null;
  SetVehicleYear: (value: string | number | null) => void;

  // handle Vehicle type
  vehicleType: string | null;
  SetVehicleType: (value: string) => void;

  // handle Vehicle model
  vehicleModel: string | null;
  SetVehicleModel: (value: string) => void;

  // handle Vehicle Trim
  vehicleTrim: {
    id: string | number;
    colorId: string | number;
  } | null;
  SetVehicleTrim: (value: {
    id: string | number;
    colorId: string | number;
  }) => void;

  // handle Vehicle Trim
  vehicleSize: string | number | null;
  SetVehicleSize: (value: string | number) => void;
}

// MAXSTEP

const MAX_STEP = 5;

export const useVehicleSearch = create<IVehicleSearch>((set) => ({
  step: 0,
  // functions to handle steppers ends
  decrementStep: () =>
    set((state) => {
      let decrementedStep = state.step - 1;
      return { step: decrementedStep <= 0 ? 0 : decrementedStep };
    }),
  incrementStep: () =>
    set((state) => {
      let IncrementedStep = state.step + 1;
      return { step: IncrementedStep >= MAX_STEP ? MAX_STEP : IncrementedStep };
    }),
  setStep: (value) =>
    set((state) => {
      return { step: value };
    }),
  // functions to handle steppers ends
  vehicleMake: null,
  SetVehicleMake: (value) =>
    set((state) => {
      return { vehicleMake: value, step: state.step + 1 };
    }),

  vehicleYear: null,
  SetVehicleYear: (value) =>
    set((state) => {
      return { vehicleYear: value, step: state.step + 1 };
    }),

  vehicleModel: null,
  SetVehicleModel: (value) =>
    set((state) => {
      return { vehicleModel: value, step: state.step + 1 };
    }),
  vehicleTrim: null,
  SetVehicleTrim: (value) =>
    set((state) => {
      return { vehicleTrim: value, step: state.step + 1 };
    }),

  vehicleType: null,
  SetVehicleType: (value) =>
    set((state) => {
      return { vehicleType: value, step: state.step + 1 };
    }),

  vehicleSize: null,
  SetVehicleSize: (value) =>
    set((state) => {
      return { vehicleSize: value };
    }),
}));
