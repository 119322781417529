import React from 'react';
import { Button } from './ui/button';
import { useVehicleSearch } from '@/lib/store';
import { useFetchVehicleYearByModel } from '@/hooks/useFetchVehicle';
import { Loader2 } from 'lucide-react';

const SelectSearchVehicleYear = () => {
  const currentYear = new Date().getFullYear() + 1;
  const yearArray = Array.from(
    { length: currentYear - 1990 },
    (_, i) => 1991 + i,
  ).reverse();

  const { SetVehicleYear, vehicleMake, setStep } = useVehicleSearch(
    (state) => state,
  );
  if (!vehicleMake) {
    return <div className="mt-4">Please Select Vehicle Make to continue</div>;
  }
  const { vehicleYears, isLoading } = useFetchVehicleYearByModel({
    make: vehicleMake,
  });
  return (
    <div className="flex gap-y-2 gap-x-2 flex-wrap">
      {vehicleYears?.length ? (
        vehicleYears.map((item) => {
          return (
            <Button
              className="bg-muted px-[12px] py-[12px]"
              variant={'ghost'}
              size={'chip'}
              onClick={() => {
                SetVehicleYear(item);
              }}
            >
              <p className="text-base font-normal">{item}</p>
            </Button>
          );
        })
      ) : !isLoading ? (
        <>Failed to get Years of this Vehicle</>
      ) : (
        <div className="flex items-center justify-center gap-1">
          <div>
            <Loader2 className="animate-spin w-4 h-4 text-primary" />
          </div>
          <p>Loading vehicle years...</p>
        </div>
      )}
    </div>
  );
};

export default SelectSearchVehicleYear;
