import React, { useEffect, useRef, useState } from 'react';
import { animated, useTransition } from 'react-spring';

const Fader: React.FC<{ isVisible: boolean; duration: number; children: React.ReactNode; zIndex?: number, rest?:number, wrapperClassName?: string }> = ({ isVisible, duration, children, zIndex, rest, wrapperClassName }) =>
{
  const [show, setShow] = useState(isVisible);
  const [itemState, setItemState] = useState(false);

  const transitions = useTransition(show, {
    from: { opacity: 0, zIndex: zIndex },
    enter: { opacity: 1, zIndex: zIndex },
    leave: { opacity: 0, zIndex: zIndex },
    config: { duration: duration / 2 },
    onRest: (_: any, __: any, item: boolean) =>
    {
      setItemState(item)
      // console.log(`item`,item)
      if (item && !isVisible)
      {
        setTimeout(() => setShow(false), rest || 100);
      }
    },
  });

  useEffect(() =>
  {
    // console.log(`fader ue`, { isVisible, itemState })
    if (isVisible)
    {
      setShow(true);
      return
    }
    
    if (itemState)
    {
      setTimeout(() => setShow(false), rest || 100);
      setItemState(false)
    }
    
    
  }, [isVisible, itemState]);

  return (
    <>
      {transitions(
        (styles, item) => item && <animated.div style={{ ...styles, zIndex: zIndex }} className={`transition-opacity relative `}>{children}</animated.div>
      )}
    </>
  );
};

export default Fader;
