// src/components/filter-menu.tsx

import useFilters from '@/hooks/useFilters';
import useResolveAndFetchOnFilterChange from '@/hooks/useResolveAndFetchOnFilterChange';
import {
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  Loader2,
  X,
} from 'lucide-react';
import React, { useEffect, useRef, useState } from 'react';
import { useFilterMenuStore, useStore } from '../lib/store';
import { Button } from './ui/button';
import { cn } from '@/lib/utils';
const DEFAULT_ALL = "All"
const FilterMenu: React.FC = () => {
  const { resetSections } = useFilterMenuStore((state) => ({
    resetSections: state.resetSections,
  }));
  const { setFilterMenuOpen } = useFilterMenuStore((state) => ({
    setFilterMenuOpen: state.setFilterMenuOpen,
  }));
  const { sections } = useFilterMenuStore((state) => ({
    sections: state.sections,
  }));
  const { setFiltersCount } = useFilterMenuStore((state) => ({
    setFiltersCount: state.setFiltersCount,
  }));
  const { filterData } = useFilterMenuStore((state) => ({
    filterData: state.filterData,
  }));
  const { setProducts, product, productLoading } = useStore((state) => ({
    setProducts: state.setProducts,
    product: state.products.length,
    productLoading: state.productsLoading,
  }));

  const { resolveAndFetchOnFilterChange } = useResolveAndFetchOnFilterChange();

  const handleResetClick = async () => {
    resetSections();
    const products_results = await resolveAndFetchOnFilterChange({
      filters: {},
    });
    setProducts(products_results);
    if (window.resetFilterState) window.resetFilterState();
    if (window.getWheelStyles) window.getWheelStyles();
  };
  useEffect(() => {
    let keys = Object.keys(sections);
    let filtered = keys.filter((key) => sections[key].displayName !== DEFAULT_ALL);
    setFiltersCount(filtered.length);
  }, [sections]);

  const filterMenuSectionsContainerRef = useRef<HTMLDivElement>(null);
  // const filterMenuRef = useRef<HTMLDivElement>(null);
  const { openSection } = useFilterMenuStore((state) => ({
    openSection: state.openSection,
  }));
  // useEffect(() => {
  //   if (filterMenuRef.current) {
  //     const remainingHeight =
  //       document.body.offsetHeight -
  //       filterMenuRef?.current?.getBoundingClientRect().top -
  //       250; //each section can expand to 200px
  //     filterMenuRef.current.style.maxHeight = `${remainingHeight}px`;
  //   }
  // }, [openSection]);
  const isThreeColumnView = useStore((state) => state.isThreeColumnView);
  const filtersCount = useFilterMenuStore((state) => state.filtersCount);

  return (
    <>
      <div
        // @CHECK Should this be here
        // ref={filterMenuRef}
        className={`select-none pb-12 h-full flex flex-col w-full  z-[999999999] relative overflow-y-scroll scrollbar-w-2 scrollbar-thumb-rounded scrollbar-track-primary scrollbar-thumb-muted`}
      >
        <button
          onClick={() => {
            setFilterMenuOpen(false);
          }}
          className={cn('flex gap-1 items-center  px-4 py-4', {
            'justify-end w-full': isThreeColumnView,
          })}
        >
          <ChevronLeft
            className={cn({
              'order-2 rotate-180': isThreeColumnView,
            })}
            size={22}
          />
          <p
            className={cn({
              'order-1': isThreeColumnView,
            })}
          >
            Back to Search Results
          </p>
        </button>
        {/* <div className="grid grid-cols-[20%,auto,20%] flex flex-col justify-between items-center mt-1 px-1 mb-2 border-b-[.5px] pb-4">
          <Button
            variant={'muted'}
            size={'xs'}
            className={'p-1 px-0 w-12'}
            onClick={() => handleResetClick()}
          >
            Reset
          </Button>
          <div className="text-center mt-1 text-lg">Filters</div>
          <Button
            variant={'muted'}
            size={'xs'}
            className={'p-1 w-6 place-self-end'}
            onClick={() => {
              setFilterMenuOpen(false);
            }}
          >
            <X size={16} className={`text-muted-foreground`} />
          </Button>
        </div> */}
        <div>
          <div className="mb-2">
            <div className="flex items-center justify-between mx-4 mt-6 mb-4">
              <div className="flex items-center">
                <h3 className="text-2xl font-light ">Filters</h3>
                <span className="text-sm bg-primary text-primary-foreground px-1.5 py-0.5 rounded-md mx-2">
                  {filtersCount}
                </span>
              </div>
            </div>
            <div className="flex items-center justify-center w-full px-4 ">
              <hr className="w-full my-2" />
            </div>
          </div>
          <div ref={filterMenuSectionsContainerRef} className=" pb-2">
            {filterData.Brands && (
              <Section items={filterData.Brands} title="Brand" />
            )}
            {filterData.Diameters && (
              <Section items={filterData.Diameters} title="Diameter" />
            )}
            {filterData.Widths && (
              <Section items={filterData.Widths} title="Width" />
            )}
            {filterData.ShortColors && (
              <Section items={filterData.ShortColors} title="Color" />
            )}
            {filterData.ShortFinishes && (
              <Section items={filterData.ShortFinishes} title="Finish" />
            )}
            {filterData.Offsets && (
              <Section items={filterData.Offsets} title="Offset" />
            )}
            {filterData.VehicleTypeTags && (
              <Section items={filterData.VehicleTypeTags} title="Type" />
            )}
          </div>
        </div>
        <div className="flex w-full h-full  mt-12  sm:justify-between items-center mx-auto max-w-full flex-col sm:flex-row sm:max-w-[90%]">
          <Button
            variant={'link'}
            className={'text-secondary-foreground underline text-base'}
            onClick={() => handleResetClick()}
          >
            Clear All
          </Button>
          <Button
            variant={'revertedButton'}
            className="md:py-6 py-5  rounded-xl w-full max-w-[90%] sm:max-w-[230px] "
            size={'lg'}
            onClick={() => {
              setFilterMenuOpen(false);
            }}
          >
            {productLoading ? (
              <div className="flex items-center w-full justify-center gap-2 ">
                <Loader2 size={18} className="animate-spin" />
                <span>Loading...</span>
              </div>
            ) : (
              <div className="">
                <p>Show {product} results</p>
              </div>
            )}
          </Button>
        </div>
      </div>
    </>
  );
};

export default FilterMenu;

const Section: React.FC<{ title: string; items: any[] }> = ({
  title,
  items,
}) => {
  const { openSection } = useFilterMenuStore((state) => ({
    openSection: state.openSection,
  }));
  const { setOpenSection } = useFilterMenuStore((state) => ({
    setOpenSection: state.setOpenSection,
  }));

  const toggle = () => setOpenSection(openSection === title ? null : title);

  const isOpen = openSection === title;

  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (contentRef.current)
      contentRef.current.style.maxHeight = isOpen ? `200px` : '0px';
  }, [isOpen]);

  return (
    <>
      {items.length > 1 && (
        <>
          <SectionHeader title={title} toggle={toggle} />
          <div
            ref={contentRef}
            className="transition-all scrollbar-thumb scrollbar-thumb-rounded scrollbar-track-primary scrollbar-w-2 scrolling-touch overflow-y-scroll "
            style={{
              height: isOpen ? `${contentRef.current?.scrollHeight}px` : '0px',
            }}
            dir="rtl"
          >
            <div dir="ltr" className="mx-1 ">
              <SectionItemAll title={title} />

              {items.map((item, i) => (
                <SectionItem title={title} item={item} key={i} />
              ))}
              <div className={`h-1`}></div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

const SectionItemAll: React.FC<{ title: string }> = ({ title }) => {
  const { sections, removeFilterItemState } = useFilterMenuStore();
  const { setProducts } = useStore((state) => ({
    setProducts: state.setProducts,
  }));
  const { resolveAndFetchOnFilterChange } = useResolveAndFetchOnFilterChange();

  return (
    <div
      onClick={async () => {
        const newSections = { ...sections };
        delete newSections[title];
        removeFilterItemState({ section: title });

        const products_results = await resolveAndFetchOnFilterChange({
          filters: newSections,
        });

        setProducts(products_results);
      }}
      className={cn(
        'filter_item  filter_item_c hover:bg-muted rounded-md transition duration-300 cursor-pointer',
        {
          sel: sections?.[title]?.value === undefined,
        },
      )}
    >
      <div>
        <span></span>
      </div>
      {DEFAULT_ALL}
    </div>
  );
};

const SectionHeader: React.FC<{ title: string; toggle: Function }> = ({
  title,
  toggle,
}) => {
  const { sections, openSection, removeFilterItemState } = useFilterMenuStore();
  const [display, setDisplay] = useState(DEFAULT_ALL);
  const { resolveFiltersDisplayObejct } = useFilters();
  const { setProducts } = useStore((state) => ({
    setProducts: state.setProducts,
  }));
  useEffect(() => {
    if (sections[title]) {
      const { displayName, value } = resolveFiltersDisplayObejct({ title })(
        sections[title],
      );
      setDisplay(displayName);
    } else {
      setDisplay(DEFAULT_ALL);
    }
  }, [sections]);
  const { resolveAndFetchOnFilterChange } = useResolveAndFetchOnFilterChange();

  return (
    <div className="relative flex gap-2 justify-between items-center transition duration-300 rounded-xl   hover:bg-muted  p-2  cursor-pointer ">
      <span className={`text-base pl-2`}>{title}</span>
      <button
        onClick={() => toggle()}
        className=" w-full h-full absolute left-0 top-0"
      />
      <div className="flex items-center justify-center">
        <Button
          size={'chipSm'}
          onClick={async () => {
            if (display === DEFAULT_ALL) {
              toggle();
              return;
            }
            const newSections = { ...sections };
            delete newSections[title];
            removeFilterItemState({ section: title });
            const products_results = await resolveAndFetchOnFilterChange({
              filters: newSections,
            });
            setProducts(products_results);
          }}
          variant={display === DEFAULT_ALL ? 'muted' : 'default'}
          className={cn(
            'font-bold items-center justify-center gap-1 relative z-10',
            {
              'text-secondary-foreground font-medium': display === DEFAULT_ALL,
            },
          )}
          
        >
          
          {display}
          <X
            size={14}
            className={cn({
              hidden: display === DEFAULT_ALL,
            })}
          />
        </Button>
        {openSection !== title ? (
          <ChevronDown strokeWidth={1.5} className="text-muted-foreground ml-2" />
        ) : (
          <ChevronRight strokeWidth={1.5} className="text-muted-foreground ml-2" />
        )}
      </div>
    </div>
  );
};

const SectionItem: React.FC<{ title: string; item: any }> = ({
  title,
  item,
}) => {
  const { sections, setFilterItemState } = useFilterMenuStore();
  const { resolveFiltersDisplayObejct } = useFilters();
  const { displayName, value } = resolveFiltersDisplayObejct({ title })(item);
  const { setProducts } = useStore((state) => ({
    setProducts: state.setProducts,
  }));
  const { resolveAndFetchOnFilterChange } = useResolveAndFetchOnFilterChange();

  const handleClick = async (
    title: string,
    value: string,
    displayName: string,
  ) => {
    const newSections = {
      ...sections,
      [title]: {
        displayName,
        value,
      },
    };
    setFilterItemState({ section: title, displayName, value });

    const products_results = await resolveAndFetchOnFilterChange({
      filters: newSections,
    });
    // debugger
    // console.log(`products_results`, products_results)
    setProducts(products_results);

    if (window.setFilterState)
      window.setFilterState({ key: title.toLowerCase(), value });
    if (window.getWheelStyles) window.getWheelStyles();
  };

  return (
    <div
      onClick={() => handleClick(title, value, displayName)}
      className={`filter_item hover:bg-muted rounded-md transition duration-300 cursor-pointer ${sections?.[title]?.value === value ? 'sel' : ''}`}
    >
      <div>
        <span></span>
      </div>
      {displayName}
    </div>
  );
};
