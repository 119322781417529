// autoviz-component-monorepo/packages/product-catalog/src/components/scroll-area-dynamic.tsx

import { ScrollArea } from "@/components/ui/scroll-area";
import { useStore } from "../lib/store";
import React, { useCallback, useEffect, useRef, useState, forwardRef } from 'react';
import { debounce } from 'lodash';

const ScrollAreaDynamic = forwardRef<HTMLDivElement, React.ComponentPropsWithoutRef<any>>((props, ref) =>
{
  const { children, ...rest } = props;
  const innerRef = useRef<HTMLDivElement | null>(null);

  const footerAreaPad = 0//60;
  const [availableHeight, setAvailableHeight] = useState(window.innerHeight);

  const listDisplayMode = useStore((state) => state.listDisplayMode);
  const carouselResizeCount = useStore((state) => state.carouselResizeCount);
  const carouselInitialized = useStore((state) => state.carouselInitialized);

  const updateHeight = () =>
  {
    if (innerRef.current)
    {
      const offsetTop = innerRef.current.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;
      let availableHeight = windowHeight - offsetTop;
      // if (availableHeight < 600) availableHeight = 600;
      setAvailableHeight(availableHeight);
    }
  };

  const debouncedUpdateHeight = useCallback(debounce(updateHeight, 1000), [updateHeight]);

  useEffect(() =>
  {
    debouncedUpdateHeight();
    window.addEventListener('resize', debouncedUpdateHeight);

    return () =>
    {
      window.removeEventListener('resize', debouncedUpdateHeight);
      debouncedUpdateHeight.cancel(); 
    };
  }, [debouncedUpdateHeight, listDisplayMode, carouselResizeCount, carouselInitialized]);

  return (
    <ScrollArea ref={(node) =>
    {
      innerRef.current = node;
      if (typeof ref === 'function')
      {
        ref(node);
      } else if (ref)
      {
        (ref as React.MutableRefObject<HTMLDivElement | null>).current = node;
      }
    }} {...rest} style={{ height: `${availableHeight - footerAreaPad}px` }}>
      {children}
    </ScrollArea>
  );
});

export default ScrollAreaDynamic;
