import React from 'react';
import { Button } from './ui/button';
import { useVehicleSearch } from '@/lib/store';
import {
  fetchVehicleTypeByYearAndMake,
  useFetchVehicleTypeByYearAndMake,
} from '@/hooks/useFetchVehicle';
import { useQuery } from '@tanstack/react-query';
import { Loader2 } from 'lucide-react';

// const vehicleType = [
//   {
//     title: 'SUV',
//   },
//   {
//     title: 'CUV',
//   },
//   {
//     title: 'Van',
//   },
//   {
//     title: 'Truck',
//   },
//   {
//     title: 'Car',
//   },
// ];
export default function SelectSearchVehicleType() {
  const { SetVehicleType, vehicleMake, vehicleYear } = useVehicleSearch(
    (state) => state,
  );
  const { vehicleType, isLoading } = useFetchVehicleTypeByYearAndMake({
    make: vehicleMake!,
    year: vehicleYear!,
  });

  return (
    <div>
    <div className="gap-2  w-full flex">
        {vehicleType.length > 0 ? (
          vehicleType.map((item) => {
            return (
              <Button
                className="bg-muted px-[12px] py-[8px]"
                variant={'ghost'}
                size={'chip'}
                onClick={() => {
                  SetVehicleType(item);
                }}
              >
                <p className="text-base font-normal">{item}</p>
              </Button>
            );
          })
        ) : !isLoading ? (
          <>No results Found</>
        ) : null}
      </div>
      <div>
        {isLoading ? (
          <div className="flex items-center justify-center gap-1">
            <div>
              <Loader2 className="animate-spin w-4 h-4 text-primary" />
            </div>
            <p>Loading type...</p>
          </div>
        ) : null}
      </div>
    </div>
  );
}
