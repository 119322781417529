// src/hooks/useFetchWheelFilters.ts
import { useCallback, useRef } from 'react';
import { useFilterMenuStore } from '../lib/store';
import { apiEndpoint } from '../lib/utils';

const useFetchWheelFilters = () =>
{
  const setFilterData = useFilterMenuStore((state) => state.setFilterData);

  const fetchWheelFilters = useCallback(async ({ brandIds, filters }: { brandIds?: string, filters?: string[] }={}): Promise<void> =>
  {
    const formData = new FormData();
    formData.append('data', JSON.stringify({ BrandIds: brandIds, filters }));

    const response = await fetch(`${apiEndpoint}/_get_wheel_filters.php`, {
      method: 'POST',
      body: formData,
    });

    const res = await response.json();
    setFilterData(res?.data?.Filters);
  }, [setFilterData]);

  return { fetchWheelFilters };
};

export default useFetchWheelFilters;
