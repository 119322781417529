// autoviz-component-monorepo/packages/product-catalog/src/components/wheel-configurator/dropdown-menu-wheel-designs.tsx

import { useStore } from '@/lib/store';
import { ResetIcon } from '@radix-ui/react-icons';
import { PencilIcon, TrashIcon } from 'lucide-react';
import React from 'react';
import QuoteRequest from '../quote-request';
import { Button } from '../ui/button';
import DropdownMenuWrapper from './dropdown-menu-wrapper';
import ToggleCompareToOriginal from './toggle-compare-to-original';
import WheelMedia from '../wheel-media';
import { to } from "react-spring";
import { toast } from "../ui/use-toast";
import { useNavigate } from 'react-router-dom';
import { useModelViewer } from '@/hooks/useModelViewer';
import { imgSrcFromWheelModel } from '@/lib/utils';

const DropdownMenuWheelDesigns = () => {
  // const currentAutosyncApiWheelStyle = useStore((state) => state.currentAutosyncApiWheelStyle);
  // const currentAutosyncApiWheelStyles = useStore((state) => state.currentAutosyncApiWheelStyles);
  // const currentAutovizDbWheelInfo = useStore((state) => state.currentAutovizDbWheelInfo);
  const currentAutovizDbWheelModel = useStore(
    (state) => state.currentAutovizDbWheelModel,
  );

  // console.log(`currentAutovizDbWheelInfo`, currentAutovizDbWheelInfo);
  // console.log(`currentAutovizDbWheelModel`, currentAutovizDbWheelModel);
  // console.log(`currentAutosyncApiWheelStyle`, currentAutosyncApiWheelStyle);
  // console.log(`currentAutosyncApiWheelStyles`, currentAutosyncApiWheelStyles);

  const { applyUrlDataToModel, savedDesigns, deleteSavedDesignByName } =
    useModelViewer();

  const showAlert = useStore((state: any) => state.showAlert);

  const navigate = useNavigate();
  return (
    <DropdownMenuWrapper
      title="My designs"
      className={`p-4 min-w-[360px] max-h-[calc(100vh-280px)] overflow-y-scroll`}
    >
      {/* <div
        className={`grid grid-cols-2 flex-col items-center justify-between gap-2 `}
      >
        <div className={`place-content-end  flex`}>
          <Button
            variant={'ghost'}
            size={'xs'}
            className={` bg-[#e6e6e6] rounded-md w-auto w-[70px]`}
            onClick={() =>
              toast({ title: 'Reset not implemented', variant: 'destructive' })
            }
          >
            <div
              className={`grid grid-cols-[auto,1fr] gap-1 flex flex-col items-center justify-center  dark:text-black`}
            >
              <ResetIcon strokeWidth={1.1} className={`h-3 w-3`} />
              <div className={`overflow-hidden text-xs`}>Reset</div>
            </div>
          </Button>
        </div>
      </div> */}

      <div className={` `}>
        <div
          className={`grid grid-cols-[40%,1fr] my-4 relative  items-start justify-start flex flex-col`}
        >
          <div
            className={`bg-primary text-primary-foreground rounded-lg font-bold absolute z-[99] p-1.5 text-xs`}
          >
            Now editing
          </div>
          <div
            className={`flex flex-col justify-center items-center w-full h-full relative`}
          >
            {currentAutovizDbWheelModel && (
              <WheelMedia
                currentAutovizDbWheelModel={currentAutovizDbWheelModel}
                allow3d={false}
              />
            )}
          </div>
          <div className={`pt-2`}>
            <h2 className="text-xl  leading-tight">
              {currentAutovizDbWheelModel?.model}
            </h2>

            <div
              className={`grid-cols-[auto,auto,auto] grid flex flex-row items-center justify-start gap-4 mt-10`}
            >
              <QuoteRequest classNameButton={`text-xs`} />
              {/* <div className={``}>
                <PencilIcon strokeWidth={1.5} className={`h-5 w-5`} />
              </div> */}
              {/* <div className={``}>
                <TrashIcon strokeWidth={1.5} className={`h-5 w-5`} />
              </div> */}
            </div>
          </div>
        </div>

        {savedDesigns.length > 0 && (
          <div className={`w-full`}>Recent designs</div>
        )}
        {[...savedDesigns].reverse().map((design: any, index: number) => (
          <div
            key={index}
            className={`grid grid-cols-[40%,1fr] my-8 pr-4 overflow-hidden`}
          >
            <div className={``}>
              <WheelMedia src={design.img} allow3d={false} />
            </div>
            <div className={`pt-2`}>
              <h2 className="text-xl  leading-tight  break-words w-full">
                {design?.displayName}
                {/* {currentAutovizDbWheelModel?.model} */}
              </h2>

              <div
                className={`grid-cols-[auto,auto,auto] grid flex flex-row items-center justify-start gap-4 mt-10`}
              >
                <QuoteRequest classNameButton={`text-xs`} />
                <Button
                  variant={'ghost'}
                  size={'xs'}
                  className={`p-0 m-0`}
                  onClick={() => {
                    // navigate(design?.config);
                    window.location.href = design?.config;
                    // applyUrlDataToModel(design?.config);
                  }}
                >
                  <PencilIcon strokeWidth={1.5} className={`h-5 w-5`} />
                </Button>
                <Button
                  variant={'ghost'}
                  size={'xs'}
                  className={`p-0 m-0`}
                  onClick={() => {
                    showAlert({
                      context: 'deleteDesign',
                      props: { name: design?.displayName },
                      callback: deleteSavedDesignByName,
                    });
                  }}
                >
                  <TrashIcon strokeWidth={1.5} className={`h-5 w-5`} />
                </Button>
              </div>
            </div>
          </div>
        ))}

        {/* <div className={`underline font-bold`}>Show more</div> */}
      </div>
    </DropdownMenuWrapper>
  );
};


export default React.memo(DropdownMenuWheelDesigns);


