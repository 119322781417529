// src/hooks/useShare.ts
import { useToast } from '@/components/ui/use-toast';
import { useLocation } from 'react-router-dom';

const useShare = () =>
{

  const { toast } = useToast();
  const location = useLocation();

  let domain = window.location.origin;
  
  const copyShareUrlToClipboard = () =>
  {
    let share_url = `${domain}${location.pathname}${location.search}`;
    console.log(`Share URL: ${share_url}`);
    navigator.clipboard.writeText(share_url);

    toast({
      description: "URL Copied to clipboard!",
      variant: "default"
    })


  };


  return { copyShareUrlToClipboard };
};

export default useShare;
