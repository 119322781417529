// src/hooks/useFetchWheelStyles.ts
import { useCallback } from 'react';
import { useFilterMenuStore, useStore } from '../lib/store';
import { apiEndpoint } from '../lib/utils';
import { WheelStyle } from '../lib/types';

const useFetchWheelStyles = () =>
{
  const setProductsLoading = useStore((state) => state.setProductsLoading);
  const setImgUrlBase = useStore((state) => state.setImgUrlBase);
  const setMoreItems = useStore((state) => state.setMoreItems);
  const setProducts = useStore((state) => state.setProducts);
  const currentBrandIds = useStore((state) => state.currentBrandIds);
  const sections = useFilterMenuStore((state) => state.sections);
  const setProductsLoadingTrigger = useStore((state) => state.setProductsLoadingTrigger);
  const setCurPage = useStore((state) => state.setCurPage);
  
  const fetchWheelStyles = useCallback(async ({ curPage = 1, brandIds, filters, trigger = "" }: { curPage?: number, brandIds?: string, filters?: any, trigger?: string } = {}): Promise<WheelStyle[] | []> =>
  {
    setProductsLoading(true);
    setProductsLoadingTrigger(trigger);
    if (trigger !== "scroll") setProducts([])

    setCurPage(curPage);
    
    const formData = new FormData();
    const resolvedBrandIds = currentBrandIds || brandIds;
    
    filters = filters || sections
    formData.append('data', JSON.stringify({ "brandIds": resolvedBrandIds, "filters": filters, "psize": 24, "pnum": curPage, "sid": "", "searchParams": "" }));

    const response = await fetch(`${apiEndpoint}/_get_wheel_styles.php`, {
      method: 'POST',
      body: formData,
    });

    const res = await response.json();
    
    const imgUrlBase = res?.data?.ImgUrlBase;
    setImgUrlBase(imgUrlBase);

    const moreItems = res?.data?.MoreItems;
    setMoreItems(moreItems);

    const products_result = Array.isArray(res?.data?.WheelStyles) ? res.data.WheelStyles : [];
    setProductsLoading(false);

    return products_result;

  }, [setProductsLoading, setImgUrlBase, setMoreItems, setProducts, currentBrandIds, sections]);

  return { fetchWheelStyles };
};

export default useFetchWheelStyles;
