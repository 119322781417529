import { useModelViewer } from '@/hooks/useModelViewer';
import { disableRightClick } from '@/lib/utils';
import {
  colors,
  finishAllowed,
  finishes,
  getPartByName,
  getSwatchByName,
  Part,
  SwatchType,
} from './wheel-configurator-helpers';

const Swatch = ({
  swatch,
  curName,
  onClick,
}: {
  swatch: SwatchType;
  curName?: string | null;
  onClick?: (name: string) => void;
}) => {
  const { dataObject, currentPartName } = useModelViewer();

  const part = getPartByName({
    scopedPartsModel: dataObject,
    partName: currentPartName,
  });

  const isSelected =
    part?.color?.name === swatch.name || part?.finish?.name === swatch.name;

  return (
    <div
      className={`cursor-pointer overflow-hidden w-full max-w-[77px] active:scale-95 active:opacity-70 transition-all pb-0.5`}
      onClick={() => onClick && onClick(swatch.name)}
    >
      <div
        className={`flex flex-col items-center justify-start gap-0 w-full overflow-hidden `}
      >
        <div
          className={`p-[2px] border-2 border-transparent h-12 w-full rounded-xl ${
            isSelected && '!border-primary'
          }`}
        >
          {!swatch.swatchSample ? (
            <HexSwatch swatch={swatch} />
          ) : (
            <ImgSwatch swatch={swatch} />
          )}
        </div>
        <div
          className={`text-sm overflow-hidden overflow-ellipsis w-full text-center  whitespace-nowrap`}
        >
          {swatch.displayName}
        </div>
      </div>
    </div>
  );
};

export default Swatch;

export const HexSwatch = ({ swatch }: { swatch: SwatchType }) => {
  return (
    <div
      className={`w-full h-full rounded-lg border-1 dark:border-gray-700`}
      style={{ backgroundColor: swatch.hex || undefined }}
    ></div>
  );
};

export const ImgSwatch = ({ swatch }: { swatch: SwatchType }) => {
  return (
    <div className={`w-full h-full rounded-lg relative `}>
      <div
        className={`absolute  scale-y-[90%] -top-[3px] left-0 `}
        style={{ transformOrigin: '' }}
        onContextMenu={disableRightClick}
      >
        <img
          src={`/images/swatches/${swatch.swatchSample}`}
          alt={swatch.displayName}
          className={`h-auto w-full`}
        />
      </div>
    </div>
  );
};

export const SwatchMerge = ({ part }: { part: Part }) => {
  const color = getSwatchByName(colors, part?.color?.name);

  //if a no finish allowed color selected, show the color swatch only
  if (!finishAllowed(color?.name || '')) {
    return color ? (
      <div className={`w-[60px] h-[40px]`}>
        <ImgSwatch swatch={color} />
      </div>
    ) : null;
  }

  const finish = getSwatchByName(finishes, part?.finish?.name);
  //if other color swatch and finish color selected, so it's hex color as a gradient from top left to bottom right abosulte positioned over the finish swatch
  if (color && finish)
    return (
      <div
        className={`w-[60px] h-[35px] relative rounded-lg overflow-hidden shadow-md border-background`}
      >
        <div
          style={{
            background: `${color.hex}`,
            opacity: 0.67,
            // background: `linear-gradient(135deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 50%, ${color.hex} 50%, ${color.hex} 100%)`,
          }}
          className={`absolute top-0 left-0 w-full h-full z-[999] bg-[${color.hex}] rounded-lg`}
        ></div>
        <div className={`absolute top-0 left-0 w-full h-full z-[99]`}>
          <ImgSwatch swatch={finish} />
        </div>
        <div
          style={{ background: `${color.hex}` }}
          className={`absolute top-0 left-0 w-full h-full`}
        ></div>
      </div>
    );

  return null;
};
