import { WheelStyleDetail } from "@/lib/types";
import { twMerge } from "tailwind-merge";

export const WheelSubModelDisplay: React.FC<{ product: any, className?: string }> = ({ product, className }) =>
{
  //@ia autosync api changes the field names depending on the query type
  return (
    <>{product && <div className={twMerge(`text-muted-foreground text-sm`, className)} > {product.ImgFinish || product.Finish} {product.ImgShortColor || product.ShortColor} {(product.ImgAccent || product.Accent) && ` / ${product.ImgAccent || product.Accent}`} </div>}</ >
  );
};


export const WheelSizeDisplay: React.FC<{ productDetail: WheelStyleDetail | null, className?: string }> = ({ productDetail, className }) =>
{
  console.log(`productDetail`, productDetail);
  const wheelOffsetDisplayText = resolveWheelOffsetDisplayText(productDetail);
  return (
    <>{productDetail && <div className={twMerge(`text-muted-foreground text-sm`, className)} > {productDetail.BoltCircle1} x {productDetail.LugCount}  / {productDetail.Diameter}" x {productDetail.Width}" {wheelOffsetDisplayText && `/ ${wheelOffsetDisplayText}`} </div>}</ > 
  );
};


export const resolveWheelOffsetDisplayText = (productDetail: any) =>
{
  if (productDetail?.Offset)
  {
    return `${productDetail?.Offset} mm`
  }
  else if (productDetail?.Offset === 0)
  {
    return `0 mm`
  }
  else
  {
    return `N/A`
  }
}
