import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import React from 'react';

import { useStore } from '@/lib/store';
import QRCode from 'react-qr-code';

const QrCodeModal: React.FC<any> = ({
  classNameButton = '',
  openCallback,
  closeCallback,
}: {
  classNameButton?: string;
  openCallback?: () => void;
  closeCallback?: () => void;
} = {}) => {
  const currentAutosyncApiWheelStyle = useStore(
    (state) => state.currentAutosyncApiWheelStyle,
  );

  const handleOpenChange = (open: boolean) => {
    setQrCodeOpen(open);
  };

  const qrCodeOpen = useStore((state) => state.qrCodeOpen);
  const qrCodeTitle = useStore((state) => state.qrCodeTitle);
  const qrCodeSubTitle = useStore((state) => state.qrCodeSubTitle);
  const setQrCodeOpen = useStore((state) => state.setQrCodeOpen);
  return (
    <>
      <Dialog open={qrCodeOpen} onOpenChange={handleOpenChange}>
        <DialogContent
          className={`z-[999999999999] bg-card p-12 max-w-[480px] w-[95%] rounded-md`}
        >
          <DialogHeader>
            <DialogTitle
              className={`mb-3 text-foreground text-xl font-bold mx-auto`}
            >
              {qrCodeTitle}
            </DialogTitle>
            <div className={`text-center`}>{qrCodeSubTitle}</div>
            <QRCode
              level="Q"
              size={320}
              className={`py-2`}
              style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
              value={window.location.href}
              viewBox={`0 0 320 320`}
            />
            Note: Currently will only link to this page{' '}
            <b>{window.location.href}</b>
          </DialogHeader>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default QrCodeModal;
