// autoviz-component-monorepo/packages/product-catalog/src/components/wheel-configurator/dropdown-size-selector.tsx

import { DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from '@/components/ui/dropdown-menu';
import { useStore } from '@/lib/store';
import { ChevronDownIcon } from 'lucide-react';
import React, { useState } from 'react';
import { twMerge } from 'tailwind-merge';

const DropdownMenuWrapper: React.FC<any> = ({
  title,
  children,
  className,
}: {
  title: string;
  children: React.ReactNode;
  className?: string;
}) => {
  const [open, setOpen] = useState(false);

  return (
    <DropdownMenu open={open} onOpenChange={setOpen} modal={false}>
      <DropdownMenuTrigger className="!w-full">
        <div
          className={twMerge(
            "flex items-center border p-2 rounded-xl h-11 justify-center bg-card",
            open ? "bg-gray-100 dark:bg-black/10" : ""
          )}
        >
          <div className="text-lg pr-2 pl-1 w-full font-bold text-sm overflow-hidden">
            {title}
          </div>
          <ChevronDownIcon
            strokeWidth={2}
            className={twMerge("h-4 w-4", open ? "rotate-180" : "")}
          />
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        className={twMerge(
          "shadow-black/40 z-[999999999] bg-card dark:bg-[#353536dd] backdrop-blur-md min-w-[300px] overflow-hidden rounded-xl",
          className
        )}
        align="start"
        side="bottom"
        sideOffset={8}
        avoidCollisions
        collisionPadding={14}
      >
        {children}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default React.memo(DropdownMenuWrapper);
