// autoviz-component-monorepo/packages/product-catalog/src/components/product-catalog.tsx

import useFetchAutovizDbWheelModels from '@/hooks/useFetchAutovizDbWheelModels';
import useFetchBrands from '@/hooks/useFetchBrands';
import useFetchDomainByAkey from '@/hooks/useFetchDomainByAkey';
import useFetchWheelFilters from '@/hooks/useFetchWheelFilters';
import useFetchWheelStyles from '@/hooks/useFetchWheelStyles';
import useFilters from '@/hooks/useFilters';
import { useFindAndSetCurrentBrand } from '@/hooks/useFindAndSetCurrentBrand';
import useSetSiteCustomizations from '@/hooks/useSetSiteCustomizations';
import useStyleIdLogic from '@/hooks/useStyleIdLogic';
import { DomainResult, ProductCatalogProps, WheelStyle } from '@/lib/types';
import { corsAssetLoaderUrl, portalUploadsUrl } from '@/lib/utils';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { useCompanyStore, useFilterMenuStore, useStore } from '../lib/store';
import FilterResultsBar from './filter-results-bar';
import { Loader } from './loader';
import ProductList from './product-list';
import QrCodeModal from './qr-code-modal';

const ProductCatalog: React.FC<ProductCatalogProps> = ({
  showNavigation = true,
  showDarkModeToggle = true,
  context = '',
  classNameObject = {},
}: {
  showNavigation?: boolean;
  showDarkModeToggle?: boolean;
  context?: string;
  classNameObject?: any;
}) => {
  const currentBrand = useStore((state) => state.currentBrand);
  const setCurrentBrandIds = useStore((state) => state.setCurrentBrandIds);
  const curPage = useStore((state) => state.curPage);
  const setProducts = useStore((state) => state.setProducts);
  const productsLoading = useStore((state) => state.productsLoading);
  const currentDomain = useStore((state) => state.currentDomain);
  const setFilterData = useFilterMenuStore((state) => state.setFilterData);
  const setSections = useFilterMenuStore((state) => state.setSections);

  const qrCodeOpen = useStore((state) => state.qrCodeOpen);
  const qrCodeTitle = useStore((state) => state.qrCodeTitle);

  const companyDisplayName = useCompanyStore(
    (state) => state.companyDisplayName,
  );
  const logoUrl = useCompanyStore((state) => state.logoUrl);
  const brandLogosUrlBase = useStore((state) => state.brandLogosUrlBase);
  const setProductsLoadingTrigger = useStore(
    (state) => state.setProductsLoadingTrigger,
  );
  const setAppInitialized = useStore((state) => state.setAppInitialized);
  const setSearchParams = useStore((state) => state.setSearchParams);
  const fetchFromAutovizDb = useStore((state) => state.fetchFromAutovizDb);
  const setFetchFromAutovizDb = useStore(
    (state) => state.setFetchFromAutovizDb,
  );

  useStyleIdLogic();
  const location = useLocation();
  const urlSearchParams = new URLSearchParams(location.search);
  const searchParams = {
    brandIds: (urlSearchParams.get('brandIds') || currentBrand?.Id) as string,
    akey: urlSearchParams.get('akey') || currentDomain?.akey,
    search: urlSearchParams.get('search') || currentDomain?.akey,
    configured: urlSearchParams.get('configured') === 'false' ? false : true,
    customizable:
      urlSearchParams.get('customizable') === 'false' ? false : true,
  };

  const initialized = useRef(false);

  //brands are always fetched and added to zustand store as `brands`, runs automatically and only on first page load
  const { brands } = useFetchBrands();
  const { fetchWheelStyles } = useFetchWheelStyles();
  const { fetchDomainByAkey } = useFetchDomainByAkey();
  const { fetchWheelFilters } = useFetchWheelFilters();
  const { setSiteCustomizations } = useSetSiteCustomizations();
  const { findAndSetCurrentBrand } = useFindAndSetCurrentBrand();
  const { fetchAutovizDbWheelModels, fetchAutovizDbWheelFilters } =
    useFetchAutovizDbWheelModels();
  // const { brandIdsToBrandNames } = useBrandIdsToBrandNames();
  const { getFiltersFromQueryParams } = useFilters();
  const { setFiltersCount } = useFilterMenuStore();

  const resolveAndFetch = useCallback(
    async ({
      wheelStyles = {},
      wheelFilters = {},
    }: {
      wheelStyles?: { brandIds?: string };
      wheelFilters?: { brandIds?: string };
    } = {}) => {
      // debugger
      if (fetchFromAutovizDb) {
        // console.log(`fetch from fetchFromAutovizDb`);
        const filtersFromQueryParams = getFiltersFromQueryParams();

        fetchAutovizDbWheelFilters({
          customizable: searchParams.customizable,
        }).then((result) => {
          setFilterData(result.filters);
          setSections(filtersFromQueryParams);
          setFiltersCount(Object.keys(filtersFromQueryParams).length);
        });

        fetchAutovizDbWheelModels({
          customizable: searchParams.customizable,
          filters: filtersFromQueryParams,
          search: searchParams.search,
        }).then((wheel_models_result) => {
          setProducts(wheel_models_result.products);
        });
      } else {
        // console.log(`fetch from API`);
        fetchWheelFilters({ brandIds: wheelFilters.brandIds });
        const products_result = await fetchWheelStyles({
          curPage: 1,
          brandIds: wheelStyles.brandIds as string,
        });
        setProducts(products_result);
      }
    },
    [
      fetchWheelFilters,
      fetchWheelStyles,
      setProducts,
      brands,
      fetchAutovizDbWheelModels,
    ],
  );

  // useEffect(() => {
  //   console.log(`fetchFromAutovizDb`, fetchFromAutovizDb);
  // }, [fetchFromAutovizDb]);
  useEffect(() => {
    (async () => {
      setProductsLoadingTrigger('filter');
      setFetchFromAutovizDb(
        searchParams.configured || searchParams.customizable,
      );

      // the list of all brands is needed for all other operations
      if ((brands && Object.keys(brands).length === 0) || initialized.current)
        return;

      setSearchParams(searchParams);

      //nothing custom set, fetch all products
      if (
        searchParams.brandIds == undefined &&
        searchParams.akey == undefined
      ) {
        initialized.current = true;
        await resolveAndFetch();
      }

      //akey set in the url search param, fetch domainResult by Retailer/Company akey
      else if (searchParams.akey) {
        initialized.current = true;
        let domainResult: DomainResult | null = null;
        domainResult = await fetchDomainByAkey({ akey: searchParams.akey });

        if (domainResult?.brandIds) {
          setCurrentBrandIds(domainResult.brandIds);
          if (/^\d+$/.test(domainResult.brandIds)) {
            findAndSetCurrentBrand({ brandId: domainResult.brandIds, brands });
          }
          setSiteCustomizations(domainResult);

          await resolveAndFetch({
            wheelStyles: { brandIds: domainResult.brandIds },
            wheelFilters: { brandIds: domainResult.brandIds },
          });
        } else {
          await resolveAndFetch(); //if domainResult has no brandIds set, fetch all products by default
        }
      }

      //brandIds set in the url search param
      else if (searchParams.brandIds) {
        initialized.current = true;
        if (/^\d+$/.test(searchParams.brandIds)) {
          findAndSetCurrentBrand({ brandId: searchParams.brandIds, brands });
        }
        setCurrentBrandIds(searchParams.brandIds);

        await resolveAndFetch({
          wheelStyles: { brandIds: searchParams.brandIds },
          wheelFilters: { brandIds: searchParams.brandIds },
        });
      }

      setAppInitialized(true);
    })();
  }, [searchParams.brandIds, brands, searchParams.akey]);

  const loadNextPage = async () => {
    if (productsLoading) return;
    let newPage = curPage + 1;
    const products_result: WheelStyle[] = await fetchWheelStyles({
      curPage: newPage,
      trigger: 'scroll',
    });
    setProducts((prevProducts) => [...prevProducts, ...products_result]);
    return true;
  };

  // const [animate, setAnimate] = useState(false);

  const navigate = useNavigate();
  const handleNavigation = (url: string) => {
    // setAnimate(true);

    // if (isThreeColumnView) {
    //   setIsThreeColumnView(false);
    //   setTimeout(() => {
    //     navigate(url);
    //   }, 1000);
    // } else {
    navigate(url);
    // }
  };

  const [wheelDetailPage, setWheelDetailPage] = useState(true);
  useEffect(() => {
    // console.log(`location.pathname`, location.pathname);
    if (location.pathname.includes(`/catalog/wheel`)) {
      // console.log(`wheelDetailPage setWheelDetailPage true`, location.pathname);
      setWheelDetailPage(true);
    } else {
      setWheelDetailPage(false);
    }
  }, [location.pathname]);

  // useEffect(() => {
  //   if (animate) {
  //     const timer = setTimeout(() => {
  //       setAnimate(false);
  //     }, 1000); // duration of animation

  //     return () => clearTimeout(timer);
  //   }
  // }, [animate]);

  // const [open, setOpen] = React.useState(false);

  // const handleOpenChange = (open: boolean) => {
  //   setOpen(open);
  //   // if (open && openCallback) openCallback();
  //   // if (!open && closeCallback) closeCallback();
  // };

  const outletLoaderIsVisible = useStore(
    (state) => state.outletLoaderIsVisible,
  );
  return (
    <>
      <div
        className={twMerge(
          `bg-background h-screen relative w-full select-none grid  gap-0 overflow-hidden`,
          wheelDetailPage ? 'lg:grid-cols-[33.3%,66.6%]' : 'grid-cols-1',
          classNameObject.ProductCatalog,
        )}
      >
        <div
          className={twMerge(
            ' select-none  ',
            // isThreeColumnView || context?.includes('CatalogDropdownMenu')
            //   ? 'w-full'
            //   : 'w-[33.3%] translate-x-0 ',
            // !isLeftDrawerOpen
            //   ? 'translate-x-0'
            //   : '-translate-x-full bp1080:translate-x-0',
          )}
        >
          {/* <button onClick={() => setIsThreeColumnView(!isThreeColumnView)}>Toggle</button> */}
          {/* {<div className={`absolute left-0 top-0`}>Loading: {productsLoading ? 'true' : 'false'} | Trigger: {productsLoadingTrigger} | curPage: {curPage} | moreItems: {moreItems ? 'true' : 'false'}</div>} */}
          {searchParams.akey && (
            <div className=" flex items-center justify-center p-2 h-[60px]">
              {companyDisplayName && logoUrl && (
                <img
                  src={`${corsAssetLoaderUrl}${portalUploadsUrl}/${logoUrl}`}
                  alt={companyDisplayName}
                  className="max-h-[60px] "
                />
              )}
            </div>
          )}

          {typeof searchParams?.brandIds == 'string' &&
            !searchParams?.brandIds?.includes(',') && (
              <div className=" flex items-center justify-center p-2 h-[60px]">
                {currentBrand?.Logo && (
                  <div className=" flex items-center justify-center p-2 ">
                    <img
                      src={`${brandLogosUrlBase}${currentBrand?.Logo}`}
                      alt={currentBrand.Brand}
                      className="max-h-[60px] "
                    />
                  </div>
                )}
              </div>
            )}

          <div
            className={twMerge(
              `p-4  relative  overflow-hidden w-full  bg-background `,
              wheelDetailPage ? 'hidden lg:block' : '',
            )}
            // className={`${
            //   isThreeColumnView ? 'mx-auto-to-mx-0 ' : 'mx-auto-to-mx-0'
            // } inactive_transition-all   inactive_duration-1000  p-4  relative  overflow-visible  bg-background w-full`}
          >
            {/* <div className={`fixed top-0 -right-0 `}>
            <div className={` relative top-0  -right-0 translate-x-1/2 z-10 `}>
              <div
                className={`bp1080:hidden   fixed bg-background top-12 -left-1.5 p-1 rounded-full cursor-pointer z-10`}
                onClick={() => setIsLeftDrawerOpen(!isLeftDrawerOpen)}
              >
                {isLeftDrawerOpen ? (
                  <ChevronsLeft size={28} className="rotate-180" />
                ) : (
                  <ChevronsLeft size={28} />
                )}
              </div>
              <div
                className={`h-screen w-[10px] rounded-r-md fixed top-0 left-0 bg-background opacity-100`}
              ></div>
            </div>
          </div> */}

            <div className="mx-auto ">
              {/* <div className="text-2xl font-bold pb-3 text-center min-h-6">{styleId == undefined && !searchParams.customizable && `Select a wheel to customize...`}</div> */}

              <FilterResultsBar
                showNavigation={showNavigation}
                showDarkModeToggle={showDarkModeToggle}
              />
            </div>

            <div className="mt-5  mx-auto w-full">
              <ProductList
                loadNextPage={loadNextPage}
                handleNavigation={handleNavigation}
                classNameObject={classNameObject}
              />
            </div>
          </div>
        </div>

        <div
          id={`page-outlet-container`}
          className=" overflow-scroll md:overflow-hidden w-full h-screen relative"
        >
          <Loader
            visible={outletLoaderIsVisible}
            className={`h-[200px] w-[200px] absolute z-[999999999] inset-0 top-[calc(50%-100px)] mx-auto`}
          />
          <Outlet />
        </div>
      </div>
      <QrCodeModal />
    </>
  );
};

export default React.memo(ProductCatalog);
