// autoviz-component-monorepo/packages/product-catalog/src/components/wheel-interactives-navigation-list-v1.tsx

import { useModelViewer } from '@/hooks/useModelViewer';
import useShare from '@/hooks/useShare';
import { siteRoot } from '@/lib/utils';
import { Share } from 'lucide-react';
import { isMobile, isTablet } from 'react-device-detect';
import { useStore } from '../lib/store';
import { Button } from './ui/button';
import { toast } from './ui/use-toast';

export function WheelInteractivesNavigationV1({
  product,
  allowListMode,
  wheelInfo,
}: {
  product: any;
  allowListMode?: boolean;
  wheelInfo?: any;
}) {
  const { copyShareUrlToClipboard } = useShare();
  const handleClick = (e: any, action: string) => {
    e.preventDefault();
    e.stopPropagation();

    let resolvedProductId = product?.StyleId || product?.Id;

    if (action === 'SHARE') {
      copyShareUrlToClipboard();
    } else if (action === 'CUSTOMIZE') {
      let data = product?.data || wheelInfo;
      let url = `${siteRoot}/testEmbed.html?productId=${resolvedProductId}&glb=${data?.path}&part_number=${data?.Pn}`;
      window.open(url, '_blank');
    } else if (action === 'SEE_IN_3D') {
      toast({
        description: 'Action not supported yet!',
        variant: 'destructive',
      });
    } else if (action === 'SEE_IN_MY_SPACE') {
      toast({
        description: 'Action not supported yet!',
        variant: 'destructive',
      });
    } else if (action === 'TRY_ON_AR') {
      toast({
        description: 'Action not supported yet!',
        variant: 'destructive',
      });
    }
  };
  // console.log(`product`, product)
  const iconSize = 28;
  const buttonSize = 'default';
  const setQrCodeOpen = useStore((state) => state.setQrCodeOpen);
  const setQrCodeTitle = useStore((state) => state.setQrCodeTitle);
  const setQrCodeSubTitle = useStore((state) => state.setQrCodeSubTitle);

  const {
    activateAR,
    modelViewerRef,
    modelViewerRefState,
    setModelViewerRefSelf,
  } = useModelViewer();
  const handleSeeInMySpace = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    // console.log(`deviceType`, deviceType);
    document.getElementById('page-outlet-container')?.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    if (isMobile || isTablet) {
      activateAR();
    } else {
      setQrCodeOpen(true);
      setQrCodeTitle('See in my space');
      setQrCodeSubTitle(
        'Scan with your mobile device to see a wheel in your space',
      );
    }
  };

  const handleTryOnInAr = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setQrCodeOpen(true);
    setQrCodeTitle('Try on in AR');
    setQrCodeSubTitle(
      'Scan with your mobile device to virtually mount wheels on your vehicle',
    );
  };

  const arNeedsActivation = useStore((state) => state.arNeedsActivation);
  const arActivated = useStore((state) => state.arActivated);

  return (
    <div className="flex flex-col flex-wrap xs:flex-row  gap-2 xs:items-center xs:justify-center">
      <Button
        onClick={(e) => handleTryOnInAr(e)}
        variant={'secondary'}
        className="py-10 text-base md:py-6 lg:text-sm xl:text-base"
      >
        <div className="flex flex-col  gap-2 items-center justify-center">
          <div>
            <Share className="w-5 h-5" strokeWidth={1.5} />
          </div>
          <div>
            <p className="">Share</p>
          </div>
        </div>
      </Button>
      <Button
        loading={arNeedsActivation && !arActivated}
        onClick={(e) => handleSeeInMySpace(e)}
        variant={'secondary'}
        className="py-10 text-base md:py-6 lg:text-sm xl:text-base"
      >
        <div className="flex md:flex-row flex-col gap-2 items-center justify-center">
          <div>
            <svg
              width="21"
              height="20"
              viewBox="0 0 21 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="stroke-[#191919] dark:stroke-[#fff]"
            >
              <path
                d="M16.0582 15.5584L17.9082 14.6334V12.3167M12.3499 8.15008L10.4999 9.07508L12.3499 8.15008ZM10.4999 9.07508L8.64988 8.15008L10.4999 9.07508ZM10.4999 9.07508V11.3917V9.07508ZM17.9082 5.36675L16.0582 6.29175L17.9082 5.36675ZM17.9082 5.36675L16.0582 4.44175L17.9082 5.36675ZM17.9082 5.36675V7.68342V5.36675ZM12.3499 2.59175L10.4999 1.66675L8.64988 2.59175H12.3499ZM3.09155 5.36675L4.94155 4.44175L3.09155 5.36675ZM3.09155 5.36675L4.94155 6.29175L3.09155 5.36675ZM3.09155 5.36675V7.68342V5.36675ZM10.4999 18.3334L8.64988 17.4084L10.4999 18.3334ZM10.4999 18.3334L12.3499 17.4084L10.4999 18.3334ZM10.4999 18.3334V16.0167V18.3334ZM4.94155 15.5584L3.09155 14.6334V12.3167L4.94155 15.5584Z"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div className="max-w-fit break-words ">
            <p className="">See in my space</p>
          </div>
        </div>
      </Button>
      <Button
        onClick={(e) => handleTryOnInAr(e)}
        variant={'secondary'}
        className="py-10 text-base md:py-6 lg:text-sm xl:text-base"
      >
        <div className="flex md:flex-row flex-col  gap-2 items-center justify-center">
          <div>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="stroke-[#191919] dark:stroke-[#fff]"
            >
              <path
                d="M7.50594 14.1667C7.50594 15.0872 6.75975 15.8333 5.83927 15.8333C4.9188 15.8333 4.17261 15.0872 4.17261 14.1667C4.17261 13.2462 4.9188 12.5 5.83927 12.5C6.75975 12.5 7.50594 13.2462 7.50594 14.1667Z"
                stroke="#191919"
                strokeWidth="1.2"
                className="stroke-[#191919] dark:stroke-[#fff]"
              />
              <path
                d="M15.8394 14.1667C15.8394 15.0872 15.0933 15.8333 14.1728 15.8333C13.2523 15.8333 12.5061 15.0872 12.5061 14.1667C12.5061 13.2462 13.2523 12.5 14.1728 12.5C15.0933 12.5 15.8394 13.2462 15.8394 14.1667Z"
                stroke="#191919"
                strokeWidth="1.2"
                className="stroke-[#191919] dark:stroke-[#fff]"
              />
              <path
                d="M1.67277 8.33341H15.0061M1.67277 8.33341C1.67277 8.98341 1.65611 10.8667 1.6761 12.7167C1.70607 13.3167 1.806 13.8167 4.17419 14.1667M1.67277 8.33341C1.85263 6.88341 2.63537 5.16675 3.03506 4.51675M7.5061 8.33341V4.16675M12.4978 14.1667H7.50166M1.68609 4.16675H10.1996C10.1996 4.16675 10.6493 4.16675 11.0489 4.20675C11.7984 4.27675 12.4279 4.61675 13.0574 5.46675C13.724 6.36675 14.2365 7.50675 14.916 8.11675C16.0452 9.13041 18.1935 8.81675 18.3134 10.9667C18.3434 12.0667 18.3434 13.2667 18.2934 13.4667C18.2132 14.0557 17.7591 14.1518 17.1942 14.1667C16.7041 14.1797 16.1132 14.1435 15.8253 14.1667"
                stroke="#191919"
                strokeWidth="1.2"
                strokeLinecap="round"
                className="stroke-[#191919] dark:stroke-[#fff]"
              />
            </svg>
          </div>
          <div>
            <p className="">Try on in AR</p>
          </div>
        </div>
      </Button>
    </div>
  );
}
