// import { MoonIcon, SunIcon } from 'lucide-react';
import React from 'react';
import { twMerge } from 'tailwind-merge';
import { useThemeStore } from '../lib/store';
import ToggleButton from './toggle-button';
import SunIcon from './assets/SunIcon';
import MoonIcon from './assets/moonIcon';
// import { MoonIcon } from 'lucide-react';

const ToggleButtonTheme: React.FC = () => {
  const isDark = useThemeStore((state) => state.isDark);
  const toggleTheme = useThemeStore((state) => state.toggleTheme);
  const iconSize = 14;

  // size={iconSize} strokeWidth={3}
  return (
    <>
      <ToggleButton
        containerClassName={``}
        pivotBoolean={!isDark}
        handleToggleFunction={toggleTheme}
        leftContent={
          <>
            <SunIcon />
          </>
        }
        rightContent={
          <>
            <MoonIcon />
          </>
        }
      />
    </>
  );
};

export default ToggleButtonTheme;
