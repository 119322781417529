// autoviz-component-monorepo/packages/product-catalog/src/components/product-catalog.tsx

import ModelViewer from '@/components/model-viewer';
import { Button } from '@/components/ui/button';
import { toast } from '@/components/ui/use-toast';
import useFetchAndSetCurrentWheelData from '@/hooks/useFetchAndSetCurrentWheelData';
import { useStore } from '@/lib/store';
import { ProductCatalogProps } from '@/lib/types';
import { cn } from '@/lib/utils';
import { Box, EyeIcon, RotateCcw, RotateCw } from 'lucide-react';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import AlertDialogComponent from '../alert-dialog-component';
import NavPartsSelector from './nav-parts-selector';
import WheelConfiguratorHeader from './wheel-configurator-header';
import { getEstimatedWheelPosition } from './wheel-configurator-helpers';

const WheelConfigurator: React.FC<ProductCatalogProps> = () => {
  const navPartsSelectorRef = useRef<HTMLDivElement>(null);
  const navPartsSwatchesPanelRef = useRef<HTMLDivElement>(null);
  const [navOpen, setNavOpen] = useState(false);

  const { styleId, wheelId } = useParams<{
    styleId: string;
    wheelId: string;
  }>();

  const currentAutosyncApiWheelStyle = useStore(
    (state) => state.currentAutosyncApiWheelStyle,
  );
  const currentAutosyncApiWheelStyles = useStore(
    (state) => state.currentAutosyncApiWheelStyles,
  );
  const currentAutovizDbWheelInfo = useStore(
    (state) => state.currentAutovizDbWheelInfo,
  );
  const currentAutovizDbWheelModel = useStore(
    (state) => state.currentAutovizDbWheelModel,
  );

  const { fetchAndSetCurrentWheelData } = useFetchAndSetCurrentWheelData();

  const init = useRef(false);
  useEffect(() => {
    if (currentAutosyncApiWheelStyle == null && !init.current) {
      init.current = true;
      fetchAndSetCurrentWheelData({ styleId, wheelId });
    }
  }, [styleId, wheelId, currentAutosyncApiWheelStyle]);

  const [offsetWheel, setOffsetWheel] = useState('0px');

  useEffect(() => {
    setTimeout(() => {
      let totalOffset = getEstimatedWheelPosition({
        navPartsSwatchesPanelRef,
        navPartsSelectorRef,
        navOpen,
      });
      setOffsetWheel(`${totalOffset}px`);
    }, 300);
  }, [navOpen]);

  const borderClass = 0 ? `border-1 border-gray-300` : ``;

  return (
    <div
      className={`bg-[#e6e6e6ee] dark:bg-[#333333cc] grid grid-rows-[80px_1fr] h-screen relative overflow-hidden  `}
    >
      <WheelConfiguratorHeader />
      <AlertDialogComponent />
      <div className={` h-full  w-full relative`}>
        <div
          className={`max-w-screen-2xl h-full mx-auto relative overflow-hidden  `}
        >
          <div
            className={`absolute top-4 left-4 z-10 min-w-[180px] w-[10%] max-w-[252px]`}
          >
            <NavPartsSelector
              navPartsSelectorRef={navPartsSelectorRef}
              navPartsSwatchesPanelRef={navPartsSwatchesPanelRef}
              navOpen={navOpen}
              setNavOpen={setNavOpen}
            />
          </div>

          <div
            style={{
              transform: `translateX(${offsetWheel})`,
            }}
            className={cn(
              `absolute left-0 right-0 bottom-0 h-full w-[100%] p-0 transition-all duration-500 ease-in-out `,
              borderClass,
            )}
          >
            <ModelViewer
              src={currentAutovizDbWheelModel?.path || ''}
              alt={currentAutovizDbWheelModel?.model || ''}
              autoRotate
              shadowIntensity={1}
              // handleModelLoaded={handleModelLoaded}
            />
          </div>

          <div
            className={`absolute right-4 top-4 grid grid-cols-2 gap-2 flex flex-row items-center justify-center`}
          >
            <Button
              variant={'secondary'}
              className={`!border-1 border-gray-300 rounded-xl !p-4 !px-4 h-7 flex flex-col items-center justify-center`}
              onClick={() =>
                toast({
                  title: 'Undo not implemented',
                  variant: 'destructive',
                })
              }
            >
              <RotateCcw strokeWidth={1.1} className={`absolute h-5 w-5`} />
            </Button>
            <Button
              variant={'secondary'}
              className={`!border-1 border-gray-300 rounded-xl !p-4 !px-4 h-7 flex flex-col items-center justify-center`}
              onClick={() =>
                toast({
                  title: 'Redo not implemented',
                  variant: 'destructive',
                })
              }
            >
              <RotateCw strokeWidth={1.1} className={`absolute h-5 w-5`} />
            </Button>
          </div>

          <div
            style={{
              transform: `translateX(${offsetWheel})`,
            }}
            className={`absolute bottom-[10%] w-[380px] mx-auto right-0 left-0  grid grid-cols-2 gap-4 flex flex-row items-start transition-transform duration-500 ease-in-out ${borderClass}`}
          >
            <Button
              variant={'secondary'}
              className={`!border-1 border-gray-300 rounded-xl !p-4 !px-4 h-7 flex flex-col items-center justify-center`}
              onClick={() =>
                toast({ title: 'Not implemented', variant: 'destructive' })
              }
            >
              <div
                className={`grid grid-cols-[auto,1fr] gap-1 flex flex-col items-center justify-center`}
              >
                <Box strokeWidth={1.1} className={`h-4 w-4`} />
                <div className={`overflow-hidden text-xs`}>See in my space</div>
              </div>
            </Button>

            <Button
              variant={'secondary'}
              className={`!border-1 border-gray-300 rounded-xl !p-4 !px-4 h-7 flex flex-col items-center justify-center`}
              onClick={() =>
                toast({ title: 'Not implemented', variant: 'destructive' })
              }
            >
              <div
                className={`grid grid-cols-[auto,1fr] gap-2 flex flex-col items-center justify-center`}
              >
                <EyeIcon strokeWidth={1.1} className={`h-4 w-4`} />
                <div className={`overflow-hidden text-xs`}>Try on in AR</div>
              </div>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(WheelConfigurator);
