// src/hooks/useResolveAndFetchOnFilterChange.ts

import { useCallback } from 'react';
import { useStore } from '../lib/store';
import useFetchAutovizDbWheelModels from './useFetchAutovizDbWheelModels';
import useFetchWheelStyles from './useFetchWheelStyles';
import { useLocation } from 'react-router-dom';
import useFilters from './useFilters';

const useResolveAndFetchOnFilterChange = () =>
{
  const fetchFromAutovizDb = useStore((state) => state.fetchFromAutovizDb ); 
  const { fetchAutovizDbWheelModels } = useFetchAutovizDbWheelModels();
  const { fetchWheelStyles } = useFetchWheelStyles();
  const { addFiltersToQueryParams } = useFilters();
  const location = useLocation();
  const urlSearchParams = new URLSearchParams(location.search);

  const resolveAndFetchOnFilterChange = useCallback( async ({ filters }: { filters: {} }) =>
  {
    let productResults = []
    
    addFiltersToQueryParams({ params: filters }) 

    if (fetchFromAutovizDb)
    {
      productResults = (await fetchAutovizDbWheelModels({ filters, search: urlSearchParams.get('search')|| undefined })).products
    } else
    {
      productResults = await fetchWheelStyles({ curPage: 1, filters: filters, trigger: "filter" });
    }

    return productResults

  }, [fetchWheelStyles, fetchAutovizDbWheelModels, fetchFromAutovizDb]);

  return { resolveAndFetchOnFilterChange }
}
export default useResolveAndFetchOnFilterChange;
