// useStyleIdLogic.ts
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useStore } from '../lib/store';

const useStyleIdLogic = () =>
{
  const { styleId } = useParams<{ styleId: string }>();
  const setIsThreeColumnView = useStore((state) => state.setIsThreeColumnView);

  useEffect(() =>
  {
    setIsThreeColumnView(styleId ? false : true);
  }, [styleId, setIsThreeColumnView]);
};

export default useStyleIdLogic;
