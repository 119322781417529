// autoviz-component-monorepo/packages/product-catalog/src/components/wheel-card.tsx

import { disableRightClick, productCatalogPath } from '@/lib/utils';
import React from 'react';
import { useParams } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { useStore } from '../lib/store';
import { AutovizDbWheelModel, Brand, WheelStyle } from '../lib/types';
import WheelMedia from './wheel-media';

const WheelCard: React.FC<{
  handleNavigation: (id: string) => void;
  index: number;
  product: WheelStyle | AutovizDbWheelModel;
  listDisplayMode: string;
  currentBrand: Brand | null;
  brandLogosUrlBase: string;
  queryString: string;
}> = ({
  handleNavigation,
  index,
  product,
  listDisplayMode,
  currentBrand,
  brandLogosUrlBase,
  queryString,
}) => {
  let resolvedBrand =
    (product as WheelStyle)?.Brand || (product as AutovizDbWheelModel)?.brand;
  let resolvedId =
    (product as WheelStyle)?.Id || (product as AutovizDbWheelModel)?.wheel_id;
  let resolvedModel =
    (product as WheelStyle)?.Model || (product as AutovizDbWheelModel)?.model;

  const brands = useStore((state) => state.brands);
  const brand = brands ? (brands[resolvedBrand] as Brand) : null;
  const setIsLeftDrawerOpen = useStore((state) => state.setIsLeftDrawerOpen);

  const navigationContextRootPath = useStore(
    (state) => state.navigationContextRootPath,
  );

  const params: any = {
    styleId: useParams<{ styleId: string }>().styleId,
  };

  const handleOnClick = () => {
    // console.log('hello from handle click');
    setIsLeftDrawerOpen(true);

    if (navigationContextRootPath == productCatalogPath) {
      handleNavigation(
        `${navigationContextRootPath}/${resolvedId}`, //?${queryString}
      );
    } else {
      window.location.href = `${navigationContextRootPath}/${resolvedId}?Brand=${resolvedBrand}`; //?${queryString}
    }
  };

  return (
    <div
      onClick={handleOnClick}
      // onMouseOver={handleMouseOver}
      // onMouseOut={handleMouseOut}
      className={twMerge(
        'relative  overflow-hidden border bg-[var(--card)] rounded-2xl p-4 flex flex-col text-center  cursor-pointer  transition-all w-full active:scale-95 active:shadow-md active:opacity-70',
        params.styleId &&
          parseInt(params.styleId) == resolvedId &&
          'border-2 bg-gray-100 dark:bg-gray-800 border-foreground shadow-md',
        listDisplayMode === 'LIST' ? 'text-left' : 'max-w-[400px]',
      )}
    >
      {!currentBrand && (
        <div
          onContextMenu={disableRightClick}
          className={twMerge(
            'absolute top-0 left-0 px-2 py-0.5 w-full h-[35px] flex flex-col items-start justify-start',
            listDisplayMode === 'LIST' && '',
          )}
        >
          {brand?.Logo ? (
            <img
              src={`${brandLogosUrlBase}${brand?.Logo}`}
              alt={brand?.Brand}
              className="h-full object-contain "
            />
          ) : (
            <>{brand?.Brand}</>
          )}
        </div>
      )}

      <div
        className={` mt-[20px] ${listDisplayMode === 'LIST' && 'grid grid-cols-[20%,1fr] gap-10 flex flex-col items-center'}`}
      >
        <WheelMedia
          currentAutovizDbWheelModel={product}
          allow3d={true}
          autoRotate={false}
          index={index}
        />

        <div className={''}>
          <h3 className={`text-[20px] font-semibold text-left leading-6`}>
            {resolvedModel}
          </h3>
        </div>
      </div>
    </div>
  );
};

export default WheelCard;
