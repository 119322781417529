// src/hooks/useFetchBrands.ts

import { useQuery } from '@tanstack/react-query';
import { useStore } from '../lib/store';
import { apiEndpoint } from '../lib/utils';
import { useEffect } from 'react';

const fetchBrands = async () =>
{
  const formData = new FormData();
  formData.append('data', JSON.stringify({}));

  const response = await fetch(`${apiEndpoint}/_get_all_brands.php`, {
    method: 'POST',
    body: formData,
  });

  const res = await response.json();
  return {
    brands: res?.data?.Brands || {},
    brandLogosUrlBase: res?.data?.BrandLogosUrlBase || '',
  };
};

const useFetchBrands = () =>
{
  const setBrands = useStore((state) => state.setBrands);
  const setBrandLogosUrlBase = useStore((state) => state.setBrandLogosUrlBase);

  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: ['brands'],
    queryFn: fetchBrands,
    staleTime: 1000 * 60 * 5
  });


  useEffect(() =>
  {
    if (data)
    {
      setBrands(data?.brands);
      setBrandLogosUrlBase(data?.brandLogosUrlBase);
    }
  }, [data]);


  return { isLoading, isError, refetch, brands: data?.brands };
};

export default useFetchBrands;
