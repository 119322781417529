// autoviz-component-monorepo/packages/product-catalog/src/components/home.tsx

import React from 'react';
import { Link } from 'react-router-dom';
import { Navigate } from "react-router-dom";

const Home: React.FC<any> = () =>
{


  return (
    <div className="grid gap-x-4 max-w-screen-xl mx-auto p-1 select-none">

      <h1>Home</h1>
      <div className={`p-4 grid grid-rows-auto gap-4`}>
        <Link to="/">Home</Link>
        <Link to="/catalog">Catalog</Link>
      </div>

    </div>
  );
};

export default Home;


