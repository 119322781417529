import { useLocation, useNavigate } from 'react-router-dom';

const useSearchParamFunctions = () =>
{
  const location = useLocation();
  const navigate = useNavigate();

  const updateSearchParam = (key: string, value: string | number) =>
  {
    const urlSearchParams = new URLSearchParams(location.search);
    urlSearchParams.set(key, value.toString());
    navigate(`${location.pathname}?${urlSearchParams.toString()}`, { replace: true });
  };

  const addSerachParams = (params: { [key: string]: string | number }) =>
  {
    const urlSearchParams = new URLSearchParams(location.search);
    Object.keys(params).forEach(key =>
    {
      urlSearchParams.set(key, params[key].toString());
    });
    navigate(`${location.pathname}?${urlSearchParams.toString()}`, { replace: true });
  };

  return { updateSearchParam, addSerachParams };
};

export default useSearchParamFunctions;
