import React, { useEffect, useState } from 'react';

import { apiEndpoint } from '../lib/utils';
import { useQuery } from '@tanstack/react-query';
import { FitmentDataResponse } from '@/types/figments';
type MakeResponseType = {
  Id: number;
  Logo: string;
  Make: string;
};
const fetchVehicle = async () => {
  const formData = new FormData();
  formData.append('data', JSON.stringify({}));

  const response = await fetch(`${apiEndpoint}/get_vehicle_makes.php`, {
    method: 'POST',
    body: formData,
  });

  const res = await response.json();
  let Makes: MakeResponseType[] | null = res?.data?.Makes || null;
  return {
    Makes,
    LogoUrlBase: res?.data?.LogoUrlBase || '',
  };
};

export function useFetchVehicle() {
  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: ['vehicle-make'],
    queryFn: fetchVehicle,
    staleTime: 1000 * 60 * 5,
  });
  return { vehicleMake: data, isLoading, isError, refetch };
}

type VehicleYearByModelParams = { make: string };

export const fetchVehicleYearByModel = async ({
  make,
}: VehicleYearByModelParams) => {
  const formData = new FormData();
  console.log(make, '==INPUT');
  formData.append('make', make);

  const response = await fetch(`${apiEndpoint}/get_vehicle_years_by_make.php`, {
    method: 'POST',
    body: formData,
  });

  const res = await response.json();

  let years: number[] | null = res?.data || null;
  return {
    years,
  };
};

export function useFetchVehicleYearByModel(param: VehicleYearByModelParams) {
  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: ['vehicle-year-by-model', param.make],
    queryFn: () => fetchVehicleYearByModel(param),
    staleTime: 1000 * 60 * 5,
  });
  return { vehicleYears: data?.years, isLoading, isError, refetch };
}

type VehicleTypeByYearAndMakeParams = { make: string; year: number | string };

type VehicleModel = {
  Id: number;
  Img032: Record<string, string>;
  Make: string;
  Model: string;
  Type: string;
  Year: number;
};

type VehicleModelData = {
  ImgUrlBase: string;
  Models: VehicleModel[];
};
type ImageSet = {
  '240': string;
  '350': string;
  '640': string;
  '960': string;
  '2400': string;
};

// Type for individual colors
type Color = {
  Id: number;
  Code: string;
  Name: string;
  ShortName: string;
  Rgb1: string;
  Rgb2: string;
  Swatch: string;
  Img001: boolean;
  Img014: boolean;
  Img032: boolean;
};

// Type for individual vehicles
type Vehicle = {
  Bed: string;
  Body: string;
  Colors: Color[];
  Doors: number | null;
  Drw: boolean;
  Id: number;
  Img001: ImageSet;
  Img014: ImageSet;
  Img032: ImageSet;
  ImgColorId: number;
  Make: string;
  Model: string;
  Submodel: string;
  Type: string;
  Year: number;
};

// Type for the entire response structure
type VehicleResponse = {
  ImgUrlBase: string;
  MoreItems: boolean;
  SwatchUrlBase: string;
  Vehicles: Vehicle[];
};
export const fetchVehicleTypeByYearAndMake = async ({
  make,
  year,
  model,
}: VehicleTypeByYearAndMakeParams & { model?: string }) => {
  const formData = new FormData();
  // Remove constants from this, add pagination in proper or make it global constants
  formData.append(
    'data',
    JSON.stringify({ psize: 500, pnum: 1, make, year, model }),
  );
  const response = await fetch(`${apiEndpoint}/get_vehicles.php`, {
    method: 'POST',
    body: formData,
  });

  const res = await response.json();
  if (!model) {
    // Vehicle Details for Model and Type
    let VehicleModelAndType: VehicleModelData | null = res?.data || null;
    return {
      VehicleModelAndType,
    };
  }
  // Vehicle Details for TRIM and other details
  let VehicleModelAndType: VehicleResponse | null = res?.data || null;
  return {
    vehicleDetails: VehicleModelAndType,
  };
};

export function useFetchVehicleTypeByYearAndMake(
  param: VehicleTypeByYearAndMakeParams,
) {
  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: ['vehicle-type-by-year-and-model', `${param.make}-${param.year}`],
    queryFn: () => fetchVehicleTypeByYearAndMake(param),
    staleTime: 1000 * 60 * 5,
  });
  let uniqueModelTypes =
    data && 'VehicleModelAndType' in data && data?.VehicleModelAndType?.Models
      ? Array.from(
          new Set(data.VehicleModelAndType.Models.map((model) => model.Type)),
        )
      : [];
  return {
    vehicleModel: data?.VehicleModelAndType,
    vehicleType: uniqueModelTypes,
    isLoading,
    isError,
    refetch,
  };
}

export function useFetchVehicleTrimByYearModelAndMake(
  param: VehicleTypeByYearAndMakeParams & { model: string },
) {
  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: [
      'vehicle-trim-by-year-model-make',
      `${param.make}-${param.year}-${param.model}`,
    ],
    queryFn: () => fetchVehicleTypeByYearAndMake(param),
    staleTime: 1000 * 60 * 5,
  });

  return {
    vehicleDetails: data?.vehicleDetails,
    isLoading,
    isError,
    refetch,
  };
}
export const fetchVehicleFigment = async ({
  colorId,

  id,
}: {
  id: string | number;
  colorId: string | number;
}) => {
  const formData = new FormData();
  // Remove constants from this, add pagination in proper or make it global constants
  // {"id":"382025","color_id":"1710255"}
  // , color_id: colorId
  formData.append('data', JSON.stringify({ id, color_id: colorId }));
  const response = await fetch(`${apiEndpoint}/get_fitments.php`, {
    method: 'POST',
    body: formData,
  });

  const res = await response.json();
  // Vehicle Details for TRIM and other details
  console.log(res, '=figmentREsponse');
  let vehicleFigment: FitmentDataResponse | null = res?.data || null;
  return {
    vehicleFigment,
  };
};

export function useFetchVehicleFigment(param: {
  id: string | number;
  colorId: string | number;
}) {
  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: [
      'vehicle-trim-by-year-model-make',
      `${param.id}-${param.colorId}`,
    ],
    queryFn: () => fetchVehicleFigment(param),
    staleTime: 1000 * 60 * 5,
  });

  return {
    vehicleDetails: data?.vehicleFigment,
    isLoading,
    isError,
    refetch,
  };
}
