import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { WheelStyleDetail } from '../lib/types';
import
{
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { productCatalogPath } from '@/lib/utils';
import DropdownMenuCapSelector from './wheel-configurator/dropdown-menu-cap-selector';

const ProductDetailSizeSelector: React.FC<{ items: WheelStyleDetail[], navigationPathRoot?: string }> = ({ items, navigationPathRoot=productCatalogPath }) =>
{
  const navigate = useNavigate();
  const location = useLocation();
  const { styleId, wheelId } = useParams<{ styleId: string, wheelId: string }>();

  const [selectedBoltPattern, setSelectedBoltPattern] = useState<string | undefined>(undefined);
  const [selectedWheelSize, setSelectedWheelSize] = useState<string | undefined>(undefined);
  const [selectedOffsetAndLipSize, setSelectedOffsetAndLipSize] = useState<string | undefined>(undefined);

  //when styleId changes, clear the selected values
  useEffect(() =>
  {
    setSelectedBoltPattern(undefined);
    setSelectedBoltPatternKey(`selectedBoltPatternKey_${new Date()}`);
  }, [styleId]);

  //try to set selected options by wheelId if it is set
  useEffect(() =>
  {
    if (items)
    {
      setSelectedBoltPattern(getDisplayKey(createBoltPatternDisplayKey, wheelId, items));
      setSelectedWheelSize(getDisplayKey(createSizeDisplayKey, wheelId, items));
      setSelectedOffsetAndLipSize(wheelId || undefined);
    }
  }, [items, wheelId]);

  //try to set selected options by wheelId if it is set
  useEffect(() =>
  {
    // console.log(`useEffect offsetAndLipDisplays`, offsetAndLipDisplays);
    let keys = Object.keys(offsetAndLipDisplays);
    if (keys.length == 1)
    {
      let wheelId = offsetAndLipDisplays[keys[0]][0].Id.toString()

      navigate(`${navigationPathRoot}/${styleId}/${wheelId}${location.search}`);
    }
  }, [selectedWheelSize]);



  //create keys for reseting radix component Select 
  const [selectedBoltPatternKey, setSelectedBoltPatternKey] = useState<string>(`selectedBoltPatternKey_${new Date()}`)
  const [selectedWheelSizeKey, setSelectedWheelSizeKey] = useState<string>(`selectedWheelSizeKey_${new Date()}`)
  const [selectedOffsetAndLipSizeKey, setSelectedOffsetAndLipSizeKey] = useState<string>(`selectedOffsetAndLipSizeKey_${+new Date()}`)

  //reset all other options
  const handleChangeBoltPattern = (value: string) =>
  {
    setSelectedBoltPattern(value);
    setSelectedWheelSize(undefined);
    setSelectedWheelSizeKey(`selectedWheelSizeKey_${new Date()}`);
    setSelectedOffsetAndLipSize(undefined);
    setSelectedOffsetAndLipSizeKey(`selectedOffsetAndLipSizeKey_${+new Date()}`);
  };

  //reset offset / lip size
  const handleChangeSize = (value: string) =>
  {
    setSelectedWheelSize(value);
    setSelectedOffsetAndLipSize(undefined);
    setSelectedOffsetAndLipSizeKey(`selectedOffsetAndLipSizeKey_${+new Date()}`);
    navigate(`${navigationPathRoot}/${styleId}/${wheelId}${location.search}`);
  };

  //set url
  const handleChangeOffsetAndLipSize = (wheelId: string) =>
  {
    setSelectedOffsetAndLipSize(wheelId);
    navigate(`${navigationPathRoot}/${styleId}/${wheelId}${location.search}`);
  };

  const boltPatternDisplays: Record<string, WheelStyleDetail[]> = {};
  items.forEach((productDetail) =>
  {
    let displayKey = createBoltPatternDisplayKey(productDetail);
    if (boltPatternDisplays[displayKey])
    {
      boltPatternDisplays[displayKey].push(productDetail);
    } else
    {
      boltPatternDisplays[displayKey] = [productDetail];
    }
  });

  const sizeDisplays: Record<string, WheelStyleDetail[]> = {};
  if (selectedBoltPattern && boltPatternDisplays[selectedBoltPattern])
  {
    boltPatternDisplays[selectedBoltPattern].forEach((productDetail) =>
    {
      let displayKey = createSizeDisplayKey(productDetail);
      if (sizeDisplays[displayKey])
      {
        sizeDisplays[displayKey].push(productDetail);
      } else
      {
        sizeDisplays[displayKey] = [productDetail];
      }
    });
  }

  const offsetAndLipDisplays: Record<string, WheelStyleDetail[]> = {};
  if (selectedWheelSize && sizeDisplays[selectedWheelSize]) {
    sizeDisplays[selectedWheelSize].forEach((productDetail) => {
      let displayKey = createOffsetAndLipDisplayKey(productDetail);
      if (offsetAndLipDisplays[displayKey]) {
        offsetAndLipDisplays[displayKey].push(productDetail);
      } else {
        offsetAndLipDisplays[displayKey] = [productDetail];
      }
    });
  }

  const capOptionsDisplays = [
    { name: "Standard center cap", value: "standard_centered" },
    { name: "Floating Cap", value: "floating" },
    { name: "Chrome Cap", value: "chrome" },
    { name: "Machined Cap", value: "machined" },
  ];


  const triggerClass = "min-w-[200px] h-8 p-1 border-primary text-primary dark:text-foreground dark:border-foreground text-sm px-2 mb-2";


  // console.log(`SELECTED`, selectedBoltPattern, selectedWheelSize);
  // console.log(`offsetAndLipDisplays`, offsetAndLipDisplays);
  
  

  return (
    <div className="flex flex-col">
      <div className={`text-sm pb-1 font-light`}>Bolt Pattern</div>
      <Select
        onValueChange={handleChangeBoltPattern}
        value={selectedBoltPattern}
        key={selectedBoltPatternKey}
      >
        <SelectTrigger className={triggerClass}>
          <SelectValue placeholder="Select Bolt Pattern" />
        </SelectTrigger>
        <SelectContent className=" z-[999999999999]">
          {Object.keys(boltPatternDisplays).map((displayKey) => (
            <SelectItem key={displayKey} value={displayKey}>
              {displayKey}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>

      {selectedBoltPattern && (
        <>
          {Object.keys(sizeDisplays).length > 1 ? (
            <>
              <div className={`text-sm pb-1 pt-2 font-light`}>Size</div>
              <Select
                onValueChange={handleChangeSize}
                value={selectedWheelSize}
                key={selectedWheelSizeKey}
              >
                <SelectTrigger className={triggerClass}>
                  <SelectValue placeholder="Select Size" />
                </SelectTrigger>
                <SelectContent className=" z-[999999999999]">
                  {Object.keys(sizeDisplays).map((displayKey) => (
                    <SelectItem key={displayKey} value={displayKey}>
                      {displayKey}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </>
          ) : (
            Object.keys(sizeDisplays)[0] &&
            "Size:" + Object.keys(sizeDisplays)[0]
          )}
        </>
      )}

      {selectedWheelSize && (
        <>
          {Object.keys(offsetAndLipDisplays).length > 1 ? (
            <>
              <div className={`text-sm pb-1 pt-2 font-light`}>
                Offset / Lip Size
              </div>
              <Select
                onValueChange={handleChangeOffsetAndLipSize}
                value={selectedOffsetAndLipSize}
                key={selectedOffsetAndLipSizeKey}
              >
                <SelectTrigger className={triggerClass}>
                  <SelectValue placeholder="Select Offset / Lip Size" />
                </SelectTrigger>
                <SelectContent className=" z-[999999999999]">
                  {Object.keys(offsetAndLipDisplays).map((displayKey) => (
                    <SelectItem
                      key={offsetAndLipDisplays[displayKey][0].Id.toString()}
                      value={offsetAndLipDisplays[displayKey][0].Id.toString()}
                    >
                      {displayKey}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </>
          ) : (
            Object.keys(offsetAndLipDisplays)[0] && (
              <>
                <div className={`text-sm pb-2 pt-2 font-light`}>
                  Offset / Lip Size
                </div>

                <div
                  className={`text-[14px] text-primary p-2 border-1 border-gray-200 rounded-lg `}
                >
                  {Object.keys(offsetAndLipDisplays)[0]}
                </div>
              </>
            )
          )}
        </>
      )}

      {((items[0] && items[0]?.Cap !== null) || true) && (
        <>
          <div className={`text-sm pb-1 pt-4 font-light`}>Cap Option</div>
          <Select>
            <SelectTrigger className={triggerClass}>
              <SelectValue placeholder="Select Cap Option" />
            </SelectTrigger>
            <SelectContent className=" z-[999999999999]">
              {Object.keys(capOptionsDisplays).map(
                (displayKey: any, index: number) => (
                  <SelectItem
                    key={capOptionsDisplays[displayKey].name}
                    value={capOptionsDisplays[displayKey].value}
                  >
                    {capOptionsDisplays[index].name}
                  </SelectItem>
                )
              )}
            </SelectContent>
          </Select>
        </>
      )}
    </div>
  );
};

export default React.memo(ProductDetailSizeSelector);


const createBoltPatternDisplayKey = (productDetail: WheelStyleDetail) =>
{
  return `${productDetail.LugCount} x ${productDetail.BoltCircle1}`;
};

const createSizeDisplayKey = (productDetail: WheelStyleDetail) =>
{
  return `${productDetail.Diameter}" x ${productDetail.Width}"`;
};

const createOffsetAndLipDisplayKey = (productDetail: WheelStyleDetail) =>
{
  let lipSizeDisplay = productDetail.LipSize ? ` / ${productDetail.LipSize}"` : '';
  let offsetDisplay = productDetail.Offset ? `${productDetail.Offset}mm` : '';
  return `${offsetDisplay}${lipSizeDisplay}`;
};

const getDisplayKey = (createDisplayKey: (productDetail: WheelStyleDetail) => string, wheelId: string | undefined, items: WheelStyleDetail[] | undefined) =>
{
  if (!items) return undefined;
  const productDetail = items.find((productDetail) => productDetail.Id.toString() === wheelId);
  return productDetail ? createDisplayKey(productDetail) : undefined;
}

// const resolveAreSpecsAllEqual = (items: any[], specs: any[]) =>
// {
//   const result: { [key: string]: boolean } = {};

//   specs.forEach((spec: any) =>
//   {
//     result[spec] = items.every((item: any) => item[spec] === items[0][spec]);
//   });

//   return result;
// };
// const areSpecsEqual = resolveAreSpecsAllEqual(items, ['Offset', 'Bore', 'BoltCircle1', 'LipSize']);
// if (productDetail?.Id?.toString() === wheelId){console.log(productDetail)}
// let offsetDisplay = productDetail.Offset && !areSpecsEqual.Offset ? ` | Offset ${productDetail.Offset} mm` : '';
// let boreDisplay = ''//productDetail.Bore && !areSpecsEqual.Bore ? ` / Bore ${productDetail.Bore} mm` : '';
// let boltCircleDisplay = productDetail.BoltCircle1 && !areSpecsEqual.BoltCircle1 ? `Bolt Pattern ${productDetail.LugCount}x${productDetail.BoltCircle1}` : '';
// let display = `${productDetail.Diameter}"x${productDetail.Width}"${offsetDisplay}${boreDisplay}${boltCircleDisplay}`;