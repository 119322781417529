// src/hooks/useFindAndSetCurrentBrand.ts

import { Brand } from '@/lib/types';
import { useStore } from '../lib/store';

const useFindAndSetCurrentBrand = () =>
{
  const setCurrentBrand = useStore((state) => state.setCurrentBrand);
  const findAndSetCurrentBrand = ({ brandName, brands, brandId }: { brandName?: string, brands: Record<string, Brand>, brandId?: string }): Brand | undefined =>
  {
    const brandsArray = Object.values(brands);

    if (brandsArray.length === 0) return undefined;

    if (brandId)
    {
      const brandFoundById = brandsArray.find((brand_item: Brand) => brand_item.Id === Number(brandId));
      if (brandFoundById) setCurrentBrand(brandFoundById);
      return brandFoundById;

    } else if (brandName && brandsArray.length > 0)
    {
      const brandFoundByName = brands[brandName];
      if (brandFoundByName) setCurrentBrand(brandFoundByName);
      return brandFoundByName;
    }
    
    return undefined
  };
  return { findAndSetCurrentBrand };
};

const useBrandIdsToBrandNames = () =>
{
  const brandIdsToBrandNames = ({ brands, brandIds }: { brands: Record<string, Brand>, brandIds?: string }): string[] | [] =>
  {
    if (brandIds)
    {
      const brandIdsArray = brandIds.split(',');
      const brandNamesArray = Object.keys(brands).filter(brandName =>
        brandIdsArray.includes(brands[brandName].Id.toString())
      );
      return brandNamesArray;
    }
    return [];
  };
  return { brandIdsToBrandNames };
};



export { useFindAndSetCurrentBrand, useBrandIdsToBrandNames };
