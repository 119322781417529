import { zodResolver } from '@hookform/resolvers/zod';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { Label } from '@/components/ui/label';
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group';
import { useToast } from '@/components/ui/use-toast';

import { Button } from '@/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';

import { useStore } from '@/lib/store';
import { WheelStyleDetail } from '@/lib/types';
import { apiEndpoint, cn, configuratorPath } from '@/lib/utils';

const RequestQuoteForm: React.FC<{
  exrtraProps?: any;
  productDetail: WheelStyleDetail | null;
  setParentDialogOpen: (open: boolean) => void;
  quantity: number;
}> = ({ exrtraProps, productDetail, setParentDialogOpen, quantity }) => {
  // console.log('I have been rerendered', quantity);
  const [loading, setLoading] = useState(false);
  const navigationContextRootPath = useStore(
    (state) => state.navigationContextRootPath,
  );
  let isConfiguratorPath = configuratorPath === navigationContextRootPath;

  const { toast } = useToast();

  const formSchema = z.object({
    firstname: z
      .string({ required_error: 'Full Name is required' })
      .min(1, { message: 'First Name must be at least 1 character' })
      .max(50),
    // lastname: z
    //   .string({ required_error: 'Last Name is required' })
    //   .min(1, { message: 'Last Name must be at least 1 character' })
    //   .max(50),

    email: z.coerce
      .string({ required_error: 'Email is required' })
      .email({ message: 'Email is invalid' })
      .min(5, { message: 'Email must be at least 5 characters' }),
    phone: z
      .string({ required_error: 'Phone is required' })
      .min(7, { message: 'Phone must be at least 7 characters' })
      .max(50),

    preferred_contact_method: z.optional(z.string()),
    postal: z
      .string({ required_error: 'Postcode is required' })
      .min(5, { message: 'Postcode must be at least 5 characters' })
      .max(50),
    vehicle_make: z
      .string()
      .min(2, { message: 'Vehicle make should be at least 3 characters long' }),
    vehicle_year: z.string().regex(/^\d{4}$/, {
      message: 'Must be exactly four digits',
    }),
    vehicle_model: z
      .string({ required_error: 'Vehicle model is required' })
      .min(2, { message: 'Model name at least 2 characters' }),
    vehicle_trim: z.string().optional(),
    vehicle_color: z.string().optional(),
    message: z.string(),
    qty: z
      .number({ required_error: 'Quantity must be a number' })
      .min(1, { message: 'Quantity must be greater than 1' }),
  });
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      firstname: '',
      // lastname: '',
      email: '',
      phone: '',
      preferred_contact_method: '',
      postal: '',
      qty: quantity,
      message: '',
    },
  });

  useEffect(() => {
    form.setValue('qty', quantity);
  }, [quantity]);

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    setLoading(true);
    let customize_info = {};
    let data = {
      ...values,
      wheel_info: JSON.stringify(productDetail),
      customize_info: JSON.stringify(customize_info || {}),
      qty: values.qty || 1,
    };
    console.log(`data: ${JSON.stringify(data)}`);
    const formData = new FormData();
    formData.append('data', JSON.stringify(data));

    const response = await fetch(`${apiEndpoint}/_send_request.php`, {
      method: 'POST',
      body: formData,
    });

    let responseJson = await response.json();
    console.log(`responseJson: ${JSON.stringify(responseJson)}`);
    if (responseJson.success === '1') {
      toast({
        description: 'Successfully sent quote request!',
      });
      setParentDialogOpen(false);
    } else {
      toast({
        description: 'Error sending quote request. Please try again.',
        variant: 'destructive',
      });
    }
    setLoading(false);
  };
  const formInputClassName = `bg-white border-1 border-gray-300`;
  const formInputErrorClassName = `bg-white border-1 border-red-500 focus:ring-red-500`;
  const [showMessage, setShowMessage] = useState(false);
  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4 mt-6">
        <div className={`grid x_grid-cols-2 gap-2`}>
          <Label htmlFor="firstname" className={`cursor-pointer`}>
            Full name
          </Label>
          <FormField
            control={form.control}
            name="firstname"
            render={({ field }: any) => (
              <FormItem>
                <FormControl>
                  <Input
                    className={
                      form.formState.errors.firstname
                        ? formInputErrorClassName
                        : formInputClassName
                    }
                    strplaceholder=""
                    {...field}
                    value={field.value ?? ''}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          {/* <FormField
            control={form.control}
            name="lastname"
            render={({ field }: any) => (
              <FormItem>
                <FormControl>
                  <Input className={form.formState.errors.lastname ? formInputErrorClassName : formInputClassName} strplaceholder="" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          /> */}
        </div>

        <FormField
          control={form.control}
          name="email"
          render={({ field }: any) => (
            <FormItem>
              <Label htmlFor="email" className={`cursor-pointer`}>
                Email
              </Label>
              <FormControl>
                <Input
                  className={
                    form.formState.errors.email
                      ? formInputErrorClassName
                      : formInputClassName
                  }
                  strplaceholder=""
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="phone"
          render={({ field }: any) => (
            <FormItem>
              <Label htmlFor="phone" className={`cursor-pointer`}>
                Phone number
              </Label>
              <FormControl>
                <Input
                  className={
                    form.formState.errors.phone
                      ? formInputErrorClassName
                      : formInputClassName
                  }
                  strplaceholder=""
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <div className={`grid grid-cols-[33%,1fr] gap-2`}>
          <FormField
            control={form.control}
            name="postal"
            render={({ field }: any) => (
              <FormItem>
                <Label htmlFor="postal" className={`cursor-pointer`}>
                  Postcode
                </Label>
                <FormControl>
                  <Input
                    className={
                      form.formState.errors.postal
                        ? formInputErrorClassName
                        : formInputClassName
                    }
                    strplaceholder=""
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="preferred_contact_method"
            render={({ field }: any) => (
              <FormItem>
                <Label
                  htmlFor="preferred_contact_method"
                  className={`cursor-pointer`}
                >
                  Preferred contact method
                </Label>
                <FormControl>
                  <RadioGroup
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                  >
                    <div className={` `}>
                      <div
                        className={`grid grid-cols-3 gap-1 text-center items-center justify-start ml-0`}
                      >
                        <Label
                          htmlFor="EMAIL"
                          className={cn(
                            'cursor-pointer flex items-center  border p-2 rounded-md h-[38px] flex-col justify-center',
                            field.value === 'EMAIL' &&
                              'bg-primary text-primary-foreground',
                          )}
                        >
                          <RadioGroupItem
                            value="EMAIL"
                            id="EMAIL"
                            className={`hidden`}
                          />
                          Email
                        </Label>

                        <Label
                          htmlFor="TEXT"
                          className={cn(
                            'cursor-pointer flex items-center  border p-2 rounded-md h-[38px] flex-col justify-center',
                            field.value === 'TEXT' &&
                              'bg-primary text-primary-foreground',
                          )}
                        >
                          <RadioGroupItem
                            value="TEXT"
                            id="TEXT"
                            className={`hidden`}
                          />
                          Text
                        </Label>

                        <Label
                          htmlFor="CALL"
                          className={cn(
                            'cursor-pointer flex items-center  border p-2 rounded-md h-[38px] flex-col justify-center',
                            field.value === 'CALL' &&
                              'bg-primary text-primary-foreground',
                          )}
                        >
                          <RadioGroupItem
                            value="CALL"
                            id="CALL"
                            className={`hidden`}
                          />
                          Call
                        </Label>
                      </div>
                    </div>
                  </RadioGroup>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="vehicle_year"
            render={({ field }: any) => (
              <FormItem className="">
                <Label htmlFor="vehicle_year" className={`cursor-pointer `}>
                  Vehicle Year
                </Label>
                <FormControl>
                  <Input
                    className={
                      form.formState.errors.vehicle_year
                        ? formInputErrorClassName
                        : formInputClassName
                    }
                    strplaceholder=""
                    {...field}
                    value={field.value ?? ''}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="vehicle_make"
            render={({ field }: any) => (
              <FormItem>
                <Label htmlFor="vehicle_make" className={`cursor-pointer`}>
                  Vehicle Make
                </Label>
                <FormControl>
                  <Input
                    className={
                      form.formState.errors.vehicle_make
                        ? formInputErrorClassName
                        : formInputClassName
                    }
                    strplaceholder=""
                    {...field}
                    value={field.value ?? ''}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="vehicle_model"
            render={({ field }: any) => (
              <FormItem>
                <Label htmlFor="vehicle_model" className={`cursor-pointer`}>
                  Vehicle Model
                </Label>
                <FormControl>
                  <Input
                    className={
                      form.formState.errors.vehicle_model
                        ? formInputErrorClassName
                        : formInputClassName
                    }
                    strplaceholder=""
                    {...field}
                    value={field.value ?? ''}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="vehicle_trim"
            render={({ field }: any) => (
              <FormItem>
                <Label htmlFor="vehicle_trim" className={`cursor-pointer`}>
                  Vehicle Trim
                </Label>
                <FormControl>
                  <Input
                    className={
                      form.formState.errors.vehicle_trim
                        ? formInputErrorClassName
                        : formInputClassName
                    }
                    strplaceholder=""
                    {...field}
                    value={field.value ?? ''}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        {/* <FormField
          control={form.control}
          name="qty"
          render={({ field }: any) => (
            <FormItem>
              <FormControl>
                <Input type="number" className={form.formState.errors.qty ? formInputErrorClassName : formInputClassName} strplaceholder="" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        /> */}
        {isConfiguratorPath ? (
          <>
            <FormField
              control={form.control}
              name="vehicle_color"
              render={({ field }: any) => (
                <FormItem>
                  <Label htmlFor="vehicle_color" className={`cursor-pointer`}>
                    If color-matching, vehicle paint color and code:
                  </Label>
                  <FormControl>
                    <Input
                      className={
                        form.formState.errors.vehicle_color
                          ? formInputErrorClassName
                          : formInputClassName
                      }
                      strplaceholder=""
                      {...field}
                      value={field.value ?? ''}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </>
        ) : null}
        {!showMessage && (
          <div
            className={`h-6  underline font-bold`}
            onClick={() => setShowMessage(true)}
          >
            Add message
          </div>
        )}
        {showMessage && (
          <FormField
            control={form.control}
            name="message"
            render={({ field }: any) => (
              <FormItem>
                <Label htmlFor="message" className={`cursor-pointer`}>
                  Message
                </Label>
                <FormControl>
                  <Textarea
                    className={
                      form.formState.errors.message
                        ? formInputErrorClassName
                        : formInputClassName
                    }
                    strplaceholder=""
                    {...field}
                    value={field.value ?? ''}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        )}
        <div className={`flex flex-col items-center`}>
          <Button
            type="submit"
            loading={loading}
            className={`bg-black w-full rounded-lg`}
          >
            Send
          </Button>
        </div>
      </form>
    </Form>
  );
};

export default RequestQuoteForm;
