// autoviz-component-monorepo/packages/product-catalog/src/components/product-list.tsx

import { debounce } from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { useStore } from "../lib/store";
import Fader from './fader';
import ScrollAreaDynamic from "./scroll-area-dynamic";
import WheelCard from "./wheel-card";

const ProductList: React.FC<any> = ({
  loadNextPage,
  handleNavigation,
  classNameObject,
}) => {
  const products = useStore((state) => state.products);
  // const curPage = useStore((state) => state.curPage);
  const moreItems = useStore((state) => state.moreItems);
 const isThreeColumnView = useStore((state) => state.isThreeColumnView);
 const brandLogosUrlBase = useStore((state) => state.brandLogosUrlBase);
 const currentBrand = useStore((state) => state.currentBrand);
 const listDisplayMode = useStore((state) => state.listDisplayMode);
 const productsLoading = useStore((state) => state.productsLoading);
 const productsLoadingTrigger = useStore(
   (state) => state.productsLoadingTrigger,
 );
 const appIntialized = useStore((state) => state.appInitialized);
 // console.log(`rerednder product-list`)

 // const { productId } = useParams<{ productId: string }>();
 const location = useLocation();
 const searchParams = new URLSearchParams(location.search);
 const queryString = searchParams.toString();

 const scrollAreaDynamicContainerRef = useRef<HTMLDivElement | null>(null);
 const [showScrollToTop, setShowScrollToTop] = useState(false);

 const isLoadingNextPageRef = useRef(false); // Local ref to track loading status
 const handleScrollEvent = async () => {
   const scrollElement = document.getElementById('scroll-area-viewport-target');
   if (scrollElement) {
     setShowScrollToTop(scrollElement.scrollTop > 200);

     const threshold = scrollElement.scrollHeight * 0.5; // 50% of the total scroll height
     // console.log({ isLoadingNextPageRef: isLoadingNextPageRef.current, moreItems, margin: scrollElement.scrollTop + scrollElement.clientHeight , threshold , productsLoadingTrigger })
     if (
       !isLoadingNextPageRef.current &&
       moreItems &&
       scrollElement.scrollTop + scrollElement.clientHeight >= threshold
     ) {
       console.log(`Loading more products...`);
       await loadNextPage();
       isLoadingNextPageRef.current = false;
     }
   }
 };

 const debouncedScrollEvent = useCallback(debounce(handleScrollEvent, 300), [
   handleScrollEvent,
 ]);
 useEffect(() => {
   const scrollElement = document.getElementById('scroll-area-viewport-target');
   if (scrollElement) {
     scrollElement.addEventListener('scroll', handleScrollEvent);
   }

   return () => {
     if (scrollElement) {
       scrollElement.removeEventListener('scroll', handleScrollEvent);
     }
     debouncedScrollEvent.cancel();
   };
 }, [debouncedScrollEvent]);

 return (
   <div className={`relative  overflow-hidden   -mr-4 w-full`}>
     <ScrollAreaDynamic
       className={`relative pr-4 overflow-hidden w-full`}
       type="always"
       ref={scrollAreaDynamicContainerRef}
     >
       <Fader
         isVisible={productsLoading && productsLoadingTrigger !== 'scroll'}
         duration={300}
         wrapperClassName=""
         zIndex={99999999999999}
         rest={100}
       >
         <div
           className={`fixed  top-0 left-0 w-full h-full z-[99999999999999] flex items-center justify-center pointer-events-none bg-white/70 dark:bg-black/70 pointer-events-none`}
         >
           {/* <Loader className={`h-[20%] w-[20%] dark:opacity-70`} /> */}
         </div>
       </Fader>

       {/* <div className={`bg-gradient-to-t from-transparent to-background absolute top-[0px] left-0 right-0 h-[20px] z-[9]`}></div> */}
       {/* grid-cols-[repeat(auto-fit,minmax(130px,1fr))] md:grid-cols-[repeat(auto-fit,minmax(280px,1fr))] 
        grid-cols-2 md:grid-cols-3 xl:grid-cols-4
        */}
       <div
         className={twMerge(
           ' relative z-0 grid grid-cols-2 sm:grid-cols-[repeat(auto-fit,minmax(200px,1fr))] lg:grid-cols-5 gap-4 pb-2 w-full',
           !isThreeColumnView ? '!grid-cols-2' : '',
           listDisplayMode === 'LIST' && '!grid-cols-1',
           classNameObject?.ProductList,
         )}
       >
         {Array.isArray(products) &&
           products.map((product, index) => {
             // if (product?.wheel_info?.WheelStyles?.[0])
             // {
             //   let product_copy = { ...product }
             //   product = product.wheel_info.WheelStyles[0]
             //   // delete (product_copy.wheel_info)
             //   product.data = product_copy
             // }
             return (
               <WheelCard
                 handleNavigation={handleNavigation}
                 product={product}
                 key={`${product?.Id}-${index}`}
                 index={index}
                 listDisplayMode={listDisplayMode}
                 currentBrand={currentBrand}
                 brandLogosUrlBase={brandLogosUrlBase}
                 // productId={productId}
                 queryString={queryString}
               />
             );
           })}

         {Array.isArray(products) &&
           products?.length == 0 &&
           !productsLoading &&
           appIntialized && (
             <div
               className={`text-center text-muted-foreground text-xl text-center col-span-full h-full w-full flex flex-col items-center justify-center`}
             >
               No Results Found
             </div>
           )}

         {/* <div className={`bg-gradient-to-b from-transparent to-background fixed bottom-[60px] left-0 right-5 h-[20px] z-[999]`}></div> */}

         {/* <div className={`fixed bottom-[0px] h-[60px] left-0 right-0 bg-[var(--background)] grid grid-cols-[25%,1fr,25%] items-center max-w-screen-xl mx-auto px-6`}>

            <Button style={{ opacity: showScrollToTop ? 1 : 0 }} variant={"outline"} size="sm"
              className={`transition-opacity duration-300 ease-in-out z-[999] inline-flex justify-self-start`} onClick={() => scrollToTop()}>
              <ChevronsUpIcon className="h-4 w-4" /> <span className="text-sm hidden sm:block">Back to Top</span>
            </Button>

            <div className={`p-2 flex flex-row items-center justify-center bg-[var(--card)] rounded-lg h-6 max-w-[400px] mx-auto whitespace-nowrap`}>
              {products?.length || 0} Results Loaded
            </div>

            <div className={`p-2 flex flex-col justify-self-end items-end mt-1`}>

              {moreItems ?
                <Fader isVisible={productsLoading} duration={300} rest={300}>
                  <div
                    className={`text-primary dark:text-foreground grid grid-cols-[auto,auto] gap-1`} >
                    <div className="text-sm leading-none text-right flex flex-col items-center justify-center">Loading More</div>
                    <div className=""><Loader /></div>
                  </div></Fader> :
                !productsLoading && productsLoadingTrigger === "scroll" &&
                <div className={`text-muted-foreground transition-opacity duration-300 ease-in-out grid grid-cols-[auto,auto] gap-1`} >
                  <div className="text-sm leading-none text-right flex flex-col items-center justify-center">No More Results</div>
                </div>
              }
            </div>

          </div> */}
       </div>
     </ScrollAreaDynamic>
   </div>
 );
};

export default React.memo(ProductList);
