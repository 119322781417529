// autoviz-component-monorepo/packages/product-catalog/src/components/wheel-configurator/parts-selector.tsx

import { Button } from '@/components/ui/button';
import {
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { useModelViewer } from '@/hooks/useModelViewer';
import { cn } from '@/lib/utils';
import { DropdownMenu } from '@radix-ui/react-dropdown-menu';
import { Download, EyeIcon, EyeOffIcon, PaletteIcon, X } from 'lucide-react';
import { useState } from 'react';
import { toast } from '../ui/use-toast';
import Swatch, { SwatchMerge } from './swatch';
import ToggleCompareToOriginal from './toggle-compare-to-original';
import {
  colors,
  finishAllowed,
  finishes,
  getMaterialByName,
  getSwatchByName,
  maxRecentColors,
  maxSwatchesRows,
  resolveSelectedTextureName,
  swatchesPerRow,
  SwatchType,
  updateAlphaValue,
} from './wheel-configurator-helpers';

type NavPartsSelectorProps = {
  // modelViewerRef: React.RefObject<HTMLDivElement>;
  navPartsSelectorRef: React.RefObject<HTMLDivElement>;
  navPartsSwatchesPanelRef: React.RefObject<HTMLDivElement>;
  navOpen: boolean;
  setNavOpen: React.Dispatch<React.SetStateAction<boolean>>;
};
const NavPartsSelector: React.FC<NavPartsSelectorProps> = ({
  // modelViewerRef,
  navPartsSelectorRef,
  navPartsSwatchesPanelRef,
  setNavOpen,
  navOpen,
}) => {
  const {
    dataObject,
    currentPartName,
    setCurrentPartName,
    applySwatchToModelViewer,
    fullyInitialized,
  } = useModelViewer();

  ////////////////////////////////////////////////////////////////////////////////////
  //menu state
  const handleMenuToggle = (partName: string) => {
    console.log(`handleMenuToggle`, partName, currentPartName);
    if (currentPartName !== partName) {
      setCurrentPartName(partName);
    }
    const settingNavOpen =
      currentPartName === partName && navOpen ? false : true;
    setNavOpen(settingNavOpen);
  };

  ////////////////////////////////////////////////////////////////////////////////////
  //color state
  const [showingAllRecentColors, setShowingAllRecentColors] = useState(false);
  const [showingAllAvailableColors, setShowingAllAvailableColors] =
    useState(false);
  const [recentColors, setRecentColors] = useState<SwatchType[]>([]);
  const [curColorName, setCurColorName] = useState<null | string>(null);
  const handleOnClickColorSwatch = (colorName: string) => {
    // console.log(`handleOnClickColorSwatch`, colorName);
    setCurColorName(colorName);
    updateRecentColors(colorName);
    setFinishEnabled(finishAllowed(colorName));
    const swatch = getSwatchByName(colors, colorName);
    if (swatch) {
      applySwatchToModelViewer({
        property: 'color',
        swatch,
        partName: currentPartName,
      });
    }
  };

  ////////////////////////////////////////////////////////////////////////////////////
  //finish state
  const [curFinishName, setCurFinishName] = useState<null | string>(null);
  const [finishEnabled, setFinishEnabled] = useState(true);
  const handleOnClickFinishSwatch = (finishName: string) => {
    setCurFinishName(finishName);
    const swatch = getSwatchByName(finishes, finishName);
    // console.log(`handleOnClickFinishSwatch`, swatch);
    if (swatch) {
      applySwatchToModelViewer({
        property: 'finish',
        swatch,
        partName: currentPartName,
      });
    }
  };

  const updateRecentColors = (colorName: string) => {
    let newRecentColors = [...recentColors];
    newRecentColors = newRecentColors.filter(
      (color) => color.name !== colorName,
    );
    let color: any = colors.find((color) => color.name == colorName);
    if (!color) return;
    newRecentColors.unshift(color);
    newRecentColors = newRecentColors.slice(0, maxRecentColors);
    setRecentColors(newRecentColors);
  };

  const [showingColorMatch, setShowingColorMatch] = useState(false);
  const [showingColorPicker, setShowingColorPicker] = useState(false);

  const handleClickColorMatch = () => {
    toast({
      title: 'Color match not implemented',
      variant: 'destructive',
    });
    setShowingColorMatch(true);
  };

  const handleClickColorPicker = () => {
    toast({
      title: 'Color picker not implemented',
      variant: 'destructive',
    });
    setShowingColorPicker(true);
  };

  return (
    <>
      {dataObject && (
        <DropdownMenu open={navOpen} modal={false}>
          <div
            className={cn(
              `bg-[#F5F5F5ee] dark:bg-[#202020ee] w-full min-w-[230px] rounded-2xl overflow-hidden self-start transition-all duration-1000`,
              navOpen && 'rounded-r-none',
              fullyInitialized ? 'opacity-100' : 'opacity-0',
            )}
          >
            <DropdownMenuTrigger
              className={`h-auto max-h-[calc(100vh-280px)] overflow-y-scroll w-full`}
            >
              <div ref={navPartsSelectorRef} className="h-0 w-full" />
              {dataObject &&
                Object.keys(dataObject)
                  .sort()
                  .map((partName, index) => (
                    <div
                      onClick={() => handleMenuToggle(partName)}
                      key={partName}
                      className={cn(
                        `relative min-h-[75px] p-4 pb-2 text-left w-full border-b-1 border-transparent cursor-pointer `,
                        currentPartName === partName &&
                          navOpen &&
                          'bg-background dark:bg-[#141414cc] dark:border-[#343434] border-border ',
                        index != 0 && 'border-t-1',
                      )}
                    >
                      <div className={`pb-3 font-kanit text-lg relative`}>
                        {dataObject[partName].displayName}
                        {partName === 'brake' && (
                          <div className={`absolute top-[-5px] left-[60px]`}>
                            <ToggleBrakeVisible />
                          </div>
                        )}
                      </div>
                      <div className={`text-[14px] dark:text-white`}>
                        {resolveSelectedTextureName({
                          partsDataModel: dataObject,
                          partName,
                        })}
                      </div>
                      <div className={`absolute top-3 right-4 `}>
                        <SwatchMerge part={dataObject[partName]} />
                      </div>
                    </div>
                  ))}
            </DropdownMenuTrigger>
            <div
              className={`flex flex-col items-center justify-center pl-0 p-4 border-t-1 border-gray-300 dark:border-gray-700`}
            >
              <ToggleCompareToOriginal />
            </div>
          </div>

          <DropdownMenuContent
            ref={navPartsSwatchesPanelRef}
            onInteractOutside={(e: any) => e.preventDefault()}
            className={`p-0 top-0 rounded-2xl drop-shadow-[13px,12px,5px,#000000] z-[30] select-none
          bg-[#ffffffee] dark:bg-[#141414dd] backdrop-blur-md min-w-[300px] border-none overflow-hidden ${
            navOpen && 'rounded-tl-none '
          }`}
            sticky={'always'}
            align={'start'}
            alignOffset={0}
            side="right"
            sideOffset={0}
            avoidCollisions={true}
          >
            <div className={`min-w-[320px] relative`}>
              <div
                className={`p-4 text-xl grid grid-cols-[auto,1fr,1fr,auto] gap-4 flex flex-row items-center justify-center`}
              >
                <div className={`text-xl font-kanit pr-7 `}>Color</div>

                {/* <Button 60px
              size={"xs"}
              onClick={() => resetAll()}
              variant={"muted"}
              className={` place-self-center self-center  w-6 h-6 p-1`}
            >
              <RotateCcw strokeWidth={1.1} />
            </Button> */}

                <Button
                  onClick={() => handleClickColorMatch()}
                  variant={'secondary'}
                  className={` border-gray-300 p-2`}
                >
                  <div
                    className={`grid grid-cols-[20px,1fr] gap-2 flex flex-col items-center justify-center`}
                  >
                    <Download strokeWidth={1.1} className={` h-5 w-5`} />
                    <div className={`overflow-hidden text-sm`}>Color match</div>
                  </div>
                </Button>

                <Button
                  onClick={() => handleClickColorPicker()}
                  variant={'secondary'}
                  className={`border-1 border-gray-300 p-2`}
                >
                  <div
                    className={`grid grid-cols-[20px,1fr] gap-2 flex flex-col items-center justify-center`}
                  >
                    <PaletteIcon strokeWidth={1.1} className={` h-5 w-5`} />
                    <div className={`overflow-hidden text-sm`}>
                      Color picker
                    </div>
                  </div>
                </Button>

                <Button
                  size={'xs'}
                  onClick={() => setNavOpen(false)}
                  variant={'muted'}
                  className={` place-self-end self-center  w-6 h-6 p-1`}
                >
                  <X strokeWidth={1.1} />
                </Button>
              </div>

              <div className={`p-4 pt-1`}>
                {recentColors.length > 0 && (
                  <div className={`pb-5`}>
                    <div className={`pb-2 text-lg`}>Recent</div>
                    <div
                      className={`grid gap-2`}
                      style={{
                        gridTemplateColumns: `repeat(${swatchesPerRow},1fr)`,
                      }}
                    >
                      {recentColors.map((color, index) => {
                        if (index >= swatchesPerRow && !showingAllRecentColors)
                          return null;
                        return (
                          <Swatch
                            key={index}
                            swatch={color}
                            curName={curColorName}
                            onClick={handleOnClickColorSwatch}
                          />
                        );
                      })}
                    </div>

                    {recentColors.length > swatchesPerRow &&
                      !showingAllRecentColors && (
                        <div
                          className={`pt-3 underline font-bold cursor-pointer`}
                          onClick={() => setShowingAllRecentColors(true)}
                        >
                          Show more
                        </div>
                      )}
                  </div>
                )}

                <div className={`pt-2 pb-2 text-lg`}>All colors</div>
                <div
                  className={`grid gap-2`}
                  style={{
                    gridTemplateColumns: `repeat(${swatchesPerRow},1fr)`,
                  }}
                >
                  {colors.map((color, index) => (
                    <Swatch
                      key={index}
                      swatch={color}
                      curName={curColorName}
                      onClick={handleOnClickColorSwatch}
                    />
                  ))}
                </div>
                {colors.length > swatchesPerRow * maxSwatchesRows &&
                  !showingAllAvailableColors && (
                    <div
                      className={`pt-3 underline font-bold cursor-pointer`}
                      onClick={() => setShowingAllAvailableColors(true)}
                    >
                      Show more
                    </div>
                  )}
              </div>

              <div className={`border-t-1 w-full dark:border-gray-700`}></div>

              <div className={`p-4 text-xl `}>
                <div className={`font-kanit text-xl pb-4`}>Finish</div>

                <div
                  className={`grid gap-2 ${!finishEnabled && 'opacity-50'}`}
                  style={{
                    gridTemplateColumns: `repeat(${swatchesPerRow},1fr)`,
                  }}
                >
                  {finishes.map((finish, index) => (
                    <Swatch
                      key={index}
                      swatch={finish}
                      curName={curFinishName}
                      onClick={handleOnClickFinishSwatch}
                    />
                  ))}
                </div>
              </div>
            </div>
          </DropdownMenuContent>
        </DropdownMenu>
      )}
    </>
  );
};

export default NavPartsSelector;

// const resetAll = () => {
//   // prepSetPartsDataModel({ property: 'finish', swatch: defaultFinish });
//   // prepSetPartsDataModel({ property: 'color', swatch: null });
//   setModelViewer({
//     property: 'color',
//     swatch: null,
//     partName: currentPartName,
//   });
//   setModelViewer({
//     property: 'finish',
//     swatch: null,
//     partName: currentPartName,
//   });
//   // setRecentColors([]);
//   setCurColorName(null);
//   setCurFinishName(null);
//   setShowingColorMatch(false);
//   setShowingColorPicker(false);
//   setShowingAllRecentColors(false);
//   setShowingAllAvailableColors(false);
// };

const ToggleBrakeVisible = () => {
  const [brakeVisible, setBrakeVisible] = useState(true);
  const { getModel } = useModelViewer();
  return (
    <Button
      size={'sm'}
      onClick={(e) => {
        e.stopPropagation();
        setBrakeVisible(!brakeVisible);

        const materials = getModel()?.model?.materials;
        // const material = getMaterialByName(materials, 'BASE');
        let caliper = getModel().model.getMaterialByName('CALIPER');
        let caliper1 = getModel().model.getMaterialByName('CALIPER_1');

        updateAlphaValue(caliper, 0);
        updateAlphaValue(caliper1, 0);
      }}
      variant={'muted'}
      className={` place-self-center self-center  w-7 h-7 p-1`}
    >
      {brakeVisible ? (
        <EyeOffIcon strokeWidth={1.1} size={22} />
      ) : (
        <EyeIcon strokeWidth={1.1} size={22} />
      )}
    </Button>
  );
};