import { useStore } from '@/lib/store';
import { cn } from '@/lib/utils';
import { useEffect, useRef, useState } from 'react';

const LoaderProgress = ({
  loadProgress = 0,
  modelSrc,
}: {
  loadProgress: number;
  modelSrc: string;
}) => {
  const currentModelSrc = useRef(modelSrc);
  const show3DModel = useStore((state) => state.show3DModel);
  const zeroCount = useRef(0);
  const [isLoaderVisible, setIsLoaderVisible] = useState(true);
  const [loaderRendered, setLoaderRendered] = useState(true);

  const loaderStateBump = useStore((state) => state.loaderStateBump);

  const loaderStateBumpRef = useRef(loaderStateBump);
  useEffect(() => {
    if (loaderStateBumpRef.current !== loaderStateBump) {
      resetLoader();
      loaderStateBumpRef.current = loaderStateBump;
    }
  }, [loaderStateBump]);

  const resetLoader = () => {
    console.log(`loader-progress state`, {
      isLoaderVisible,
      loadProgress,
      loaderRendered,
    });
    if (loadProgress < 1) {
      setIsLoaderVisible(true);
      setLoaderRendered(true);
    }
  };

  // useEffect(() => {
  //   console.log(`loadProgress`, loadProgress);
  // }, [loadProgress]);
  // useEffect(() => {
  //   // if (currentModelSrc.current !== modelSrc) {
  //   console.log(`RESET NEW WHEEL`, modelSrc);
  //   setIsLoaderVisible(true);
  //   setLoaderRendered(true);
  //   // }
  //   currentModelSrc.current = modelSrc;
  // }, [modelSrc]);

  // useEffect(() => {
  //   if (loadProgress === 1) {
  //     zeroCount.current++;
  //   }
  //   // console.log(`zeroCount.current`, zeroCount.current);
  // }, [loadProgress]);

  useEffect(() => {
    // if (zeroCount.current >= 1 && loadProgress < 1) {
    // console.log(`/////\n/// loaderProgress`, loadProgress);
    // }
    console.log(`loader-progress.tsx loadProgress prop`, loadProgress);
    if (loadProgress === 1) {
      setIsLoaderVisible(false);
      setTimeout(() => {
        setLoaderRendered(false);
      }, 1000);
    } else {
      setLoaderRendered(true);
      setIsLoaderVisible(true);
    }
  }, [loadProgress]);

  // useEffect(() => {
  //   if (!isLoaderVisible && zeroCount > 1) {
  //     setTimeout(() => {
  //       console.log(`loader reset`);
  //       setLoaderRendered(false);
  //       setIsLoaderVisible(true);
  //       setZeroCount(0);
  //       zeroFound.current = false;
  //     }, 1000);
  //   }
  // }, [isLoaderVisible, zeroCount]);

  // useEffect(() => {
  //   console.log(`loader-progress state`, {
  //     isLoaderVisible,
  //     loadProgress,
  //     loaderRendered,
  //   });
  // }, [isLoaderVisible, loadProgress, loaderRendered]);

  return (
    <>
      {loaderRendered && (
        <div
          className={cn(
            'absolute top-[50%] left-0 right-0 bottom-0 mx-auto w-[50%] h-[20px] flex items-center justify-center bg-[#333333aa] z-[9999999]  rounded-full overflow-hidden drop-shadow-[0px_5px_10px_#00000066] dark:drop-shadow-[0px_5px_10px_#aaaaaa66] border-[.5px] border-r-primary border-b-primary border-r-primary transition-all duration-500',
            isLoaderVisible ? 'opacity-90' : 'opacity-0',
          )}
        >
          <div className="relative w-full h-full flex flex-col items-center justify-center">
            <div className="text-white z-[999] flex flex-col items-center justify-center text-center h-[30px] font-bold text-[10px]">
              {Math.ceil(loadProgress * 100)}%
            </div>
            <div
              className="absolute top-0 left-0 h-full bg-black/70"
              style={{ width: `${loadProgress * 100}%` }}
            ></div>
          </div>
        </div>
      )}
    </>
  );
};

export default LoaderProgress;
