import React from 'react';

export default function MoonIcon() {
  return (
    <svg
      width="18"
      height="18"
      strokeWidth={2}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.2538 11.8855L18.7403 11.5752V11.5752L19.2538 11.8855ZM12.1145 4.74621L11.8042 4.23268L11.8042 4.23268L12.1145 4.74621ZM19.4 12C19.4 16.0869 16.0869 19.4 12 19.4V20.6C16.7497 20.6 20.6 16.7497 20.6 12H19.4ZM12 19.4C7.91309 19.4 4.6 16.0869 4.6 12H3.4C3.4 16.7497 7.25035 20.6 12 20.6V19.4ZM4.6 12C4.6 7.91309 7.91309 4.6 12 4.6V3.4C7.25035 3.4 3.4 7.25035 3.4 12H4.6ZM14.8 13.8C12.2595 13.8 10.2 11.7405 10.2 9.2H9C9 12.4033 11.5967 15 14.8 15V13.8ZM18.7403 11.5752C17.9338 12.9099 16.4705 13.8 14.8 13.8V15C16.9079 15 18.7526 13.8751 19.7673 12.1958L18.7403 11.5752ZM10.2 9.2C10.2 7.52946 11.0901 6.06624 12.4248 5.25974L11.8042 4.23268C10.1249 5.24744 9 7.09207 9 9.2H10.2ZM12 4.6C11.9292 4.6 11.8462 4.56807 11.7859 4.50535C11.7349 4.45221 11.723 4.40195 11.7203 4.38162C11.7169 4.3567 11.7185 4.28446 11.8042 4.23268L12.4248 5.25974C12.8273 5.01652 12.9568 4.57118 12.9095 4.22101C12.8603 3.85701 12.5733 3.4 12 3.4V4.6ZM19.7673 12.1958C19.7155 12.2815 19.6433 12.2831 19.6184 12.2797C19.598 12.277 19.5478 12.2651 19.4947 12.2141C19.4319 12.1538 19.4 12.0708 19.4 12H20.6C20.6 11.4267 20.143 11.1397 19.779 11.0905C19.4288 11.0432 18.9835 11.1727 18.7403 11.5752L19.7673 12.1958Z"
        className='fill-[#191919] dark:fill-[#fff]'
      />
    </svg>
  );
}
