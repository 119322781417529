// autoviz-component-monorepo/packages/product-catalog/src/components/product-catalog.tsx

import { useStore } from '@/lib/store';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Label } from '@/components/ui/label';
import { Switch } from '@/components/ui/switch';


const ToggleCompareToOriginal = () => 
{

  const [catalogMenuOpen, setCatalogMenuOpen] = useState(false);
  const { styleId, wheelId } = useParams<{ styleId: string, wheelId: string }>();
  const currentAutosyncApiWheelStyle = useStore((state) => state.currentAutosyncApiWheelStyle);
  const currentAutosyncApiWheelStyles = useStore((state) => state.currentAutosyncApiWheelStyles);
  const currentAutovizDbWheelInfo = useStore((state) => state.currentAutovizDbWheelInfo);
  const currentAutovizDbWheelModel = useStore((state) => state.currentAutovizDbWheelModel);

  return (
    <div className="flex flex-wrap items-center justify-center cursor-pointer">
      <Switch id="compare-to-original" />
      <Label
        htmlFor="compare-to-original"
        className="text-xs  md:text-sm text-ellipsis whitespace-nowrap flex items-center cursor-pointer text-center overflow-hidden ml-2 h-6"
      >
        Compare to original
      </Label>
    </div>

  )
}

export default React.memo(ToggleCompareToOriginal);