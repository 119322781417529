// src/hooks/useFetchDomainByAkey.ts
import { useCallback } from 'react';
import { useStore } from '../lib/store';
import { apiEndpoint } from '../lib/utils';

const useFetchDomainByAkey = () =>
{
  const setCurrentDomain = useStore((state) => state.setCurrentDomain);

  const fetchDomainByAkey = useCallback(async ({ akey }: { akey: string }) =>
  {
    const formData = new FormData();
    formData.append('data', JSON.stringify({ akey }));

    const response = await fetch(`${apiEndpoint}/_get_domain_by_akey.php`, {
      method: 'POST',
      body: formData,
    });

    const res = await response.json();
    const domainResult = res?.data || null;
    setCurrentDomain(domainResult);
    return domainResult;
  }, [setCurrentDomain]);

  return { fetchDomainByAkey };
};

export default useFetchDomainByAkey;
