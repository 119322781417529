import React from 'react';
import { twMerge } from 'tailwind-merge';




const ToggleButton: React.FC<any> = ({ containerClassName, pivotBoolean, handleToggleFunction, leftContent, rightContent }: { containerClassName: string, pivotBoolean: boolean, handleToggleFunction: any, leftContent: any, rightContent: any }) => 
{
  const classNameBase = "flex items-center gap-1 rounded-lg justify-center px-2 py-0 h-6 text-foreground";
  const classNameActive = "bg-background shadow-sm dark:shadow-white/30 "
  const classNameInactive = "text-muted-foreground bg-muted"

  return (
    <>
      <div onClick={() => handleToggleFunction()}
        className={twMerge(`cursor-pointer grid grid-cols-[auto,auto]  max-w-[180px] rounded-lg p-[1px] w-full font-bold text-sm bg-muted`, containerClassName)}>
        <div className={twMerge(classNameBase, pivotBoolean ? classNameActive : classNameInactive)}>
          {leftContent}
        </div>
        <div className={twMerge(classNameBase, !pivotBoolean ? classNameActive : classNameInactive)}>
          {rightContent}
        </div>
      </div></>
  );
}

export default ToggleButton;