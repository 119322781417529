// autoviz-component-monorepo/packages/product-catalog/src/components/wheel-configurator/wheel-configurator-header.tsx

import BrandDisplayImage from '@/components/brand-display-image';
import QuoteRequest from '@/components/quote-request';
import { Button } from '@/components/ui/button';
import { toast } from '@/components/ui/use-toast';
import useShare from '@/hooks/useShare';
import { ChevronLeftIcon, SaveIcon, Share } from 'lucide-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import ToggleButtonTheme from '../toggle-button-theme';
// import DropdownMenuCatalog from './dropdown-menu-catalog';
import DropdownMenuSizeSelector from './dropdown-menu-size-selector';
import DropdownMenuWheelDesigns from './dropdown-menu-wheel-designs';
import { useModelViewer } from '@/hooks/useModelViewer';
import { useStore } from '@/lib/store';
import { imgSrcFromWheelModel } from '@/lib/utils';

const WheelConfiguratorHeader: React.FC = () => {
  const borderClass = 0 ? `border border-gray-300` : ``;
  const { copyShareUrlToClipboard } = useShare();
  const { saveModelViewerImage } = useModelViewer();

  const showAlert = useStore((state: any) => state.showAlert);
  const alertOpen = useStore((state: any) => state.alertOpen);
  const setAlertCallback = useStore((state: any) => state.setAlertCallback);
  const currentAutovizDbWheelModel = useStore(
    (state) => state.currentAutovizDbWheelModel,
  );

  const navigate = useNavigate();
  return (
    <div
      className={`bg-[#F5F5F5ee] dark:bg-[#191919cc] border-b-1 border-gray-300 dark:border-gray-700 z-[999999]`}
    >
      <div
        className={`max-w-screen-2xl h-full mx-auto grid grid-cols-[auto_1fr_130px] gap-[2%] justify-between items-center px-4 ${borderClass}`}
      >
        <div
          className={`flex flex-col grid grid-cols-[30px,auto,auto] items-center justify-start gap-4 ${borderClass}`}
        >
          <div
            className={`flex flex-col items-center justify-center w-[30px] ${borderClass}`}
          >
            <Button
              variant={'ghost'}
              className={`flex flex-col items-center justify-center ${borderClass}`}
              onClick={() => navigate(-1)}
            >
              <ChevronLeftIcon
                strokeWidth={1.5}
                className={`absolute h-6 w-6`}
              />
            </Button>
          </div>

          <div
            className={`h-[60px] max-w-[130px] overflow-hidden flex justify-start ${borderClass}`}
          >
            <BrandDisplayImage />
          </div>

          <div className={`flex flex-col ${borderClass}`}>
            <DropdownMenuSizeSelector />
          </div>
        </div>

        <div
          className={`grid grid-cols-2 gap-[10%] justify-self-end justify-end items-center place-content-end flex flex-col ${borderClass}`}
        >
          <div
            className={`flex flex-col items-end justify-end ${borderClass} w-[180px]`}
          >
            <DropdownMenuWheelDesigns />
          </div>

          <div
            className={`grid grid-cols-2 gap-3 flex flex-row  justify-end max-w-[100px] ${borderClass}`}
          >
            <Button
              variant={'secondary'}
              className={`!border-1 border-gray-300 rounded-xl !p-4 !px-4 h-7 flex flex-col items-center justify-center`}
              onClick={() => {
                showAlert({
                  context: 'saveDesign',
                  props: {
                    img: imgSrcFromWheelModel(currentAutovizDbWheelModel),
                  },
                  callback: saveModelViewerImage,
                });
              }}
            >
              <SaveIcon strokeWidth={1.1} className={`absolute h-5 w-5`} />
            </Button>
            <Button
              variant={'secondary'}
              className={`!border-1 border-gray-300 rounded-xl !p-4 !px-4 h-7 flex flex-col items-center justify-center`}
              onClick={() => copyShareUrlToClipboard()}
            >
              <Share strokeWidth={1.1} className={`absolute h-5 w-5`} />
            </Button>
            <div className={`fixed bottom-4 left-4`}>
              <ToggleButtonTheme />
            </div>
          </div>
        </div>

        <div className={`justify-self-end ${borderClass}`}>
          <QuoteRequest classNameButton={`text-sm`} />
        </div>
      </div>
    </div>
  );
};

export default React.memo(WheelConfiguratorHeader);
