import { Filters } from '@/lib/types';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';


const useFilters = () =>
{
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const filterParams = ["Brand", "Diameter", "Width", "Color", "Finish", "Offsets", "Search"] as const;
  const location = useLocation();

  const addFiltersToQueryParams = ({ params }: { params: Filters }) =>
  {
    
    const newSearchParams = new URLSearchParams(location.search);

    Object.keys(params).forEach(key =>
    {
      newSearchParams.set(key, params[key as keyof Filters]!.value);
    });

    navigate({ search: newSearchParams.toString() }, { replace: true });
  };

  const getFiltersFromQueryParams = (): Filters =>
  {
    const filters: Filters = {};
    searchParams.forEach((value, key) =>
    {
      if (filterParams.includes(key as typeof filterParams[number]))
      {
        filters[key as keyof Filters] = { value };
      }
    });
    return filters;
  };

  const resolveInchesFilterObject = (itemWithInchesAsUnit: any) =>
  {
    itemWithInchesAsUnit = itemWithInchesAsUnit?.value ? itemWithInchesAsUnit.value : itemWithInchesAsUnit
    return { displayName: itemWithInchesAsUnit + "\"", value: itemWithInchesAsUnit }
  }
  const resolveBrandFilterObject = (item: any) =>
  {
    item = item?.value ? item.value : item.Brand
    return { displayName: item, value: item }
  }
  const resolveGeneralFilterObject = (item: any) =>
  {
    item = item?.value ? item.value : item
    return { displayName: item, value: item }
  }
  const resolveFiltersDisplayObejct = ({ title }: { title: string }) =>
  {
    switch (title)
    {
      case "Diameter":
      case "Width":
        return resolveInchesFilterObject
      case "Brand":
        return resolveBrandFilterObject
      default:
        return resolveGeneralFilterObject
    }
  }

  return { addFiltersToQueryParams, getFiltersFromQueryParams, resolveFiltersDisplayObejct };
};

export default useFilters;
