// autoviz-component-monorepo/packages/product-catalog/src/components/brand-display-image.tsx

import useFetchBrands from '@/hooks/useFetchBrands';
import { useStore } from '@/lib/store';
import { Brand } from '@/lib/types';
import { disableRightClick } from '@/lib/utils';
import { useEffect, useState } from 'react';

const BrandDisplayImage = ({
  brandLogosUrlBase,
  brand,
}: { brandLogosUrlBase?: string; brand?: Brand | null } = {}) => {
  const currentAutosyncApiWheelStyle = useStore(
    (state) => state.currentAutosyncApiWheelStyle,
  );
  const storeBrandLogosUrlBase = useStore((state) => state.brandLogosUrlBase);

  const [resolvedBrand, setResolvedBrand] = useState<Brand | null>(
    brand || null,
  );
  const [resolvedbrandLogosUrlBase, setResolvedbrandLogosUrlBase] =
    useState<string>(brandLogosUrlBase || storeBrandLogosUrlBase);

  const { brands } = useFetchBrands();
  useEffect(() => {
    if (
      brands &&
      Object.keys(brands).length > 0 &&
      currentAutosyncApiWheelStyle?.Brand
    ) {
      const brand = brands
        ? (brands[currentAutosyncApiWheelStyle?.Brand] as Brand)
        : null;
      setResolvedBrand(brand);
    }
  }, [brands, storeBrandLogosUrlBase, currentAutosyncApiWheelStyle]);

  return (
    <div
      onContextMenu={disableRightClick}
      className={`flex items-center justify-center`}
    >
      <img
        src={`${storeBrandLogosUrlBase}${resolvedBrand?.Logo}`}
        alt={resolvedBrand?.Brand}
        className="object-contain transition-all duration-500 ease-in-out"
        style={{ opacity: resolvedBrand && storeBrandLogosUrlBase ? 1 : 0 }}
      />
    </div>
  );
};

export default BrandDisplayImage;